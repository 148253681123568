import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';
import { ModalController } from '@ionic/angular';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { Observable, Subscription } from 'rxjs';
import { Card } from 'src/app/core/models/card/card.model';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { User } from 'src/app/core/models/user/user.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRolesService } from 'src/app/modules/roles/services/http/api-roles.service';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { LocationGroupsService } from 'src/app/shared/services/location-groups.service';
import { LocationGroup } from '../../services/api-location-groups.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { PermissionType } from 'src/app/core/models/permissions/permission.enum';
import { CardStatus } from 'src/app/core/models/card/card-status.model';
import { CardReaderService } from 'src/app/core/services/card-reader.service';
import { GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE } from 'src/environments/environment';

@Component({
  selector: 'app-location-drag-select-modal',
  templateUrl: './location-drag-select-modal.component.html',
  styleUrls: ['./location-drag-select-modal.component.scss']
})
export class LocationDragSelectModalComponent implements OnInit, OnDestroy {

  @ViewChild('selectContainer') selectContainer: SelectContainerComponent;
  @ViewChild('dtFrom', { static: true }) dtFrom: OwlDateTimeComponent<any>;
  @ViewChild('dtTo', { static: true }) dtTo: OwlDateTimeComponent<any>;
  selectedDateTime: Date[] = [];

  acLocations$ = this.projectService.getAccessControlLocations$();
  floorList$: Observable<LocationGroup[]> = this.locationGroupsService.getFloorList();
  objectsFilterFull$ = this.filtersService.getObjectsFilterFull();

  permissions: Permission[];
  permissionsSubscription: Subscription;

  selectedRooms: string[] = [];

  fromDateValid: boolean = false;
  toDateValid: boolean = false;
  showNoRoomsSelectedError = false;

  cardCanBeUsed: boolean;

  openModal = false;
  modalLoading = false;
  userBeingCreated = false;
  allObjects$: Observable<ProjectObject[]> = this.projectService.getObjects();

  selectedRoomDesignation = '*/*/*/*';
  maxDate = this.cardsService.cardsMaxEndDate;

  errorReadingCard = '';

  constructor(private projectService: ProjectService,
              private locationGroupsService: LocationGroupsService,
              private rolesService: RolesService,
              private apiRolesService: ApiRolesService,
              private cardsService: CardsService,
              private apiUserService: ApiUsersService,
              private modalController: ModalController,
              private filtersService: FiltersService,
              private cardReaderService: CardReaderService,
              ) { }

  ngOnInit(): void {
    this.selectedDateTime[0] = new Date();
    this.getPermissions();
  }

  getPermissions() {
    this.permissions = this.rolesService.getPermissions();
    this.apiRolesService.getPermissions().subscribe();
    this.permissionsSubscription = this.rolesService.permissionsChanged.subscribe(() => {
      this.permissions = this.rolesService.getPermissions();
    });
  }

  ionViewDidEnter	() {
    this.selectContainer.update();// "select container" needs to be updated on each change of its size and position
  }

  onScrollEnd() {
    this.selectContainer.update();
  }

  dateTimeInput() {
  }

  onCloseFrom() {
    this.selectedDateTime = [this.dtFrom.selecteds[0], this.dtFrom.selecteds[1]];
    this.fromDateValid = false;
  }

  onCloseTo() {
    this.selectedDateTime = [this.dtTo.selecteds[0], this.dtTo.selecteds[1]];
    this.toDateValid = false;
  }

  checkRequiredFields() {
    if (!this.selectedDateTime[0]) {
      this.fromDateValid = true;
    }
    if (!this.selectedDateTime[1] || this.selectedDateTime[1]< new Date()) {
      this.toDateValid = true;
    }
  }

  onModalDecision(decision) {
    this.errorReadingCard = '';
    this.openModal = false;
    this.modalLoading = false;
 }

 onChangeObjectSubobjectRooms(event) {
  this.selectedRoomDesignation = event.target.value;
  this.selectContainer.clearSelection();
  this.selectContainer.update();
}

  async onClickSave() {
    if (!this.selectedDateTime[0] || !this.selectedDateTime[1] || this.selectedDateTime[1]< new Date()) {
      this.checkRequiredFields();
    }

    if (this.selectedRooms.length === 0) {
      this.showNoRoomsSelectedError = true;
    }

    if (!this.selectedDateTime[0]
      || !this.selectedDateTime[1]
      || this.selectedRooms.length === 0
      || this.selectedDateTime[1]< new Date()) {
      return;
    }

      this.openModal = true;

      let cardReaderInfo: {primaryCardOnReaderInfo?:CardStatus,secondaryCardOnReaderInfo?:CardStatus, error?: string};
      do {
        cardReaderInfo = GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE != null ?
        await this.cardReaderService.getCardInfoFromCardReaderForSingleCardTechType()
        :await this.cardReaderService.getCardInfoFromCardReader()
      if (cardReaderInfo.error) {
        this.errorReadingCard = cardReaderInfo.error;
        await new Promise(resolve => setTimeout(resolve,  this.cardReaderService.pauseBetweenCardReadAttemps));
      } else {
        break;
      }
      } while (cardReaderInfo.error && this.openModal)

      if (!cardReaderInfo.primaryCardOnReaderInfo) {
        return;
      }

      this.modalLoading = true;
      this.errorReadingCard = '';

          const user: User = new User();
          user.typeId = 3;
          user.firstName = 'Guest';
          user.lastName = 'Guest';
          user.permissions = []

          this.permissions.forEach( perm => {
            if(perm.typeId === PermissionType.Unlock && perm?.locationId && this.selectedRooms.includes(perm.locationId.toString())) {
              user.permissions.push(perm);
            }
          })

          user.cards = [];
          const card: Card = new Card();
          card.type = 1;

          if (GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE != null) {
            card.uid = cardReaderInfo.primaryCardOnReaderInfo.uid;
            card.techTypeId = GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE
            card.secondaryUid = null;
            card.secondaryTechTypeId = null;
          }
          else {
            card.uid = cardReaderInfo.primaryCardOnReaderInfo.uid;
            card.techTypeId = this.cardReaderService.getDualCardSettings().getPrimaryCardReaderTechTypeId()
            card.secondaryUid =
              this.cardReaderService.getDualCardSettings().useDualCardReader?cardReaderInfo.secondaryCardOnReaderInfo.uid:null;
            card.secondaryTechTypeId = this.cardReaderService.getDualCardSettings().getSecondaryCardReaderTechTypeId();
          }

          card.userId = 0;
          card.validFrom = this.selectedDateTime[0];
          card.validTo = this.selectedDateTime[1];
          card.blacklisted = 0;
          user.cards.push(card);

          this.modalController.dismiss();
          if (!this.userBeingCreated) {
            this.userBeingCreated = true;
            this.openModal = true;
            this.modalLoading = true;
            this.apiUserService.addUser(user).subscribe({
              next :(createdUser) => {
                this.userBeingCreated = false;
                this.modalLoading = false;
            }, error: ()=> {
              this.modalLoading = false;
            }});
          }
  }

  ngOnDestroy() {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
  }

}
