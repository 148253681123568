import { DATE_PICKER_OPTIONS } from 'src/environments/environment';
import { User } from '../user/user.model';
import { CardReaderTechType } from 'src/app/modules/settings/services/http/api-settings.service';
import { AdriaCardReaderSettings } from './adria-card-reader-settings.model';

export class Card {
    uid: string;
    techTypeId: CardReaderTechType;
    secondaryUid:string;
    secondaryTechTypeId: CardReaderTechType;
    userId: number;
    type: number;
    validFrom: Date;
    validTo: Date;
    blacklisted: number;
    accessBits?: string;
    groups?: string;
    customData?: string;
    accessLogs?: any[];
    user?: User;
    isBackup: boolean;

    constructor() {
        this.setValidFromToDates();
    }
    /* public static generateCardUid(
      cardType: string // 'salto' | 'mifare' | 'rfid'
    )
    {
      if (cardType.toLowerCase() === 'rfid') {
        return this.generateRandomNumber(8).toString()
      } else if (cardType.toLowerCase() === 'mifare') {
        return this.generateRandomNumber(8).toString()
      } else if (cardType.toLowerCase() === 'salto') {
        return this.generateRandomNumber(14).toString()
      } else {
        return this.generateRandomNumber(8).toString()
      }
    } */

      public static generateCardUid(cardReaderTechType: CardReaderTechType)
      /* RFID - 0107BB15BC lenght 10, 5 bytes
      Mifare - 7C47BC68 lenght 8, 4 bytes
      Salto - 14 char ali tebi nebitno */
      {
        if (cardReaderTechType === CardReaderTechType.Mifare) {
          return this.generateRandomNumber(8).toString()
        } else {
          return this.generateRandomNumber(10).toString()
        }
      }

    private static generateRandomNumber(size: number): number {
      const min = Math.pow(10, size - 1);
      const max = Math.pow(10, size) - 1;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static generateVirtualCardTechTypeData (adriaCardReaderSettings: AdriaCardReaderSettings):
    {techTypeId: CardReaderTechType,uid: string, secondaryTechTypeId: CardReaderTechType,secondaryUid: string} {
      // let techTypeId: CardReaderTechType;
      // let uid:string;
      // let secondaryTechTypeId: CardReaderTechType;
      // let secondaryUid: string;

      const primaryCardReaderTechTypeId = adriaCardReaderSettings.getPrimaryCardReaderTechTypeId();
      if (!adriaCardReaderSettings.useDualCardReader ) {
        return {
          techTypeId: primaryCardReaderTechTypeId,
          uid : Card.generateCardUid(primaryCardReaderTechTypeId),
          secondaryTechTypeId: null,
          secondaryUid: null
        }
      }
      const secondaryCardReaderTechTypeId = adriaCardReaderSettings.getSecondaryCardReaderTechTypeId();

      return {
        techTypeId: primaryCardReaderTechTypeId,
        uid : Card.generateCardUid(primaryCardReaderTechTypeId),
        secondaryTechTypeId: secondaryCardReaderTechTypeId,
        secondaryUid: Card.generateCardUid(secondaryCardReaderTechTypeId)
      }
  /*     techTypeId = cardType.toLowerCase()==='salto' || cardType.toLowerCase()==='mifare'?
      CardReaderTechType.Mifare:CardReaderTechType.Rfid
      uid = Card.generateCardUid(cardType)

      if (!useDualCardReader ) {
        return {
          techTypeId,
          uid,
          secondaryTechTypeId: null,
          secondaryUid: null
        }
      }

      secondaryTechTypeId = techTypeId === CardReaderTechType.Mifare? CardReaderTechType.Rfid:CardReaderTechType.Mifare;
      if (cardType.toLowerCase()==='salto' || cardType.toLowerCase()==='mifare') {
        secondaryUid = Card.generateCardUid('rfid')
      }
      else {
        secondaryUid = Card.generateCardUid('mifare')
      }

      return {
        techTypeId,
        uid,
        secondaryTechTypeId,
        secondaryUid
      }*/
    }

    setValidFromToDates() {
        this.validFrom = new Date();
        this.validTo = new Date();
        if (DATE_PICKER_OPTIONS.startDate !== 'today') {
            this.validFrom.setDate(this.validFrom.getDate() + +DATE_PICKER_OPTIONS.startDate);
        }
        if (DATE_PICKER_OPTIONS.startTime !== 'now') {
            const hhmm = DATE_PICKER_OPTIONS.endTime.split(':');
            this.validFrom.setHours(+hhmm[0], +hhmm[1]);
        }
        if (DATE_PICKER_OPTIONS.endDate !== 'today') {
            this.validTo.setDate(this.validTo.getDate() + +DATE_PICKER_OPTIONS.endDate);
        }
        if (DATE_PICKER_OPTIONS.endTime !== 'now') {
            const hhmm = DATE_PICKER_OPTIONS.endTime.split(':');
            this.validTo.setHours(+hhmm[0], +hhmm[1]);
        }
    }



}
