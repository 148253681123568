import { CardReaderTechType } from 'src/app/modules/settings/services/http/api-settings.service'

export class AdriaCardReaderSettings {
  mainType: CardReaderTechType;
  useDualCardReader: boolean;
  antennaSwitchDelay: number;

  constructor(mainType: CardReaderTechType, useDualCardReader: boolean, antennaSwitchDelay: number) {
    this.mainType = mainType;
    this.useDualCardReader = useDualCardReader;
    this.antennaSwitchDelay = antennaSwitchDelay;
  }

  getPrimaryCardReaderTechTypeId(): CardReaderTechType {
    return this.mainType === CardReaderTechType.Mifare ? CardReaderTechType.Mifare:CardReaderTechType.Rfid;
  }

  /* getSecondaryCardReaderTechType(): CardReaderTechType {
    return this.mainType === CardReaderTechType.Mifare ? CardReaderTechType.Rfid:CardReaderTechType.Mifare;
  } */

  getSecondaryCardReaderTechTypeId(): CardReaderTechType | null {
    if (this.useDualCardReader) {
      return this.mainType === CardReaderTechType.Mifare ? CardReaderTechType.Rfid:CardReaderTechType.Mifare;
    } else {
      return null;
    }
  }

}
