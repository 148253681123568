<div class="flex-column-center-full margin-top20">
    <h2>{{'Enter Room Number' | translate}}</h2>
    <div class="flex-row-center-v margin-top20">
        <input autofocus  id='roomInput' #search type="text" (keyup.enter)="onEnterLocationName($event.target.value)" (input)="showError = false" class="search-input" [(ngModel)]="roomNumber">
        {{search.focus()}}<!-- focuses on search input -->
        <ion-button  class="margin5" (click)="onEnterLocationName(roomNumber)">OK</ion-button>
    </div>
    <div class="red" *ngIf="showError"> {{'No room with that number' | translate}}!</div>
    <div (click)="guestWithoutRoom()" class="br-b-s font-size20 margin-top20 clickable">{{'Guest without room' | translate}}</div>
</div>

