import { IMqttServiceOptions } from 'ngx-mqtt';
import { StatusFilterConfig } from 'src/app/core/models/status-filters-config.interface';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export let ENVIRONMENT_MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: '10.0.2.20',
  port: 8083,
  path: '/mqtt',
  protocol: 'ws',
  connectOnCreate: false
};

export let API_BASE_URL = 'http://10.0.2.82/api/irooms2/1';
export let API_URL = 'http://10.0.2.82'; // needed for creation of version check url
export let VERSION_CHECK_URL = '/version.json';
export let BACKUP_CARD_YEARS_ACTIVE = 10;
export let USE_DALI = true;
export let CARD_WARNING = 0;
export let CARD_DANGER = 0;
export let RETRO_MODE = false;
export let RESET_MQTT_QUEUE_AT_COUNT = 0;
export let PROJECT_ID = 0;
export let DEMO_MODE = false;
export let VIRTUAL_CARD = false;
export let FORBID_HEAT_COOL_CHANGE = false;
export let USE_SIMPLIFIED_ROOM_STATUS = false;
export let SHOW_CARD_TRAY_IGNORED_INDICATOR = false;
export let GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE = undefined;
export let HIDE_STATUS_FILTERS: StatusFilterConfig = {
  hvac: {
    hvacOnOff: false,
    coolingOnOff: false,
    roomFloorHeatingOnOff: false,
    bathroomFloorHeatingOnOff: false,
    heatingOnOff: false,
    hvacSpeed: false,
    temperatureFilter: false,
    temperatureDifferenceFilter: false,
    lgTemperatureFilter: false,
    lgTemperatureDifferenceFilter: false,
    daikinTemperatureFilter: false,
    daikinTemperatureDifferenceFilter: false,
    boschTemperatureFilter: false,
    boschTemperatureDifferenceFilter: false
  },
  cleaning : {
    roomInspected: false,
    roomCleaned: false,
    makeUpRoom: false,
  },
  highestCleaningStatus: false,
  other : {
    isOnline: false,
    isRented: false,
    presence: false,
    alarmActive: false,
    doNotDisturb: false,
    roomArmed: false,
    windowOpen: false,
    doorOpen: false,
    ignoreWindow: false,
    ignoreCardTray: false,
    minibarOpened: false,
    maidAtLocation: false
  }
}

export function setEnvironmentMqtt(mqttSettings: IMqttServiceOptions) {
  Object.assign(ENVIRONMENT_MQTT_SERVICE_OPTIONS, mqttSettings);
}

export function setCardBackupYearsActive(years: number) {
  BACKUP_CARD_YEARS_ACTIVE = years;
}

export function setDemoMode(demoMode: boolean) {
  DEMO_MODE = demoMode;
}

export function setVirtualCard(virtual: boolean) {
  VIRTUAL_CARD = virtual;
}

export function setBaseUrl(port: string, url: string, url_suffix: string) {
  if (url.includes('dyn.ae.hr')) {
    API_BASE_URL = url + '/irooms' + url_suffix;
    API_URL = url;  // needed for version check
  } else {
    API_BASE_URL = url + ':' + port + url_suffix;
    API_URL = url;  // needed for version check
  }
}

export function setVersionCheckUrl(newUrl: string) {
  VERSION_CHECK_URL = newUrl;
}

export function setUseDali(useDali: boolean) {
  USE_DALI = useDali;
}

export function setCardWarning(cardWarning: number) {
  CARD_WARNING = cardWarning;
}

export function setRetroMode(retroMode: boolean) {
  RETRO_MODE = retroMode;
}

export function setMQTT(protocol: string, url: string) {
  if(protocol == 'http:') {
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.hostname = url;
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.port = 8083;
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.protocol = 'ws';
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.path = '/mqtt';
  } else {
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.hostname = url;
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.port = 443;
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.protocol = 'wss';
    ENVIRONMENT_MQTT_SERVICE_OPTIONS.path = '/ws';
  }
}

export function setResetMqttAtCount(count: number) {
  RESET_MQTT_QUEUE_AT_COUNT= count;
}

export function setProjectId(count: number) {
  PROJECT_ID = count;
}

export function setCardDanger(cardDanger: number) {
  CARD_DANGER= cardDanger;
}

export function setForbidHeatCoolChange(hcChange: boolean) {
  FORBID_HEAT_COOL_CHANGE = hcChange;
}

export function setSimplifedRoomStatus(useSimple: boolean) {
  USE_SIMPLIFIED_ROOM_STATUS = useSimple;
}

export function setHideStatusFilters(statusFilterConfig: StatusFilterConfig) {
  HIDE_STATUS_FILTERS = statusFilterConfig;
}

export function setShowCardTrayIgnoredIndicator(showCardTrayIgnoredIndicator: boolean) {
  SHOW_CARD_TRAY_IGNORED_INDICATOR = showCardTrayIgnoredIndicator;
}

export function setGuestWithoutRoomsSingleTechType(guestWithoutRoomsSingleTechType: number) {
  if (guestWithoutRoomsSingleTechType  !== undefined && guestWithoutRoomsSingleTechType  !== null &&
    typeof guestWithoutRoomsSingleTechType === 'number')
    GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE = guestWithoutRoomsSingleTechType;
}

export const CARD_READER_URL = 'http://localhost:9986';

/*
* Start/End Date -> today or +/-n value from today in days
* Start/End Time -> now or fixed time in HH:mm format
* max -> in days, from now what is max end date
*/
export const DATE_PICKER_OPTIONS = {
  startDate: 'today',
  startTime: 'now',
  endDate: '+120',
  endTime: '12:00',
  maxEnd: 365
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
