import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { USE_SIMPLIFIED_ROOM_STATUS } from 'src/environments/environment';

@Component({
  selector: 'app-display-set-temperature',
  templateUrl: './display-set-temperature.component.html',
  styleUrl: './display-set-temperature.component.scss'
})
export class DisplaySetTemperatureComponent implements OnInit {
    @Input({required:true}) location: Location;
    @Input({required:true}) controllerIndex: number;
    @Input({required:true}) modeRoomOrBathroom: 'room' | 'bathroom';
    @Input({required:true}) codeFrom: number;
    @Input({required:true}) codeTo: number;
    @Input({required:true}) displaySize: string;
    @Input({required:true}) activeIcon= 'icon-temp font-size19 white';
    @Input({required:true}) sufix= '°C';
    @Input({required:true}) activeTooltip= 'Set room temperature';
    Property = Property
    public updated: Date;
    public USE_SIMPLIFIED_ROOM_STATUS = USE_SIMPLIFIED_ROOM_STATUS
    private updatedSubscription: Subscription;

    constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.updatedSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
    })
  }

   ngOnDestroy(): void {
      if(this.updatedSubscription) {
        this.updatedSubscription.unsubscribe();
    }
  }

}
