import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';

@Pipe({
  name: 'filterByObj'
})
export class FilterControllerByObjectPipe implements PipeTransform {

  transform(
    controllers: Controller[],
    objectFilter: ProjectObject[],
    ): Controller[] {
    
      if (objectFilter.length == 0) {
        return controllers
      } else {
        objectFilter.forEach ( (proObject: ProjectObject) => {
          const object = proObject.designation.split('/')[0]
          controllers = controllers.filter ( cont => cont.object == object)
        })
      }
    return controllers
  }

}
