import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardOnHolderService {

  cardOnHolder: boolean;
  cardOnHolderChanged = new Subject<boolean>();

  constructor() {}

  getCardOnHolder() {
    return this.cardOnHolder;
  }

  setCardOnHolder(cardOnHolder: boolean) {
    this.cardOnHolder = cardOnHolder;
    this.cardOnHolderChanged.next(this.cardOnHolder);
  }
}
