import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { IMqttMessage } from 'ngx-mqtt';
import { lastValueFrom, Subscription } from 'rxjs';
import { Card } from 'src/app/core/models/card/card.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { ApiProjectService, KeyOption } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRolesService } from 'src/app/modules/roles/services/http/api-roles.service';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { EditCardModalComponent } from 'src/app/modules/rooms/components/edit-card-modal/edit-card-modal.component';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { AllPropsComponent } from '../all-props/all-props.component';
import { CardStatus } from 'src/app/core/models/card/card-status.model';
import { CardReaderService } from 'src/app/core/services/card-reader.service';

export type roomModalViewTypes = 'view' | 'hvac' | 'settings' | 'cards' | 'log' | 'service';

@Component({
  selector: 'app-room-modal',
  templateUrl: './room-modal.component.html',
  styleUrls: ['./room-modal.component.scss']
})
export class RoomModalComponent implements OnInit, OnDestroy {
  @Input() object: string = '0';
  @Input() subObject: string = '0';
  @Input() zone: string;
  @Input() startingView: roomModalViewTypes;
  @Input() openNewCard = false;
  @Input() locationId: string;

  activeView: roomModalViewTypes = 'view';
  updated: Date;
  pipeSubscription: Subscription;
  contSub: Subscription;
  popoverOpened = false;
  location: Location;
  Location = Location;
  isAccessControl = Controller.isAccessControl;
  isIOCommonArea = Controller.isIOCommonArea;
  isGuestRoom = Controller.isGuestRoom;
  isHvacCommonArea = Controller.isHvacCommonArea;

  signedInUser: User;
  swPermissions = SoftwarePermissionId;
  permissionsSubscription: Subscription;

  guestCards: Card[] = [];
  cardMaxNumberReached = false;
  controllerSubscription: Subscription;
  multipleRooms: Location[] = [];
  permissions: Permission[];

  openModal = false;
  accessControlListForLocationId: KeyOption[];
  modalLoading = false;
  mainController: Controller;
  controllers: Controller[];
  from;
  to;
  savedDateFrom: Date;
  savedDateTo: Date;
  savedDateFromString: string = '';
  savedDateToString: string = '';
  savedDateFromChangedSub: Subscription;
  savedDateToChangedSub: Subscription;

  isFirefox: boolean;
  cardType: string;
  User = User;

  cardHasBeenRead = false;
  errorReadingCard = '';

  constructor(
              private projectService: ProjectService,
              private cardsService: CardsService,
              private apiUsersService: ApiUsersService,
              private currentUserStoreService: CurrentUserStoreService,
              private modalController: ModalController,
              private apiProjectService: ApiProjectService,
              private usersService: UsersService,
              private apiRolesService: ApiRolesService,
              private rolesService: RolesService,
              private popoverController: PopoverController,
              private cardReaderService: CardReaderService
              ) { }

  ngOnInit(): void {
    this.isFirefox = this.checkIfFirefox();
    this.cardsService.setCardInMaking(true);
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
    this.getFilteredLocationById();
    this.getPermissions();
    if (this.startingView && !Location.isGuestRoom(this.location) &&
     !User.hasAnyOfRequiredPermissions(this.signedInUser, [this.swPermissions.LocationSettings])) {
      this.activeView = 'cards';
     }else if (this.startingView){
      this.activeView = this.startingView;
     }

    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = value;
    });

    this.controllerSubscription = this.projectService.singleControllerMessage$.subscribe( value => {
      this.updateControllerByMqttMsg(value)
    });
    this.getSavedDates();
  }

  private checkIfFirefox(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('firefox');
  }

  getCurrentTime(): Date {
    return new Date();
  }

  getSavedDates(){
    this.from = this.projectService.getLatestCardFrom();
    this.to = this.projectService.getLatestCardTo();
    this.savedDateFrom = new Date(this.from);
    this.savedDateTo = new Date(this.to);
    this.savedDateFromString = this.savedDateFrom.toLocaleDateString('hr-HR').replace(/\s/g, '');
    this.savedDateToString = this.savedDateTo.toLocaleDateString('hr-HR').replace(/\s/g, '');
    this.savedDateFromChangedSub = this.projectService.savedDateFromChanged.subscribe((value: Date) => {
      this.savedDateFrom = new Date(value);
      this.savedDateFromString = value.toLocaleDateString('hr-HR').replace(/\s/g, '');
    });
    this.savedDateToChangedSub = this.projectService.savedDateToChanged.subscribe((value: Date) => {
      this.savedDateTo = new Date(value);
      this.savedDateToString = value.toLocaleDateString('hr-HR').replace(/\s/g, '');
    });
  }

  getFilteredLocationById() {
    if (this.apiProjectService.filteredFatLocationsIsReady$.value) {
      this.location = this.projectService.getFilteredLocationById(this.locationId)
        this.getLocationByApi();
    } else {
      this.getLocationByApi();
    }
  }

  async showAllProps(event){
    const popover = await this.popoverController.create({
      component: AllPropsComponent,
      cssClass: 'popover-custom',
      showBackdrop: false,
      backdropDismiss: true,
      event: event,
      componentProps: {
        controllers: this.controllers
      }
    });
    await popover.present();
    // popover.onDidDismiss().then((value) => {})
  }

  getKeyOptions(){
    this.controllers = this.location.controllers.$values
    this.mainController = Controller.getMainController(this.controllers);
    if (Controller.isGuestRoom(this.mainController)) {
      this.cardType = Controller.getCardType(this.mainController)
    }
    this.apiProjectService.getKeyOptionsAccessControllListForLocationId(this.mainController.locationId).subscribe(keyOptions => {
      this.accessControlListForLocationId = keyOptions;
    });
  }

  getLocationByApi() {
    this.apiProjectService.getLocationById(this.locationId)
    .subscribe((location: Location)=> {
      this.location = location;
      if (this.openNewCard) {
        this.newCard();
      }
      this.getCardsByLocation();
      this.getKeyOptions();
    })
  }

  updateControllerByMqttMsg(message: IMqttMessage) {
    if (this.location != undefined && message !== null) {
      const payloadIndex = message.payload.toString().lastIndexOf('|');
      const payload = message.payload.toString().slice(payloadIndex + 1);

      // example topic cli/driver/1/0/0/101/0/hvac/temp/set/room
      const driverPref = message.topic.split('/')[1] + '/' + message.topic.split('/')[2];
      const obj = message.topic.split('/')[3];
      const subObj = message.topic.split('/')[4];
      const zoneId = message.topic.split('/')[5];
      const subZoneId = message.topic.split('/')[6];
      const propTopic = this.getPropTopic(message.topic);
      let targetProp: Property;

      if (propTopic) {
         this.location.controllers.$values.find( cont => {
            if (cont.driverPrefix.toLowerCase() === driverPref.toLowerCase() && cont.object === obj && cont.subObject === subObj && cont.zone === zoneId && cont.subZone === subZoneId) {
              targetProp = cont.controllerProperties.$values.find((prop)=> {
                return prop.mqttTopic === propTopic
              })
              return true;
            }
          })

      }

      if (targetProp) {
        targetProp.value = payload;
      }
    }
  }

  tabClicked(card: roomModalViewTypes) {
    this.activeView = card;
    if (this.activeView !== 'cards') {
      this.openNewCard = false;
    }
  }

  getPropTopic(topic: string): string { // example input: dev/irooms/1/0/0/101/1/hvac/temp/meas/room,
    const topicArray = topic.split('/');
    let propTopic = '';
    for (let index = 7; index < topicArray.length; index++) {
      propTopic = propTopic + topicArray[index];
      if (index < topicArray.length-1) {
        propTopic = propTopic + '/';
      }
    }
    return propTopic;  // example output : hvac/temp/meas/room
  }

  async newCard() {
      const modal = await this.modalController.create({
        component: EditCardModalComponent,
        cssClass: 'cards-popover',
        backdropDismiss: true,
        showBackdrop: true,
        componentProps:  {
          location: this.location
          }
      });
      return await modal.present();
  }

  closeAndResetConfirmationModal(decision?) {
    this.errorReadingCard = '';
    this.openModal = false;
    this.modalLoading = false;
  }

  getPermissions() {
    this.permissions = this.rolesService.getPermissions();
    this.apiRolesService.getPermissions().subscribe();
    this.permissionsSubscription = this.rolesService.permissionsChanged.subscribe(() => {
      this.permissions = this.rolesService.getPermissions();
    });
  }

  getRandomId() {
    const randomInt = this.getRandomInt(1,9999999)
    return String(randomInt).padStart(7, '0'); // '0009'
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  isAccessControll(controller: Controller) {
    return Controller.isAccessControl(controller);
  }

  async onNewCardWithSavedDates() {
    this.openModal = true;

      let cardReaderInfo: {primaryCardOnReaderInfo?:CardStatus,secondaryCardOnReaderInfo?:CardStatus, error?: string};
      do {
        cardReaderInfo = await this.cardReaderService.getCardInfoFromCardReader()
      if (cardReaderInfo.error) {
        this.errorReadingCard = cardReaderInfo.error;
        await new Promise(resolve => setTimeout(resolve,  this.cardReaderService.pauseBetweenCardReadAttemps));
      } else {
        break;
      }
      } while (cardReaderInfo.error && this.openModal)

      if (!cardReaderInfo.primaryCardOnReaderInfo) {
        return;
      }

      this.modalLoading = true;
      this.errorReadingCard = '';

        const multipleRoomsIds = [];
        const accessControlIds = [];
        this.multipleRooms.forEach( loc => {
          multipleRoomsIds.push(loc.locationId.toString());
        });
        this.accessControlListForLocationId.forEach((keyOption: KeyOption) => {
          if (keyOption.checked) {
            accessControlIds.push(keyOption.location.locationId.toString());
          }
        });
        const user: User = new User();
        user.typeId = 3;
        user.firstName = 'Guest'
        user.lastName = '';
        user.lastName = this.getRandomId();
        // user.email = '';
        user.permissions = [];

        this.permissions.forEach( perm => {
          if(perm.locationId && (perm.typeId === 1) && (this.location.locationId === perm.locationId ||
            multipleRoomsIds.includes(perm.locationId.toString()) || accessControlIds.includes(perm.locationId.toString()))) {
            user.permissions.push(perm);
          }
        });
        user.cards = [];
        if (this.isAccessControll(this.mainController)) {
          user.firstName = 'AC'
          user.lastName = this.getRandomId();
        }
        const card: Card = new Card();
        card.type = 1;

        card.uid = cardReaderInfo.primaryCardOnReaderInfo.uid;
        card.techTypeId = this.cardReaderService.getDualCardSettings().getPrimaryCardReaderTechTypeId()
        card.secondaryUid =
        this.cardReaderService.getDualCardSettings().useDualCardReader?cardReaderInfo.secondaryCardOnReaderInfo.uid:null;
        card.secondaryTechTypeId = this.cardReaderService.getDualCardSettings().getSecondaryCardReaderTechTypeId();

        card.userId = 0;
        card.validFrom = this.savedDateFrom;
        card.validTo = this.savedDateTo;
        card.blacklisted = 0;
        user.cards.push(card);
        this.openModal = true;
        try {
          await lastValueFrom(this.apiUsersService.addUser(user))
          this.modalLoading = false;
          this.usersService.guestAdded();
          this.closeAndResetConfirmationModal();
        } catch (err) {
          this.modalLoading = false;
        }
      }

  createVirtualCard(/* virtualUid: string */) {
    const multipleRoomsIds = [];
    // const accessControlIds = [];
    this.multipleRooms.forEach((loc) => {
      multipleRoomsIds.push(loc.locationId.toString());
    });
    // no access controlles are allowed on virtual cards
    /* this.accessControlListForLocationId.forEach((keyOption: KeyOption) => {
      if (keyOption.checked) {
        accessControlIds.push(keyOption.location.locationId.toString());
      }
    }); */
    const user: User = new User();
    user.typeId = 3;
    user.firstName = 'Guest';
    user.lastName = '';
    user.lastName = this.getRandomId();
    // user.email = '';
    user.permissions = [];

    this.permissions.forEach((perm) => {
      if (
        perm.locationId &&
        perm.typeId === 1 &&
        (this.location.locationId === perm.locationId ||
          multipleRoomsIds.includes(perm.locationId.toString())
          // || accessControlIds.includes(perm.locationId.toString())
          )
      ) {
        user.permissions.push(perm);
      }
    });
    user.cards = [];
    const card: Card = new Card();

    card.type = 1;
    if (this.isAccessControll(this.mainController)) {
      user.firstName = 'AC';
      user.lastName = this.getRandomId();
    }

    const techTypeData = Card.generateVirtualCardTechTypeData(this.cardReaderService.getDualCardSettings())
    card.techTypeId = techTypeData.techTypeId;
    card.uid = techTypeData.uid;
    card.secondaryTechTypeId = techTypeData.secondaryTechTypeId;
    card.secondaryUid = techTypeData.secondaryUid;

    card.userId = 0;
    card.validFrom = this.savedDateFrom;
    card.validTo = this.savedDateTo;
    card.blacklisted = 0;
    user.cards.push(card);
    // this.modalController.dismiss();
    this.openModal = false;
      this.apiUsersService.addUser(user).subscribe({
        next: (createdUser) => {
          this.modalLoading = false;
          this.usersService.guestAdded();
        },
        error: () => {
          this.modalLoading = false;
        },
      });
  }

  @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      if (event.key === 'F9') {
        if (!this.popoverOpened) {
          this.newCard();
        }
      } else if(event.key === 'F6') {
        this.onNewCardWithSavedDates();
      }
    }

    getCardsByLocation() {
      // TODO MAYBE CHANGE
      this.apiUsersService.getCardsByLocation(this.location.locationId).subscribe( (value: Card[]) => {
        this.guestCards = value.filter( card => card.type === 1 && !card.isBackup);

        // if we already have max number of guest cards we need to say that if someone is trying to create a new one
        if (Controller.isGuestRoom(this.location.controllers.$values[0])
          && this.guestCards.length >= Controller.getMaxGuestCardsNumber(this.location.controllers.$values[0]) - 1) {
          this.cardMaxNumberReached = true;
        }
      })
    }


    cardsUpdated(updatedGuestCards: Card[]) { // event emiter from child component app-room-modal-cards
      this.guestCards = updatedGuestCards;
      // if we already have max number of guest cards we need to say that if someone is trying to create a new one
      if (Controller.isGuestRoom(this.location.controllers.$values[0])
        && this.guestCards.length >= Controller.getMaxGuestCardsNumber(this.location.controllers.$values[0]) - 1) {
        this.cardMaxNumberReached = true;
      } else {
        this.cardMaxNumberReached = false;
      }
    }

  ngOnDestroy(): void {
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }
    if(this.contSub) {
      this.contSub.unsubscribe();
    }
    if (this.controllerSubscription) {
      this.controllerSubscription.unsubscribe();
    }
    if (this.savedDateToChangedSub) {
      this.savedDateToChangedSub.unsubscribe();
    }
    if (this.savedDateFromChangedSub) {
      this.savedDateFromChangedSub.unsubscribe();
    }
    this.cardsService.setCardInMaking(false);
  }
}
