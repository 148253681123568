import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getControllersInObject'
})
export class GetControllersInObjectPipe implements PipeTransform {

  transform(locations: Location[], objects: ProjectObject[]): Controller[] {
    if (objects.length === 0 || !objects) {
      return this.getControllersFromLocations(locations);
    }

    if (objects.length > 0) {
      const targetObj: string = objects[0].designation.split('/')[0];
      const locationsInObject = locations.filter((loc )=> {
        return Location.getLocationObject(loc) === targetObj.toString()
      })
      return this.getControllersFromLocations(locationsInObject);
    }
  }


  getControllersFromLocations(locations: Location[]) {
    let allControllers: Controller[] = [];
    locations.map( location => {
      location.controllers.$values.forEach( cont => {
        allControllers.push(cont)
      })
    })

    return allControllers;
  }
}
