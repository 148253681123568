import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { RoomViewSize, RoomsOverviewService } from 'src/app/shared/services/rooms-overview.service';

@Component({
  selector: 'app-hvac-common-area-card',
  templateUrl: './hvac-common-area-card.component.html',
  styleUrls: ['./hvac-common-area-card.component.scss']
})
export class HvacCommonAreaCardComponent implements OnInit {
  @Input() location: Location;
  controllers: Controller[];
  roomViewSize$: Observable<RoomViewSize> = this.roomsOverviewService.getViewSize();
  Property = Property
  showTags = false
  updated: Date;
  pipeSubscription: Subscription

  constructor(
    private roomsOverviewService: RoomsOverviewService,
    private projectService: ProjectService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values
    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
      this.cd.detectChanges();
    })
  }

  /* getSetTemperature() {
   const target = this.controllers[0].controllerProperties.$values.find (prop => {
      if (Property.isRoomTemperatureSet(prop)) {
        return true;
      }
    })
    if (target) {
      return target.value;
    }
  } */

  /* getCurrentTemperature() {
    const target =  this.controllers[0].controllerProperties.$values.find (prop => {
       if (Property.isRoomTemperatureCurrent(prop)) {
        return true;
       }
     })
     if (target) {
      return target.value;
    }
  } */

    onDestroy(){
      if(this.pipeSubscription) {
        this.pipeSubscription.unsubscribe();
      }
    }
}
