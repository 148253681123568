<div  class="content-header flex-row margin30 modal-header">
  <h1>{{'Select locations and date for new card' | translate }}</h1>
      <ion-button class="to-right"  (click)="onClickSave()">{{'Save' | translate}}</ion-button>
</div>


<ion-content
[scrollEvents]="true"
(ionScrollEnd)="onScrollEnd()"
class="main-content"
>
<div class="margin-left20">
  <ion-list (keyup.enter)="onClickSave()" class="br-r-s">
      <ion-item>
        <div class="content-form-group">
          <label class="width-100px">{{'Arrival' | translate}}:</label>
          <div class="flex-row">
          <div class="date-time-container">
            <input
              [owlDateTimeTrigger]="dtFrom"
              [owlDateTime]="dtFrom"
              (dateTimeInput)="dateTimeInput()"
              [max]="maxDate"
              [(ngModel)]="selectedDateTime"
              [selectMode]="'rangeFrom'"
              placeholder="{{'Date and time' | translate}}"
            />
            <div *ngIf="fromDateValid; else fromDateSpacer" class="red margin-left5">{{'Required' | translate}}</div>
            <ng-template #fromDateSpacer>
              <div class="spacer"></div>
            </ng-template>
          </div>
          <i class="icon-calendar font-size32" [owlDateTimeTrigger]="dtFrom"></i></div>
          <owl-date-time
            #dtFrom
            (afterPickerClosed)="onCloseFrom()"
          ></owl-date-time>
        </div>
      </ion-item>
      <ion-item
        ><div class="content-form-group">
          <label class="width-100px">{{'Departure' | translate}}:</label>
          <div class="flex-row">
          <div class="date-time-container">
            <input
              type="text"
              [owlDateTimeTrigger]="dtTo"
              [owlDateTime]="dtTo"
              (dateTimeInput)="dateTimeInput()"
              [max]="maxDate"
              [(ngModel)]="selectedDateTime"
              [selectMode]="'rangeTo'"
              placeholder="{{'Date and time' | translate}}"
              />
            <div *ngIf="toDateValid; else toDateSpacer" class="red margin-left5">{{'Invalid' | translate}}</div>
            <ng-template #toDateSpacer>
              <div class="spacer"></div>
            </ng-template>
          </div>
          <i class="icon-calendar font-size32" [owlDateTimeTrigger]="dtTo"></i>
        </div>

          <owl-date-time
            #dtTo
            (afterPickerClosed)="onCloseTo()"
          ></owl-date-time></div
      ></ion-item>
    </ion-list>
</div>

<div class="margin-left40">
  <ng-container *ngIf="objectsFilterFull$ | async as selectedObjectGlobal" >
  {{'Location' | translate}}
  <select
    class="margin-left20"
    (change)="onChangeObjectSubobjectRooms($event)"
    [ngModel]="selectedRoomDesignation"
  >
    <ng-container *ngFor="let object of (allObjects$ | async) | getObjects: selectedObjectGlobal[0]">
      <option [value]="object.designation">
         {{ object.name }}
      </option>
      <option
        *ngFor="let subObject of object.projectSubobjects.$values"
        [value]="subObject.designation"
      >
       {{ subObject.name }}
      </option>
    </ng-container>
    <option
      [value]="'*/*/*/*'"
    >
      {{ "All" | translate }}
    </option>
  </select>
</ng-container>
</div>
<div *ngIf="this.selectedRooms.length === 0 && showNoRoomsSelectedError; else noRoomsErrorSpacer" class="red margin-left5">{{'Select at least one access control' | translate}}</div>
<ng-template #noRoomsErrorSpacer>
  <div class="spacer"></div>
</ng-template>
<dts-select-container
#selectContainer="dts-select-container"
class="drag-area"
[(selectedItems)]="selectedRooms"
>
<div class="rooms-data">
<ng-container *ngIf="acLocations$ | async as locations">
    <ng-container *ngIf="floorList$ | async as floorList">
      <ng-container *ngFor=" let floor of floorList | getFloorsForRoomSelection : selectedRoomDesignation : locations">
        <div
        *ngIf="floor | floorHaveAcLocationsPipe"
         class="floor-box">
        <div class="floor-number-box" [title]="floor?.name">
          <span  [ngClass]="{ 'vertical-text': floor.name }">{{
            floor.name || floor | textSizeFilterPipe : 10
          }}</span>
        </div>
        <div>
          <div class="flex-row">
            <div
              *ngFor="
                let room of locations
                  | getRoomsOnFloorForSelection: floor
              "
              [dtsSelectItem]="room.locationId.toString()"
              class="selectable-access-control">
              <p>{{ room.name }}</p>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
</dts-select-container>
</ion-content>
<app-modal-confirmation
  [type]="'cardholder'"
  [content]=""
  [loading]="modalLoading"
  [errorReadingCard]="errorReadingCard"
  (decision)="onModalDecision($event)"
  *ngIf="openModal">
</app-modal-confirmation>
