
export class ControllerFilters {
    Cleaning: {
        Inspected: boolean | null,
        Cleaned: null | 0 | 1 | 2,
        HighestCleaningStatus: null | 0 | 1 | 2 | 3,
        MakeUpRoom: boolean | null
      };
    Hvac:{
        Active: boolean | null,
        Heating: boolean | null,
        Cooling: boolean | null,
        HvacSpeed: null | 1 | 2 | 3, // 1-low, 2-mid, 3-high
        FloorheatingRoom: boolean | null,
        FloorheatingBathroom: boolean | null
        TemperatureFilter: null | ITemperatureFilter,
        TemperatureDifferenceFilter: null | ITemperatureDifferenceFilter,
        DaikinTemperatureFilter: null | ITemperatureFilter,
        DaikinTemperatureDifferenceFilter: null | ITemperatureDifferenceFilter,
        LgTemperatureFilter: null | ITemperatureFilter,
        LgTemperatureDifferenceFilter: null | ITemperatureDifferenceFilter,
        BoschTemperatureFilter: null | ITemperatureFilter,
        BoschTemperatureDifferenceFilter: null | ITemperatureDifferenceFilter,
      };
    Statuses:{
        Online: boolean | null,
        Rented: boolean | null,
        CardDeposited: boolean | null
        AlarmActive: boolean | null,
        DoNotDisturb: boolean | null,
        RoomArmed: boolean | null,
        WindowOpen: boolean | null,
        DoorOpen: boolean | null,
        IgnoreWindow: boolean | null,
        IgnoreCardTray: boolean | null,
        MiniBarOpen: boolean | null,
        MaidAtLocation: boolean | null
    }

  constructor() {
    this.Cleaning = {
      Inspected:  null,
        Cleaned:  null,
        HighestCleaningStatus: null,
        MakeUpRoom:  null
    }

    this.Hvac = {
      Active:  null,
      Heating:  null,
      Cooling:  null,
      HvacSpeed: null,
      FloorheatingRoom: null,
      FloorheatingBathroom: null,
      TemperatureFilter: null ,
      TemperatureDifferenceFilter: null,
      DaikinTemperatureDifferenceFilter: null,
      DaikinTemperatureFilter: null,
      LgTemperatureDifferenceFilter: null,
      LgTemperatureFilter: null,
      BoschTemperatureDifferenceFilter: null,
      BoschTemperatureFilter: null
    };

    this.Statuses = {
        Online: null,
        Rented: null,
        CardDeposited: null,
        AlarmActive: null,
        DoNotDisturb: null,
        RoomArmed: null,
        WindowOpen: null,
        DoorOpen: null,
        IgnoreWindow: null,
        IgnoreCardTray: null,
        MaidAtLocation: null,
        MiniBarOpen: null
    }
  }
}

export interface ITemperatureFilter {
  Type: 0 | 1, // 0-Set, 1-Current
  Location: 0 | 1, // 0-Room, 1-Bathroom
  Comparison: 0 | 1 | 2, // 0-Equal, 1-GreaterThan, 2-Less than
  Value: number
}

export interface ITemperatureDifferenceFilter {
  Location: 0 | 1, // 0-Room, 1-Bathroom
  Comparison: 0 | 1 | 2, // 0-Equal, 1-GreaterThan, 2-Less than
  Value: number
}
