import { Component, Input, input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';

@Component({
  selector: 'app-alarm-type-popover',
  templateUrl: './alarm-type-popover.component.html',
  styleUrl: './alarm-type-popover.component.scss'
})
export class AlarmTypePopoverComponent implements OnInit {
  @Input() alarmTypes: AlarmType[];
  @Input() selectedAlarmTypes: number[];
  @Input() alarmTypesSubject: Subject<number[]>;


  constructor() {}

  ngOnInit(): void {
  }

  typeSelected(type: AlarmType, event: any) {
    if (event.detail.checked) {
      this.selectedAlarmTypes.push(type.alarmTypeId)
    } else {
      this.selectedAlarmTypes = this.selectedAlarmTypes.filter( element => element != type.alarmTypeId);
    }
    this.alarmTypesSubject.next(this.selectedAlarmTypes);
  }

  shouldCheck(type: AlarmType) {
    return this.selectedAlarmTypes.includes(+type.alarmTypeId);
  }

  clearSatusFilters() {
    this.selectedAlarmTypes = [];
    this.alarmTypesSubject.next(this.selectedAlarmTypes);
  }


}
