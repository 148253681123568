import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'findPropertyByCode'
})
export class FindPropertyByCodePipe implements PipeTransform {

  transform(controllers: Controller[], codeFrom: number, codeTo: number, updated?: any, viewSize?: 'minimal' |'large' ): Property[] {
    if (!controllers) {
      return null;
    }

    if (viewSize === 'minimal') {
      let targetDaikins: Property[] = [];
      let targetLgs: Property[] = [];
      let targetBoschs: Property[] = []

      const propTypeFrom = new Property()
      const propTypeTo = new Property()
      propTypeFrom.type = codeFrom
      propTypeTo.type = codeTo

      if (Property.isRoomTemperatureSet(propTypeFrom) && Property.isRoomTemperatureSet(propTypeTo)) {
        targetDaikins = controllers.map( cont => cont.controllerProperties.$values.find(
           prop => Property.isDaikinUnitSetTemperature(prop) && !prop.hidden))
        targetLgs = controllers.map( cont => cont.controllerProperties.$values.find(
           prop => Property.isLgUnitSetTemperature(prop) && !prop.hidden))
        targetBoschs = controllers.map( cont => cont.controllerProperties.$values.find(
        prop => Property.isBoschUnitSetTemperature(prop) && !prop.hidden))

    } else  if (Property.isRoomTemperatureCurrent(propTypeFrom) && Property.isRoomTemperatureCurrent(propTypeTo)) {
        targetDaikins = controllers.map( cont => cont.controllerProperties.$values.find(
           prop => Property.isDaikinUnitMeasuredTemperature(prop) && !prop.hidden))
        targetLgs = controllers.map( cont => cont.controllerProperties.$values.find(
            prop => Property.isLgUnitMeasuredTemperature(prop) && !prop.hidden))
        targetBoschs = controllers.map( cont => cont.controllerProperties.$values.find(
          prop => Property.isBoschUnitMeasuredTemperature(prop) && !prop.hidden))
    }
    if (targetDaikins[0]) {
      return targetDaikins;
    } else if (targetLgs[0]) {
      return targetLgs;
    } else if (targetBoschs[0]) {
      return targetBoschs
    }
  }


    const props =  controllers.reduce((allFilteredProps, controller)=> {
      const filteredPropsOnController = controller.controllerProperties.$values.filter((prop: Property) => {
        return (prop.type >= Number(codeFrom) && prop.type <= Number(codeTo) && !prop.hidden)
      });
      allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
      return allFilteredProps;
    },[]);

    return props
  }

  sortByLocation(controllerA: Controller, controllerB: Controller) {
    return Number(controllerA.subZone) - Number(controllerB.subZone)
  }

}

