import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Card } from 'src/app/core/models/card/card.model';
import { CustomTranslatePipe } from '../../pipes/custom-translate.pipe';
import { Location } from 'src/app/core/models/project/location.model';
import { ApiCardReaderService } from 'src/app/core/services/api-card-reader.service';

@Component({
  selector: 'app-ble-key-qr-code',
  templateUrl: './ble-key-qr-code.component.html',
  styleUrls: ['./ble-key-qr-code.component.scss']
})
export class BleKeyQrCodeComponent implements OnInit {
  @Input() card: Card;
  @Input() location: Location;

  activeMode: 'deepLink' | 'key' = 'deepLink';

  img: string
  textUnderQrCode: string;

  codeForDisplay: Array<{ id: string, qr: string }> = [];

  constructor(
    private apiCardReaderService: ApiCardReaderService,
    private toastController: ToastController,
    private pipe: CustomTranslatePipe,
    private modalController: ModalController
    ) {}

  ngOnInit(): void {
    this.apiCardReaderService.getCardDeepLinkQR(this.card.uid).subscribe(resp => {
      this.img = 'data:image/png;base64,' + resp;
    });
    this.apiCardReaderService.getCardDeepLinkText(this.card.uid).subscribe(resp => {
      this.textUnderQrCode = resp;
    });
  }

  async copyToClipboard(element) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.textUnderQrCode);
    } else {
      element.select();
      document.execCommand('copy');
      element.blur()
    }
    const toast = await this.toastController.create({
      message: this.pipe.transform('Link copied to clipboard'),
      duration:2000
    });
    await toast.present();
  }

  openQrCodeInNewWindow() {
    const myWindow = window.open('', '_blank', 'width=600,height=600');
    myWindow.document.write(`<img src=${this.img}>`);
  }

  sendLinkToGuestEmail() {
    this.apiCardReaderService.sendDeepLinkToCardEmail(this.card.uid).subscribe(async ()=> {
      const toast = await this.toastController.create({
        message: this.pipe.transform('Link sent to guest e-mail'),
        duration:2000
      });
      await toast.present();
    })
  }

  close() {
    this.modalController.dismiss();
  }

  onKeyModeClicked() {
    if (this.activeMode === 'key') {
      return
    }
    this.activeMode = 'key';
    this.apiCardReaderService.getCardCodeQR(this.card.uid).subscribe(resp => {
      this.img = 'data:image/png;base64,' + resp;
    });
  }

  onDeepLinkModeClicked() {
    if (this.activeMode === 'deepLink') {
      return
    }
    this.activeMode = 'deepLink';
    this.apiCardReaderService.getCardDeepLinkQR(this.card.uid).subscribe(resp => {
      this.img = 'data:image/png;base64,' + resp;
    });
  }


}
