import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRoomTemperatureData'
})
export class GetRoomTemperatureDataPipe implements PipeTransform {

  transform(controllers: Controller[], location: 'room' | 'bathroom', updated: Date):
      {baseSetProp: Property,  ecoHyst1Prop: Property, ecoHyst2Prop: Property, regulationProp?: Property}
    {

    if (location === 'bathroom') {
      const bathroomBaseSetProp: Property =  controllers[0].controllerProperties.$values.find(Property.HVACBathroomBaseSet);
      const bathroomEcoHyst1Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacBathroomEcoHyst1);
      const bathroomEcoHyst2Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacBathroomEcoHyst2);

      const bathroomData = {
        baseSetProp: bathroomBaseSetProp? bathroomBaseSetProp : undefined,
        ecoHyst1Prop: bathroomEcoHyst1Prop? bathroomEcoHyst1Prop : undefined,
        ecoHyst2Prop: bathroomEcoHyst2Prop? bathroomEcoHyst2Prop : undefined,
      }
      return bathroomData;
    } else {
      const roomBaseSetProp: Property =  controllers[0].controllerProperties.$values.find(Property.isHVACRoomBaseSet);
      const roomEcoHyst1Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacRoomEcoHyst1);
      const roomEcoHyst2Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacRoomEcoHyst2);
      const roomHvacRegulationProp: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacRegulation);

      const roomData = {
        baseSetProp: roomBaseSetProp? roomBaseSetProp : undefined,
        ecoHyst1Prop: roomEcoHyst1Prop? roomEcoHyst1Prop : undefined,
        ecoHyst2Prop: roomEcoHyst2Prop? roomEcoHyst2Prop : undefined,
        regulationProp: roomHvacRegulationProp? roomHvacRegulationProp : undefined
      }
      return roomData;
    }
  }

}
