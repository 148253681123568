import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { User } from 'src/app/core/models/user/user.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { MqttProjectService } from '../app-load/mqtt-project.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { ApiSettingsService, FrontendSettings } from 'src/app/modules/settings/services/http/api-settings.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { ApiLocationGroupsService } from 'src/app/shared/services/api-location-groups.service';
import { LocationGroupType } from '../models/location-group-type.enum';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CurrentUserStoreService } from './current-user-store.service';
import { ApiAlarmsService } from 'src/app/modules/alarms/services/http/api-alarms.service';
import { DEMO_MODE, USE_DALI } from 'src/environments/environment';
import { DemoModeService } from 'src/app/shared/services/demo-mode.service';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';

@Injectable({
    providedIn: 'root'
})
export class UserService {


    swPermissionId = SoftwarePermissionId;
    alarmsInitialized = false;
    alarmsService: AlarmAlertsService;
    alarmAlertComponentRef: any;
    mqttInitialised = false
    BASE_FRONTEND_CONFIG_URL='assets/config/baseFrontendConfig.json';

    constructor(
        private mqttProjectService: MqttProjectService,
        private apiProjectService: ApiProjectService,
        private api: ApiUsersService,
        private apiSettingsService: ApiSettingsService,
        private apiDaliProjectService: ApiDaliProjectService,
        private apiLocationGroupsService: ApiLocationGroupsService,
        private settingsService: SettingsService,
        private currentUserStoreService: CurrentUserStoreService,
        private apiAlarmsService: ApiAlarmsService,
        private demoModeService: DemoModeService,
        private http: HttpClient,
        private toastController: ToastController,
        private pipe: CustomTranslatePipe,
    ) {}

    async setUser(u: User) {
        const user = Object.assign(new User(), u);
        this.currentUserStoreService.setUser(user)
        if (DEMO_MODE) {
            this.demoModeService.setupDemoProject();
            return;
        }
        if(!this.mqttInitialised) {
            this.mqttInitialised = true;
            // const filtersForUser = StoredFilter.getGuestRoomFilterFromStorage(this.user.userId);
            // const filter = filtersForUser ? filtersForUser.filters : new ControllerFilters(['Room2'])
            // const commonAreafiltersForUser = StoredFilter.getCommonAreaFilterFromStorage(this.user.userId);
            // const commonAreaFilter = commonAreafiltersForUser ? commonAreafiltersForUser.filters :
            // new ControllerFilters(['AccessControl_v1', 'IOCommonArea', 'HvacCommonArea'])
            let msg: string;
            try {
              // frontend settings needed to setup view
              const frontendSettings: FrontendSettings = await lastValueFrom(this.apiSettingsService.getFrontendSettings());
              if (!frontendSettings || !frontendSettings.settings || !frontendSettings.settings.propertyDisplayConfigs
                || frontendSettings.settings.propertyDisplayConfigs.length === 0 ) {
              // if frontend config is invalid use default frontend config
              if (User.isAdria(u)) {
                  msg = 'Frontend configuration invalid. Using default frontend configuration. Go to Settings/Room overview and Save configuration.'
              } else {
                  msg = 'Frontend configuration invalid. Using default frontend configuration. Contact administrator to save configuration.'
              }
              await this.useDefaultFrontendConfig(msg)
              }
            }
            catch (err) {
              // if error fetching frontend config use default frontend config
              if (User.isAdria(u)) {
                msg = 'Frontend configuration unavailable. Using default frontend configuration. Go to Settings/Room overview and Save configuration.'
              } else {
                  msg = 'Frontend configuration unavailable. Using default frontend configuration. Contact administrator to save configuration.'
              }
              await this.useDefaultFrontendConfig(msg)
            }

            this.settingsService.setupViewConfigs();
            this.settingsService.initializeViewFromStorage(user);
            this.settingsService.initializeGuestStayShortcut(user);
            Promise.all(
              [
                lastValueFrom(this.api.getCardTypes()),
                lastValueFrom(this.apiProjectService.getObjects()),
                lastValueFrom(this.apiProjectService.getSubobjects()),
                lastValueFrom(this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.FloorGroup)),
                lastValueFrom(this.apiAlarmsService.getAlarmTypes()),
                lastValueFrom(this.apiProjectService.getAllLocationsFat()),
                lastValueFrom(this.apiSettingsService.getCardReaderDualCardSettings()).catch(()=> {
                  this.apiSettingsService.presentToast('Unable to fetch dual card reader settings')
                })
              ]).then(()=> {
                this.apiProjectService.projectReady$.next(true);
                if (USE_DALI) {
                  this.apiDaliProjectService.getProject().subscribe();
                }
                this.mqttProjectService.initMqttForProject();
            })
        }
    }

    async useDefaultFrontendConfig(msg: string) {
      this.presentToast(msg);
      const baseFrontendConfig:FrontendSettings = await lastValueFrom(this.http.get<any>(this.BASE_FRONTEND_CONFIG_URL));
      this.settingsService.setFrontendSettings(baseFrontendConfig);
    }

    async presentToast(msg: string) {
      const toast = await this.toastController.create(
      {
        message: this.pipe.transform(msg),
        duration: 10000,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
          }
        ]
      }
    );
      await toast.present();
  }
}
