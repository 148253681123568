import { Details } from './details.model';

export class Property {
  // controller property
  id: number;
  type: number;
  name: string;
  description: string;
  mqttTopic: string;
  readDetails: Details;
  writeDetails: Details;
  defaultValue: string;
  preActionFunction: string;
  postActionFunction: string;
  value?; // TODO define value
  readLocationType: string;
  readLocationAddress: string;
  readDataType: string;
  writeLocationType: string;
  writeLocationAddress: string;
  writeDataType: string;
  isAlarm: number;
  alarmValue?: any;
  controller?: any;
  hidden?: boolean;
  logValue?: boolean

  constructor() {}

  static isCleaningStatus(property: Property): boolean {
    return property.type >= 3200 && property.type <= 3249;
  }

  static isHighestCleaningStatus(property: Property): boolean {
    return property.type >= 3500 && property.type <= 3500;
  }

  static isInspectedStatus(property: Property): boolean {
    return property.type >= 3250 && property.type <= 3299;
  }

  // doors and windows

  static isDoorOpened(property: Property): boolean {
    return property.type >= 1000 && property.type <= 1049;
  }

  static isDoorOpenTooLongAlarm(property: Property): boolean {
    return (
      property.type >= 1000 &&
      property.type <= 1049 &&
      Number(property.value) === 9
    );
  }

  static isDoorLock(property: Property): boolean {
    return property.type >= 1650 && property.type <= 1699;
  }

  static isWindowOpened(property: Property): boolean {
    return property.type >= 1050 && property.type <= 1099;
  }

  static isWindowOpenTooLongAlarm(property: Property): boolean {
    return (
      property.type >= 1050 &&
      property.type <= 1099 &&
      Number(property.value) === 9
    );
  }

  static isDoorLockBatteryLow(property: Property) {
    return property.type >= 1700 && property.type <= 1749;
  }

  static isBurglaryDetectedWindow(property: Property) {
    return property.type >= 8210 && property.type <= 8219;
  }
  static isBurglaryDetectedDoor(property: Property) {
    return property.type >= 8220 && property.type <= 8229;
  }

  // Access control

  static ACDoorOpenPushButton(property: Property) {
    return property.type >= 1750 && property.type <= 1799;
  }

  static ACDoorOpenAnyCard(property: Property) {
    return property.type >= 1800 && property.type <= 1849;
  }

  static ACDoorGuestDenyFrom(property: Property) {
    return property.type >= 1850 && property.type <= 1874;
  }

  static ACDoorGuestDenyTo(property: Property) {
    return property.type >= 1875 && property.type <= 1899;
  }

  static ACAlwaysOpen(property: Property) {
    return property.type >= 1900 && property.type <= 1949;
  }

  static ACDenyGuests(property: Property) {
    return property.type >= 1950 && property.type <= 1959;
  }
  static ACDenyStaff(property: Property) {
    return property.type >= 1960 && property.type <= 1969;
  }
  static ACDenyAll(property: Property) {
    return property.type >= 1970 && property.type <= 1979;
  }

  // misc

  static isDndStatus(property: Property): boolean {
    return property.type >= 3000 && property.type <= 3049;
  }

  static isMinibarWasOpenedStatus(property: Property): boolean {
    return property.type >= 3150 && property.type <= 3199;
  }

  static isWelcomeLightStatus(property: Property): boolean {
    return property.type >= 10650 && property.type <= 10699;
  }

  // hvac

  static isRoomTemperatureCurrent(property: Property) {
    return property.type >= 6200 && property.type <= 6249;
  }

  static isRoomTemperatureSet(property: Property) {
    return property.type >= 6250 && property.type <= 6299;
  }

  static isHVACRoomBaseSet(property: Property) {
    return property.type >= 5800 && property.type <= 5849;
  }

  static isBathroomTemperatureCurrent(property: Property) {
    return property.type >= 6300 && property.type <= 6349;
  }

  static isBathroomTemperatureSet(property: Property) {
    return property.type >= 6350 && property.type <= 6399;
  }

  static isCO2Measurement(property: Property) {
    return property.type >= 7050 && property.type <= 7069;
  }

  static isHumidity(property: Property) {
    return property.type >= 7075 && property.type <= 7099;
  }

  static HVACBathroomBaseSet(property: Property) {
    return property.type >= 6050 && property.type <= 6099;
  }

  static isFancoilSpeed(property: Property) {
    return (
      Property.isFancoilV1(property) ||
      Property.isFancoilV2(property) ||
      Property.isFancoilV3(property)
    );
  }

  static fancoilMode(property: Property) {
    return (Property.isHvacRoomControlFancoilOff(property) ||
    Property.isHvacRoomControlFancoilLow(property) ||
    Property.isHvacRoomControlFancoilMedium(property) ||
    Property.isHvacRoomControlFancoilHigh(property) ||
    Property.isHvacRoomControlFancoilAuto(property))
  }

  static isFancoilV1(property: Property) {
    return property.type >= 5300 && property.type <= 5309;
  }

  static isFancoilV2(property: Property) {
    return property.type >= 5310 && property.type <= 5319;
  }

  static isFancoilV3(property: Property) {
    return property.type >= 5320 && property.type <= 5329;
  }

  static isFancoilVAuto(property: Property) {
    return property.type >= 5330 && property.type <= 5339;
  }

  static isBathroomRadiator(property: Property) {
    return property.type >= 5400 && property.type <= 5400;
  }

  static isRoomRadiator(property: Property) {
    return property.type >= 5350 && property.type <= 5350;
  }

  static isBathroomFloorHeating(property: Property) {
    return property.type >= 5500 && property.type <= 5549;
  }

  static isRoomFloorHeating(property: Property) {
    return property.type >= 5450 && property.type <= 5499;
  }
/*
  static isHVACLargeTempDiff(property: Property) {
    return property.type >= 5050 && property.type <= 5099;
  } */

  static isHVACLargeTempDiffRoom(property: Property) {
    return property.type >= 5050 && property.type <= 5074;
  }

  static isHVACLargeTempDiffBathroom(property: Property) {
    return property.type >= 5075 && property.type <= 5099;
  }

  static isHvacRegulation(property: Property) {
    return property.type >= 5950 && property.type <= 5999;
  }

  static isHvacCalibration(property: Property) {
    return property.type >= 6000 && property.type <= 6049;
  }

  static isHvacRoomEcoHyst1(property: Property) {
    return property.type >= 5850 && property.type <= 5899;
  }

  static isHvacRoomEcoHyst2(property: Property) {
    return property.type >= 5900 && property.type <= 5949;
  }

  static isHvacBathroomEcoHyst1(property: Property) {
    return property.type >= 6100 && property.type <= 6149;
  }

  static isHvacBathroomEcoHyst2(property: Property) {
    return property.type >= 6150 && property.type <= 6199;
  }

  static isHvacHeatCool(property: Property) { // 0 is heating, 1 is cooling. 0-heating, 1-cooling, 2-auto can be sent
    return property.type >= 5200 && property.type <= 5224;
  }

  static isHvacHeatCoolAuto(property: Property) { // 0 is auto off, 1 is auto on
    return property.type >= 5225 && property.type <= 5249;
  }

  static isFlood(property: Property) {
    return property.type >= 9050 && property.type <= 9099;
  }

  // HVAC

  static isHvacActive(property: Property) {
    return property.type >= 5000 && property.type <= 5024;
  }

  static isHvacToggle(property: Property) {
    return property.type >= 5025 && property.type <= 5049;
  }

  static isHvacRoomControlFancoilOff(property: Property) {
    return property.type >= 6500 && property.type <= 6549;
  }

  static isHvacRoomControlFancoilLow(property: Property) {
    return property.type >= 6550 && property.type <= 6599;
  }

  static isHvacRoomControlFancoilMedium(property: Property) {
    return property.type >= 6600 && property.type <= 6649;
  }

  static isHvacRoomControlFancoilHigh(property: Property) {
    return property.type >= 6650 && property.type <= 6699;
  }

  static isHvacRoomControlFancoilAuto(property: Property) {
    return property.type >= 6700 && property.type <= 6749;
  }

  // static isHvacRoomControlFancoilMutex(property: Property): boolean {
  //   return Property.isHvacRoomControlFancoilOff(property) ||
  //   Property.isHvacRoomControlFancoilLow(property) ||
  //   Property.isHvacRoomControlFancoilMedium(property) ||
  //   Property.isHvacRoomControlFancoilHigh(property) ||
  //   Property.isHvacRoomControlFancoilAuto(property)
  // }

  static isTemperatureProperty(property: Property): boolean {
    return Property.isRoomTemperatureCurrent(property) ||
    Property.isRoomTemperatureSet(property) ||
    Property.isBathroomTemperatureCurrent(property) ||
    Property.isBathroomTemperatureSet(property) ||
    Property.isDaikinUnitMeasuredTemperature(property) ||
    Property.isDaikinUnitSetTemperature(property) ||
    Property.isLgUnitMeasuredTemperature(property) ||
    Property.isLgUnitSetTemperature(property) ||
    Property.isBoschUnitMeasuredTemperature(property) ||
    Property.isBoschUnitSetTemperature(property)
  }

  // SAFE

  static isSafeBurglary(property: Property) {
    return property.type >= 8100 && property.type <= 8149;
  }

  static isSafeCommError(property: Property) {
    return property.type >= 8150 && property.type <= 8199;
  }

  static isSafeDoor(property: Property) {
    return property.type >= 1200 && property.type <= 1249;
  }

  static isRoomArmed(property: Property) {
    return property.type >= 8000 && property.type <= 8049;
  }

  static isBurglary(property: Property) {
    return property.type >= 8050 && property.type <= 8099;
  }

  static isBurglaryDetectedByPIR(property: Property) {
    return property.type >= 8200 && property.type <= 8209;
  }

  static isIgnoreCardTray(property: Property) {
    return property.type >= 10200 && property.type <= 10249;
  }

  static isCardType(property: Property) {
    return property.type >= 1450 && property.type <= 1474;
  }

  static isCardPosition(property: Property) {
    return property.type >= 1475 && property.type <= 1499;
  }

  static isIgnoreWindow(property: Property) {
    return property.type >= 10250 && property.type <= 10299;
  }

  static isSOS(property: Property) {
    return property.type >= 9000 && property.type <= 9049;
  }

  static isPirInput(property: Property) {
    return property.type >= 11000 && property.type <= 11049;
  }

  static isPanelBlocked(property: Property) {
    return property.type >= 10050 && property.type <= 10099;
  }

  static isCardDeposited(property: Property) {
    return property.type >= 1250 && property.type <= 1299;
  }

  static isValveHeating(property: Property) {
    return property.type >= 5550 && property.type <= 5599;
  }

  static isValveCooling(property: Property) {
    return property.type >= 5600 && property.type <= 5649;
  }

  static isNewCard(property: Property) {
    return property.type >= 1300 && property.type <= 1349;
  }

  static isDumper(property: Property) {
    return property.type >= 5750 && property.type <= 5774;
  }

  static isDumperControl(property: Property) {
    return property.type >= 5775 && property.type <= 5799;
  }

  static isContactor(property: Property) {
    return property.type >= 10300 && property.type <= 10349;
  }

  static isWelcomeLight(property: Property) {
    return property.type >= 10650 && property.type <= 10699;
  }

  static isLight(property: Property) {
    return property.type >= 10700 && property.type <= 10749;
  }

  static isLuxomat(property: Property) {
    return property.type >= 12050 && property.type <= 12099;
  }

  static isLuxomatBrodcast(property: Property) {
    return property.type >= 12100 && property.type <= 12109;
  }

  static isMakeUpRoom(property: Property) {
    return property.type >= 3050 && property.type <= 3099;
  }

  static isCommunicationError(property: Property) {
    return property.type === 20000
  }

  static isAnyBurglaryProperty(property: Property) {
    return (
      this.isBurglary(property) ||
      this.isBurglaryDetectedByPIR(property) ||
      this.isBurglaryDetectedWindow(property) ||
      this.isBurglaryDetectedDoor(property) ||
      this.isSafeBurglary(property)
    );
  }

  static isAnyAlarm(property: Property) {
    return (
      Property.isAnyBurglaryProperty(property) ||
      Property.isSafeCommError(property) ||
      Property.isHVACLargeTempDiffRoom(property) ||
      Property.isHVACLargeTempDiffBathroom(property) ||
      Property.isFlood(property) ||
      Property.isBurglaryDetectedByPIR(property) ||
      Property.isSOS(property) ||
      Property.isDoorOpenTooLongAlarm(property) ||
      Property.isWindowOpenTooLongAlarm(property)
    );
  }

  // IO common area

  static isOutputMode(property: Property) {
    return property.type >= 12110 && property.type <= 12149;
  }

  static isOutputStartTimeT1(property: Property) {
    return property.type >= 12150 && property.type <= 12199;
  }

  static isOutputEndTimeT1(property: Property) {
    return property.type >= 12200 && property.type <= 12249;
  }

  static isOutputStartTimeT2(property: Property) {
    return property.type >= 12250 && property.type <= 12299;
  }

  static isOutputEndTimeT2(property: Property) {
    return property.type >= 12300 && property.type <= 12349;
  }

  static isBalconyLightSwitch(property: Property): boolean {
    return property.type >= 10900 && property.type <= 10924;
  }

  static isForceBalconyLight(property: Property) {
    return property.type >= 10925 && property.type <= 10949;
  }

  static isVrvOnOff(property: Property) {
    return property.type >= 5340 && property.type <= 5344;
  }

  static isVrvSpeed(property: Property) { // 0 low 1 high
    return property.type >= 5345 && property.type <= 5349;
  }

  static isActive(property: Property) {
    return (
      property?.value !== null &&
      property?.value !== undefined &&
      Number(property?.value) !== 0
    );
  }

  static isInactive(property: Property) {
    return (
      property?.value !== null &&
      property?.value !== undefined &&
      (property?.value === 0 || property?.value === '0')
    );
  }

  // NEW
  static isControllerTempHigh(property: Property) {
    return property.type >= 9200 && property.type <= 9249;
  }

  static isLowWindowOpenTooLong(property: Property): boolean {
    return property.type >= 1050 && property.type <= 1099;
  }

  static isRoomBurglaryPIR(property: Property) {
    return property.type >= 8230 && property.type <= 8239;
  }

  static isFuse(property: Property) {
    return property.type >= 10150 && property.type <= 10199;
  }

  static isPowerOutage(property: Property) {
    return property.type >= 10100 && property.type <= 10149;
  }

  static isFire(property: Property) {
    return property.type >= 9250 && property.type <= 9299;
  }

  static isWind(property: Property) {
    return property.type >= 9150 && property.type <= 9199;
  }

  static isCO2(property: Property) {
    return property.type >= 9100 && property.type <= 9149;
  }

  static isBathroomRadiatorByRented(property: Property) {
    return property.type >= 6810 && property.type <= 6810;
  }

  static isBathroomRadiatorForceOn(property: Property) {
    return property.type >= 6830 && property.type <= 6830;
  }

  static isBathroomRadiatorForceOff(property: Property) {
    return property.type >= 6840 && property.type <= 6840;
  }

  static isBathroomRadiatorInCooling(property: Property) {
    return property.type >= 6820 && property.type <= 6820;
  }

  static isRadiatorByRented(property: Property) {
    return property.type >= 6760 && property.type <= 6760;
  }

  static isRadiatorForceOn(property: Property) {
    return property.type >= 6780 && property.type <= 6780;
  }

  static isRadiatorForceOff(property: Property) {
    return property.type >= 6790 && property.type <= 6790;
  }

  static isRadiatorInCooling(property: Property) {
    return property.type >= 6770 && property.type <= 6770;
  }

  static isBurglaryIncludePIR(property: Property) {
    return property.type >= 8230 && property.type <= 8239;
  }

  static isBurglaryIncludeWindow(property: Property) {
    return property.type >= 8240 && property.type <= 8249;
  }

  static isBurglaryIncludeDoor(property: Property) {
    return property.type >= 8250 && property.type <= 8250;
  }

  static isDaikinUnitOnOff(property: Property) {
    return property.type >= 7200 && property.type <= 7209;
  }

  static isDaikinUnitStatus(property: Property) {
    return property.type >= 7210 && property.type <= 7219;
  }

  static isDaikinUnitMode(property: Property) {
    return property.type >= 7220 && property.type <= 7229;
  }

  static isDaikinUnitSetTemperature(property: Property) {
    return property.type >= 7230 && property.type <= 7239;
  }

  static isDaikinUnitMeasuredTemperature(property: Property) {
    return property.type >= 7240 && property.type <= 7249;
  }

  static isDaikinUnitFanSpeed(property: Property) {
    return property.type >= 7250 && property.type <= 7259;
  }

  static isLgUnitOnOff(property: Property) {
    return property.type >= 7400 && property.type <= 7409;
  }

  static isLgUnitStatus(property: Property) {
    return property.type >= 7410 && property.type <= 7419;
  }

  static isLgUnitOperationMode(property: Property) {
    return property.type >= 7420 && property.type <= 7429;
  }

  static isLgUnitSetTemperature(property: Property) {
    return property.type >= 7430 && property.type <= 7439;
  }

  static isLgUnitMeasuredTemperature(property: Property) {
    return property.type >= 7440 && property.type <= 7449;
  }

  static isLgUnitFanSpeed(property: Property) {
    return property.type >= 7450 && property.type <= 7459;
  }

  static isBoschUnitOnOff(property: Property) {
    return property.type >= 7500 && property.type <= 7509;
  }

  static isBoschUnitStatus(property: Property) {
    return property.type >= 7510 && property.type <= 7519;
  }

  static isBoschUnitOperationMode(property: Property) {
    return property.type >= 7520 && property.type <= 7529;
  }

  static isBoschUnitSetTemperature(property: Property) {
    return property.type >= 7530 && property.type <= 7539;
  }

  static isBoschUnitMeasuredTemperature(property: Property) {
    return property.type >= 7540 && property.type <= 7549;
  }

  static isBoschUnitFanSpeed(property: Property) {
    return property.type >= 7550 && property.type <= 7559;
  }

  static isRelayOutputStatus(property: Property) {
    return property.type >= 5351 && property.type <= 5359;
  }

  static isRelayOutputControl(property: Property) {
    return property.type >= 6751 && property.type <= 6759;
  }
  static isRelayOutputForceOn(property: Property) {
    return property.type >= 6781 && property.type <= 6789;
  }
  static isRelayOutputForceOff(property: Property) {
    return property.type >= 6791 && property.type <= 6799;
  }
  static isRelayOutputByRentedOrPresence(property: Property) {
    return property.type >= 6761 && property.type <= 6769;
  }
  static isRelayOutputInCooling(property: Property) {
    return property.type >= 6771 && property.type <= 6779;
  }

  static isAnyNonStatusRelayOutputProperty(property: Property) {
    return (
      Property.isRelayOutputControl(property) ||
      Property.isRelayOutputForceOn(property) ||
      Property.isRelayOutputForceOff(property) ||
      Property.isRelayOutputByRentedOrPresence(property) ||
      Property.isRelayOutputInCooling(property)
    );
  }

  static isCO2SetPoint(property: Property) {
    return property.type >= 7070 && property.type <= 7074;
  }

  static isMeasuredTemperature3(property: Property) {
    return property.type >= 6990 && property.type <= 6999;
  }

  static isSameProperty(prop1: Property, prop2: Property) {
    if (Property.isForceBalconyLight(prop1) && Property.isForceBalconyLight(prop2)) {return true;}
    else if (Property.isForceBalconyLight(prop1) && Property.isForceBalconyLight(prop2)) {return true;}
    else if (Property.isBalconyLightSwitch(prop1) && Property.isBalconyLightSwitch(prop2)) {return true;}
    else if (Property.isOutputEndTimeT2(prop1) && Property.isOutputEndTimeT2(prop2)) {return true;}
    else if (Property.isOutputStartTimeT2(prop1) && Property.isOutputStartTimeT2(prop2)) {return true;}
    else if (Property.isOutputEndTimeT1(prop1) && Property.isOutputEndTimeT1(prop2)) {return true;}
    else if (Property.isOutputStartTimeT1(prop1) && Property.isOutputStartTimeT1(prop2)) {return true;}
    else if (Property.isOutputMode(prop1) && Property.isOutputMode(prop2)) {return true;}
    else if (Property.isAnyAlarm(prop1) && Property.isAnyAlarm(prop2)) {return true;}
    else if (Property.isCommunicationError(prop1) && Property.isCommunicationError(prop2)) {return true;}
    else if (Property.isMakeUpRoom(prop1) && Property.isMakeUpRoom(prop2)) {return true;}
    else if (Property.isLuxomatBrodcast(prop1) && Property.isLuxomatBrodcast(prop2)) {return true;}
    else if (Property.isLuxomat(prop1) && Property.isLuxomat(prop2)) {return true;}
    else if (Property.isLight(prop1) && Property.isLight(prop2)) {return true;}
    else if (Property.isWelcomeLight(prop1) && Property.isWelcomeLight(prop2)) {return true;}
    else if (Property.isContactor(prop1) && Property.isContactor(prop2)) {return true;}
    else if (Property.isDumper(prop1) && Property.isDumper(prop2)) {return true;}
    else if (Property.isNewCard(prop1) && Property.isNewCard(prop2)) {return true;}
    else if (Property.isValveCooling(prop1) && Property.isValveCooling(prop2)) {return true;}
    else if (Property.isValveHeating(prop1) && Property.isValveHeating(prop2)) {return true;}
    else if (Property.isCardDeposited(prop1) && Property.isCardDeposited(prop2)) {return true;}
    else if (Property.isPanelBlocked(prop1) && Property.isPanelBlocked(prop2)) {return true;}
    else if (Property.isPirInput(prop1) && Property.isPirInput(prop2)) {return true;}
    else if (Property.isSOS(prop1) && Property.isSOS(prop2)) {return true;}
    else if (Property.isIgnoreWindow(prop1) && Property.isIgnoreWindow(prop2)) {return true;}
    else if (Property.isCardType(prop1) && Property.isCardType(prop2)) {return true;}
    else if (Property.isIgnoreCardTray(prop1) && Property.isIgnoreCardTray(prop2)) {return true;}
    else if (Property.isBurglaryDetectedByPIR(prop1) && Property.isBurglaryDetectedByPIR(prop2)) {return true;}
    else if (Property.isBurglary(prop1) && Property.isBurglary(prop2)) {return true;}
    else if (Property.isRoomArmed(prop1) && Property.isRoomArmed(prop2)) {return true;}
    else if (Property.isSafeDoor(prop1) && Property.isSafeDoor(prop2)) {return true;}
    else if (Property.isSafeCommError(prop1) && Property.isSafeCommError(prop2)) {return true;}
    else if (Property.isSafeBurglary(prop1) && Property.isSafeBurglary(prop2)) {return true;}
    else if (Property.isHvacActive(prop1) && Property.isHvacActive(prop2)) {return true;}
    else if (Property.isFlood(prop1) && Property.isFlood(prop2)) {return true;}
    else if (Property.isHvacHeatCool(prop1) && Property.isHvacHeatCool(prop2)) {return true;}
    else if (Property.isHvacHeatCoolAuto(prop1) && Property.isHvacHeatCoolAuto(prop2)) {return true;}
    else if (Property.isHvacBathroomEcoHyst2(prop1) && Property.isHvacBathroomEcoHyst2(prop2)) {return true;}
    else if (Property.isHvacBathroomEcoHyst1(prop1) && Property.isHvacBathroomEcoHyst1(prop2)) {return true;}
    else if (Property.isHvacRoomEcoHyst2(prop1) && Property.isHvacRoomEcoHyst2(prop2)) {return true;}
    else if (Property.isHvacRoomEcoHyst1(prop1) && Property.isHvacRoomEcoHyst1(prop2)) {return true;}
    else if (Property.isHvacCalibration(prop1) && Property.isHvacCalibration(prop2)) {return true;}
    else if (Property.isHvacRegulation(prop1) && Property.isHvacRegulation(prop2)) {return true;}
    else if (Property.isHVACLargeTempDiffRoom(prop1) && Property.isHVACLargeTempDiffRoom(prop2)) {return true;}
    else if (Property.isHVACLargeTempDiffBathroom(prop1) && Property.isHVACLargeTempDiffBathroom(prop2)) {return true;}
    else if (Property.isRoomFloorHeating(prop1) && Property.isRoomFloorHeating(prop2)) {return true;}
    else if (Property.isBathroomFloorHeating(prop1) && Property.isBathroomFloorHeating(prop2)) {return true;}
    else if (Property.isBathroomRadiator(prop1) && Property.isBathroomRadiator(prop2)) {return true;}
    else if (Property.isRoomRadiator(prop1) && Property.isRoomRadiator(prop2)) {return true;}
    else if (Property.fancoilMode(prop1) && Property.fancoilMode(prop2)) {return true;}
    else if (Property.isFancoilSpeed(prop1) && Property.isFancoilSpeed(prop2)) {return true;}
    else if (Property.isBathroomTemperatureSet(prop1) && Property.isBathroomTemperatureSet(prop2)) {return true;}
    else if (Property.isBathroomTemperatureCurrent(prop1) && Property.isBathroomTemperatureCurrent(prop2)) {return true;}
    else if (Property.isRoomTemperatureSet(prop1) && Property.isRoomTemperatureSet(prop2)) {return true;}
    else if (Property.isRoomTemperatureCurrent(prop1) && Property.isRoomTemperatureCurrent(prop2)) {return true;}
    else if (Property.isWelcomeLightStatus(prop1) && Property.isWelcomeLightStatus(prop2)) {return true;}
    else if (Property.isMinibarWasOpenedStatus(prop1) && Property.isMinibarWasOpenedStatus(prop2)) {return true;}
    else if (Property.isDndStatus(prop1) && Property.isDndStatus(prop2)) {return true;}
    else if (Property.ACDenyAll(prop1) && Property.ACDenyAll(prop2)) {return true;}
    else if (Property.ACDenyStaff(prop1) && Property.ACDenyStaff(prop2)) {return true;}
    else if (Property.ACDenyGuests(prop1) && Property.ACDenyGuests(prop2)) {return true;}
    else if (Property.ACAlwaysOpen(prop1) && Property.ACAlwaysOpen(prop2)) {return true;}
    else if (Property.ACDoorGuestDenyTo(prop1) && Property.ACDoorGuestDenyTo(prop2)) {return true;}
    else if (Property.ACDoorGuestDenyFrom(prop1) && Property.ACDoorGuestDenyFrom(prop2)) {return true;}
    else if (Property.ACDoorOpenAnyCard(prop1) && Property.ACDoorOpenAnyCard(prop2)) {return true;}
    else if (Property.ACDoorOpenPushButton(prop1) && Property.ACDoorOpenPushButton(prop2)) {return true;}
    else if (Property.isBurglaryDetectedDoor(prop1) && Property.isBurglaryDetectedDoor(prop2)) {return true;}
    else if (Property.isBurglaryDetectedWindow(prop1) && Property.isBurglaryDetectedWindow(prop2)) {return true;}
    else if (Property.isDoorLockBatteryLow(prop1) && Property.isDoorLockBatteryLow(prop2)) {return true;}
    else if (Property.isWindowOpenTooLongAlarm(prop1) && Property.isWindowOpenTooLongAlarm(prop2)) {return true;}
    else if (Property.isWindowOpened(prop1) && Property.isWindowOpened(prop2)) {return true;}
    else if (Property.isDoorLock(prop1) && Property.isDoorLock(prop2)) {return true;}
    else if (Property.isDoorOpenTooLongAlarm(prop1) && Property.isDoorOpenTooLongAlarm(prop2)) {return true;}
    else if (Property.isDoorOpened(prop1) && Property.isDoorOpened(prop2)) {return true;}
    else if (Property.isInspectedStatus(prop1) && Property.isInspectedStatus(prop2)) {return true;}
    else if (Property.isCleaningStatus(prop1) && Property.isCleaningStatus(prop2)) {return true;}
    else if (Property.isHighestCleaningStatus(prop1) && Property.isHighestCleaningStatus(prop2)) {return true;}
    else if (Property.isBathroomRadiatorByRented(prop1) && Property.isBathroomRadiatorByRented(prop2)) {return true;}
    else if (Property.isBathroomRadiatorForceOn(prop1) && Property.isBathroomRadiatorForceOn(prop2)) {return true;}
    else if (Property.isBathroomRadiatorInCooling(prop1) && Property.isBathroomRadiatorInCooling(prop2)) {return true;}
    else if (Property.isRadiatorByRented(prop1) && Property.isRadiatorByRented(prop2)) {return true;}
    else if (Property.isRadiatorForceOn(prop1) && Property.isRadiatorForceOn(prop2)) {return true;}
    else if (Property.isRadiatorInCooling(prop1) && Property.isRadiatorInCooling(prop2)) {return true;}
    else if (Property.isDaikinUnitOnOff(prop1) && Property.isDaikinUnitOnOff(prop2)) {return true;}
    else if (Property.isDaikinUnitStatus(prop1) && Property.isDaikinUnitStatus(prop2)) {return true;}
    else if (Property.isDaikinUnitMode(prop1) && Property.isDaikinUnitMode(prop2)) {return true;}
    else if (Property.isDaikinUnitSetTemperature(prop1) && Property.isDaikinUnitSetTemperature(prop2)) {return true;}
    else if (Property.isDaikinUnitMeasuredTemperature(prop1) && Property.isDaikinUnitMeasuredTemperature(prop2)) {return true;}
    else if (Property.isDaikinUnitFanSpeed(prop1) && Property.isDaikinUnitFanSpeed(prop2)) {return true;}
    else if (Property.isRelayOutputStatus(prop1) && Property.isRelayOutputStatus(prop2)) {return true;}
    else if (Property.isRelayOutputControl(prop1) && Property.isRelayOutputControl(prop2)) {return true;}
    else if (Property.isRelayOutputForceOn(prop1) && Property.isRelayOutputForceOn(prop2)) {return true;}
    else if (Property.isRelayOutputForceOff(prop1) && Property.isRelayOutputForceOff(prop2)) {return true;}
    else if (Property.isRelayOutputByRentedOrPresence(prop1) && Property.isRelayOutputByRentedOrPresence(prop2)) {return true;}
    else if (Property.isRelayOutputInCooling(prop1) && Property.isRelayOutputInCooling(prop2)) {return true;}
    else if (Property.isHVACRoomBaseSet(prop1) && Property.isHVACRoomBaseSet(prop2)) {return true;}


    else {return false;}
  }
}
