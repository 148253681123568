import { Pipe, PipeTransform } from '@angular/core';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'subObjectHaveLocations'
})
export class SubObjectHaveLocations implements PipeTransform {

  transform(
    subObject: ProjectSubObject,
    locations: Location[],
    ): boolean {
    
    
      if (locations.length == 0) {
        return false
      } else {
        return locations.some( loc => loc.projectSubobjectId == subObject.projectSubobjectId)
      }
  }

}
