import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Controller } from 'src/app/core/models/controller.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model'
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Property } from 'src/app/core/models/project/property.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { DateTimeFromToComponent } from 'src/app/modules/logs/components/date-time-from-to/date-time-from-to.component';
import { ApiAccessLogsService } from 'src/app/modules/logs/services/http/api-access-logs.service';
import { ApiAlarmLogsService } from 'src/app/modules/logs/services/http/api-alarm-logs.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { PopoverController } from '@ionic/angular';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { UserSettingsService } from '../../services/user-settings.service';
import { DEMO_MODE } from 'src/environments/environment';
import { DemoModeService } from '../../services/demo-mode.service';
import { CustomTranslatePipe } from '../../pipes/custom-translate.pipe';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexTooltip,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexStroke
} from 'ng-apexcharts';
import { formatDate } from '@angular/common';

export interface IValueDictionaryItem {
  CodeFrom: number;
  CodeTo: number;
  activeName?: string,
  inactiveName?: string,
  name: string,
  mode: 'bool' | 'num' | 'custom(cardType)' | 'custom(openTooLong)'
}
export type LogTabType = 'access' | 'alarm' | 'valueLog' | 'temperature';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; // ApexMarkers;
  stroke: ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};


export interface LogData {
  controllerDesignation: string,
  propertyType: string // "6200"
  timestamp:string // "2024-06-07T11:25:43+02:00"
  value: number
}

@Component({
  selector: 'app-room-modal-log',
  templateUrl: './room-modal-log.component.html',
  styleUrls: ['./room-modal-log.component.scss']
})
export class RoomModalLogComponent implements OnInit {

  @ViewChild('dateTimeFromTo') dateTimeFromTo: DateTimeFromToComponent;
/*   @ViewChild('tablePropertiesSelect') tablePropertiesSelect: any;
  @ViewChild('tableTemperatureSelect') tableTemperatureSelect: any; */
  @Input() controllers: Controller[];
  // private readonly EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/cntproptypes.json';
  private readonly VALUE_LOG_TRANSLATION_URL: string = 'assets/config/valueLogTranslation.json';
  activeCard: LogTabType;
  // propertiesList: Array<any>;

  // new graph
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  // graph
  graphCreated = false;
  tableCreated = true;
  // OLDtempGraph: any[];
  // OLDcircumstancesGraph: any[];
  tableData: any = [];
  valueTableProperties: string[] = []; //  array of prop type codes as strings.  example ['1000', '1050']
  temperatureProperties: string[] = []; //  array of prop type codes as strings.  example ['1000', '1050']
  selectedController: Controller;
  LegendPosition = LegendPosition;

  body: ValueGraph = new ValueGraph();

  // options
  /* OLDgraphOptions = {
    // xAxisLabel : 'Time',
    // yAxisLabel: 'Values',
    yScaleMin : 0,
    yScaleMax : 35,
    yScaleHumidityMin : 0,
    yScaleHumidityMax : 100,
    yScaleCo2MeasurmentMin : 200,
    yScaleCo2MeasurmentMax : 2000,
  } */

  // colorScheme = {domain: ['#6BA0C4', '#03143A']};
  hideCustomTime = true;
  // valueLogProps: ((prop: Property)=> boolean)[]
  // temperatureLogProps: ((prop: Property)=> boolean)[]
  showSelect = true;

  Property = Property;

  // propSelect: any;
  // temperatureTypesSelect: ((prop:Property)=>boolean)[];

  eventLog = {
    page : 1,
    giveAccess : false,
    giveAlarm : false,
    logsLoading : false,
  }


  accessCheckbox = false;
  alarmCheckbox = false;

  queryParams = {
    pageNumber: 1,
    pageSize: 10000,
    includeArchive: false
  };

  valueDictionary: IValueDictionaryItem[] = [];
  cardTypes: CardType[];
  cardTypesSubscription: Subscription;
  signedInUser: User;
  swPermissions = SoftwarePermissionId;
  searchActive = false
  searchValue = '';
  localeId: string;
  propsHr: Property[] = [];
  // series: any[] = [];
  // yaxis: any[] = [];

  // showDoorSeries: boolean = undefined;
  // showWindowSeries: boolean = undefined;
  // showRoomTempSetSeries: boolean = undefined;
  // showRoomTempCurrentSeries: boolean = undefined;
  useHiddenFlag = true;

  customTemperatureAlertOptions = {
    cssClass: 'custom-temperature-props-alert'
  };

  // allValueLogProperties = this.logPropertiesService.valueLogProperties;
  // allRelayOutputLogProperties = this.logPropertiesService.relayOutputLogProperties;
  // allTemperatureLogProperties = this.logPropertiesService.temperatureLogProperties;

  chartColors =  ['#008080', '#800080', '#808000', '#000080', '#008000', '#800000', '#00FFFF', '#FF00FF', '#FFFF00', '#0000FF', '#00FF00', '#FF0000']// add more colors

  constructor(private apiProjectService: ApiProjectService,
              private apiAccessLogService: ApiAccessLogsService,
              private apiAlarmLogsService: ApiAlarmLogsService,
              private http: HttpClient,
              private cardsService: CardsService,
              private currentUserStoreService: CurrentUserStoreService,
              private popoverController: PopoverController,
              private userSettingsService: UserSettingsService,
              private demoModeService: DemoModeService,
              private pipe: CustomTranslatePipe,
              // private logPropertiesService: LogPropertiesService
              ) { }

  ngOnInit(): void {
    this.selectedController = this.controllers[0];
    this.localeId = this.userSettingsService.getLanguage();
    // this.http.get<any>(this.EQUIPMENT_PROPERTY_TYPES_URL).subscribe( resp => {
    //   this.propertiesList = resp.Definitions;
    // })
    this.http.get<IValueDictionaryItem[]>(this.VALUE_LOG_TRANSLATION_URL).subscribe( resp => {
      this.valueDictionary = resp;

    })
    this.cardTypes = this.cardsService.getCardTypes();
    // this.api.getCardTypes().subscribe();
    this.cardTypesSubscription = this.cardsService.cardTypesChanged.subscribe(() => this.cardTypes = this.cardsService.getCardTypes())
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());

    if (this.signedInUser.havePermission(SoftwarePermissionId.AccessLogView)) {
      this.activeCard = 'access';
    } else if (this.signedInUser.havePermission(SoftwarePermissionId.AlarmsLogView)) {
      this.activeCard = 'alarm';
    } else if (this.signedInUser.havePermission(SoftwarePermissionId.ValueLogView)) {
      this.activeCard = 'valueLog';
    }
    if(DEMO_MODE) {
     this.demoModeService.getData().subscribe( value => {
        this.body.properties.push(this.selectedController.controllerProperties.$values
          .find( prop => Property.isRoomTemperatureCurrent(prop)).type.toString())
        // this.temperatureTypesSelect = []
        // this.temperatureTypesSelect.push(Property.isRoomTemperatureCurrent)
        // this.OLDtempGraph = this.createDataFromResponse(value.TemperatureLog);
        this.tableData = value.ValueLog
        this.valueTableProperties = value.TableProperties
        // this.propSelect = [];
        // this.OLDcircumstancesGraph = [];
        // propSelect.push(Property.isDoorOpened)
        // this.propSelect.push(Property.isWindowOpened)
        this.tableCreated = true;
        this.eventLog.logsLoading = false
        // this.OLDgraphCreated = true;

      })
    }
    if (this.localeId === 'hr') {
      this.getPropNamesHr();
    }
  }

  initDataForNewGraph(rawData: any){


   /*  let roomTempSetDates = [];
    let roomTempSetValues = []; */
   /*  let roomTempDates = [];
    let roomTempValues = []; */
  /*   let radiatorBathroomDates = [];
    let radiatorBathroomValues = []; */
   /*  let doorDates = [];
    let doorValues = []; */
  /*   let windowDates = [];
    let windowsValues = []; */

   /*  let filledRoomTempSetValues = []; */
/*     let filledRoomTempValues = [];
    let filledRadiatorBathroomValues = []; */
   /*  let filledDoorValues = []; */
/*     let filledWindowValues = []; */

    const dataResults = this.createDataFromResponseNEW(rawData);

    let temperaturePropertiesMap = this.temperatureProperties.map((typeCode)=> {
      return {
        type: typeCode,
        name: this.getPropertyName(typeCode),
        values:[],
        dates:[]
      }
    })

    let valuePropertiesMap = this.valueTableProperties.map((typeCode)=> {
      return {
        type: typeCode,
        name: this.getPropertyName(typeCode),
        values:[],
        dates:[]
      }
    })

    dataResults.forEach((dataResult, index) => {
      const temperaturePropertiesMapIndex = temperaturePropertiesMap.findIndex((tempPropValues)=> {
        return tempPropValues.type === dataResult.type
      })
      if (temperaturePropertiesMapIndex !== -1) {
        temperaturePropertiesMap[temperaturePropertiesMapIndex].dates = dataResult.series.map(item => item.name.toLocaleString())
        temperaturePropertiesMap[temperaturePropertiesMapIndex].values = dataResult.series.map(item => item.value)
      }

      const valuePropertiesMapIndex = valuePropertiesMap.findIndex((valuePropValues)=> {
        return valuePropValues.type === dataResult.type
      })
      if (valuePropertiesMapIndex !== -1) {
        valuePropertiesMap[valuePropertiesMapIndex].dates = dataResult.series.map(item => item.name.toLocaleString())
        valuePropertiesMap[valuePropertiesMapIndex].values = dataResult.series.map(item => item.value)
      }
     /*  if(dataResult.name === 'RoomTemperatureSet'){
        roomTempSetValues = [...dataResults[index].series.map(item => item.value)];
        roomTempSetDates = [...dataResults[index].series.map(item => item.name.toLocaleString())];
      } */

      /* else if(element.name === 'RoomTemperature'){
        roomTempValues = [...results[index].series.map(item => item.value)];
        roomTempDates = [...results[index].series.map(item => item.name.toLocaleString())];
      }else if(element.name === 'RadiatorBathroom'){
        radiatorBathroomValues = [...results[index].series.map(item => item.value)];
        radiatorBathroomDates = [...results[index].series.map(item => item.name.toLocaleString())];
      } */
    });





  /*   if(this.OLDcircumstancesGraph.length > 0){
      this.OLDcircumstancesGraph.forEach((element, index) => {
        if(element.name === 'Door'){
          doorValues = [...this.OLDcircumstancesGraph[index].series.map(item => item.value)].map(value => value === 9 ? 1 : value);
          doorDates = [...this.OLDcircumstancesGraph[index].series.map(item => item.name.toLocaleString())];
        }else if(element.name === 'Window'){
          windowsValues = [...this.OLDcircumstancesGraph[index].series.map(item => item.value)].map(value => value === 9 ? 1 : value);
          windowDates = [...this.OLDcircumstancesGraph[index].series.map(item => item.name.toLocaleString())];
        }
      });
    } */



  /*   const combinedDates = Array.from(
      new Set([...doorDates, ...roomTempSetDates ]))
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()); */
    temperaturePropertiesMap = temperaturePropertiesMap.map((x)=> {
      return this.removeMiddleConsecutiveValues(x)
    })

    valuePropertiesMap = valuePropertiesMap.map((x)=> {
      return this.removeMiddleConsecutiveValues(x)
    })

      const temperaturePropertiesMapDates = temperaturePropertiesMap.reduce( (dates, entry) => {
        const newDates = entry.dates
        return [...newDates,...dates]
    }, [])
    const valuePropertiesMapDates = valuePropertiesMap.reduce( (dates, entry) => {
      const newDates = entry.dates
      return [...newDates,...dates]
  }, [])
    const combinedDatesNew = Array.from(new Set([
      ...temperaturePropertiesMapDates,
      ...valuePropertiesMapDates]))
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());


    /*   const temperaturePropertiesMapFilled = temperaturePropertiesMap.map((entry)=> {
        const newEntry = {
          type: entry.type,
          name: entry.name,
          values: [],
          dates: combinedDatesNew
        }
        newEntry.values = combinedDatesNew.map((date)=> {
          const index = entry.dates.indexOf(date);
          return index !== -1 ? entry.values[index] : null;
        })
        return newEntry
      }) */

      const temperaturePropertiesMapFilled = temperaturePropertiesMap.map((entry)=> {
        const entryDatesIndexMap = entry.dates.reduce((indexMap, date, index) => {
          indexMap[date] = index;
          return indexMap;
        }, {});

        const newEntry = {
          type: entry.type,
          name: entry.name,
          values: [],
          dates: combinedDatesNew
        }
        newEntry.values = combinedDatesNew.map((date)=> {
           const index = entryDatesIndexMap[date];
           return index ? entry.values[index] : null;
        })
        return newEntry
      })

   /*  const valuePropertiesMapFilled = valuePropertiesMap.map((entry)=> {
      const newEntry = {
        type: entry.type,
        name: entry.name,
        values: [],
        dates: combinedDatesNew
      }
      newEntry.values = combinedDatesNew.map((date)=> {
        const index = entry.dates.indexOf(date);
        return index !== -1 ? entry.values[index] : null;
      })
      return newEntry
    }) */


    const valuePropertiesMapFilled = valuePropertiesMap.map((entry)=> {
      const entryDatesIndexMap = entry.dates.reduce((indexMap, date, index) => {
        indexMap[date] = index;
        return indexMap;
      }, {});

      const newEntry = {
        type: entry.type,
        name: entry.name,
        values: [],
        dates: combinedDatesNew
      }
      newEntry.values = combinedDatesNew.map((date)=> {
        const index = entryDatesIndexMap[date];
        return index  ? entry.values[index] : null;
      })

      return newEntry
    })

    const temperaturePropertiesMapFilledAndInterpolated = temperaturePropertiesMapFilled.map((entry)=> {
      entry.values = this.interpolateMissingData(entry.values)
      return entry
    })
    const valuePropertiesMapFilledAndInterpolated = valuePropertiesMapFilled.map((entry)=> {
      entry.values = this.interpolateMissingData(entry.values)
      return entry
    })
    // const series = []
    /* if(doorValues.length > 0){
      filledDoorValues = combinedDates.map(date => {
        const index = doorDates.indexOf(date);
        return index !== -1 ? doorValues[index] : null;
      });
      const data = this.interpolateMissingData(filledDoorValues);
      this.createSeriesAndYAxis('Door', data, '#4CAF50', true, 1, 0, 1, false);
    } */
  /*   if(windowsValues.length > 0){
      filledWindowValues = combinedDates.map(date => {
        const index = windowDates.indexOf(date);
        return index !== -1 ? windowsValues[index] : null;
      });
      const data = this.interpolateMissingData(filledWindowValues);
      this.createSeriesAndYAxis('Window', data, '#448AFF', true, 1, 0, 1, false);
    } */

    /* if(roomTempSetValues.length > 0){
      filledRoomTempSetValues = combinedDates.map(date => {
        const index = roomTempSetDates.indexOf(date);
        return index !== -1 ? roomTempSetValues[index] : null;
      });
      const data = this.interpolateMissingData(filledRoomTempSetValues);
      this.createSeriesAndYAxis('Set room temperature', data, '#9C27B0', false, 7, 0, 35, true);
    } */
    const series = []
    const yAxis =[]

    let usingColorIndex = 0;

    temperaturePropertiesMapFilledAndInterpolated.forEach((temperatureMap)=> {
      series.push(this.createSeries(temperatureMap.name,temperatureMap.values,this.chartColors[usingColorIndex]))
      const minValue = 0;
      const maxValue = 35;
      yAxis.push(this.createYAxis(temperatureMap.name,this.chartColors[usingColorIndex],false,7,minValue,maxValue))
      usingColorIndex++
    })

    valuePropertiesMapFilledAndInterpolated.forEach((valueMap)=> {
      series.push(this.createSeries(valueMap.name,valueMap.values,this.chartColors[usingColorIndex]))
      let minValue = 0;
      let maxValue = 1;

      const prop = new Property();
      prop.type = Number(valueMap.type);

      if (Property.isCO2Measurement(prop)) {
        minValue = 200;
         maxValue = 2000;
      } else if (Property.isHumidity(prop)) {
        minValue = 0;
        maxValue = 100;
      } else if (Property.isHVACRoomBaseSet(prop) || Property.HVACBathroomBaseSet(prop)
        || Property.isHvacRoomEcoHyst1(prop) || Property.isHvacRoomEcoHyst2(prop)
        || Property.isHvacBathroomEcoHyst1(prop) || Property.isHvacBathroomEcoHyst2(prop)
        || Property.isHvacCalibration(prop) || Property.isHvacRegulation(prop)
      ) {
        minValue = 0;
        maxValue = 35;
      }else if (Property.isCardPosition(prop)) {
        minValue = 0;
        maxValue = 255;
      }

      yAxis.push(this.createYAxis(valueMap.name,this.chartColors[usingColorIndex],true,1,minValue,maxValue))
      usingColorIndex++
    })
    /* if(roomTempValues.length > 0){
      filledRoomTempValues = combinedDates.map(date => {
        const index = roomTempDates.indexOf(date);
        return index !== -1 ? roomTempValues[index] : null;
      });
      const data = this.interpolateMissingData(filledRoomTempValues);
      this.createSeriesAndYAxis('Current room temperature', data, '#F44336', false, 7, 0, 35, true);
    } */
 /*    if(radiatorBathroomValues.length > 0){
      filledRadiatorBathroomValues = combinedDates.map(date => {
        const index = radiatorBathroomDates.indexOf(date);
        return index !== -1 ? radiatorBathroomValues[index] : null;
      });
      const data = this.interpolateMissingData(filledRadiatorBathroomValues);
      this.createSeriesAndYAxis('Bathroom radiator', data, '#FF9800', false, 7, 0, 35, true);
    } */


      this.chartOptions = this.createChartOptions(combinedDatesNew, series, yAxis);
    // this.chartOptions = this.createChartOptions(combinedDates, this.series, this.yaxis);

   /*  filledRoomTempSetValues = [];
    filledRoomTempValues = [];
    filledRadiatorBathroomValues = [];
    doorValues = [];
    windowsValues = [];
    roomTempSetValues = [];
    roomTempValues = [];
    radiatorBathroomValues = [];
    this.series = [];
    this.yaxis = []; */
  }

  refreshTable() {
    this.body.dateTimeTo = new Date().toISOString();
    this.createGraph();
  }

  removeMiddleConsecutiveValues(valueMap : {type: any; name: any;values: any[]; dates: any[]; }) {
      const values = valueMap.values
      const dates = valueMap.dates
      const newValues = [];
      const newDates = [];
      let count = 0;
      let prev = null;
      let tempValues = [];
      let tempDates = [];

      for (let i = 0; i < values.length; i++) {
          if (values[i] === prev) {
              count++;
              tempValues.push(values[i]);
              tempDates.push(dates[i]);
          } else {
              if (count > 2) {
                  newValues.push(tempValues[0]);
                  newValues.push(tempValues[tempValues.length - 1]);
                  newDates.push(tempDates[0]);
                  newDates.push(tempDates[tempDates.length - 1]);
              } else {
                  newValues.push(...tempValues);
                  newDates.push(...tempDates);
              }

              count = 1;
              prev = values[i];
              tempValues = [values[i]];
              tempDates = [dates[i]];
          }
      }

      if (count > 2) {
          newValues.push(tempValues[0]);
          newValues.push(tempValues[tempValues.length - 1]);
          newDates.push(tempDates[0]);
          newDates.push(tempDates[tempDates.length - 1]);
      } else {
          newValues.push(...tempValues);
          newDates.push(...tempDates);
      }

      return { type: valueMap.type, name: valueMap.name, values: newValues, dates: newDates };
  }


  createSeries(
    nameString: string, values: any[], colorHex: string){
      return {
        name: nameString,
        type: 'line',
        data: values,
        color: colorHex,
      };
    }

  createYAxis(
    name: string, colorHex: string, oppositeSide: boolean,
    tickIncrementAmount: number, minimum: number, maximum: number){
      const yaxis = {
        seriesName: name,
        opposite: oppositeSide,
        min: minimum,
        max: maximum,
        tickAmount: tickIncrementAmount,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: colorHex
        },
        title: {
          text: name,
          style: {
            color: colorHex
            }
          }
        }
      return yaxis
  }


  /* createSeriesAndYAxis(
    name: string, values: any[], color: string, oppositeSide: boolean,
    tickAmount: number, minimum: number, maximum: number, isTemp: boolean){
    if(isTemp){
      this.series.push({
        name: name,
        type: 'line',
        data: values,
        color: color,
      });
      this.yaxis.push({
        seriesName: name,
        opposite: oppositeSide,
        min: minimum,
        max: maximum,
        tickAmount: tickAmount,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: color
        },
        title: {
          text: name,
          style: {
            color: color
            }
          }
        },
      );
    }else{
      this.series.push({
        name: name,
        type: 'line',
        data: values,
        color: color,
      });
      this.yaxis.push({
        seriesName: name,
        opposite: oppositeSide,
        min: minimum,
        max: maximum,
        tickAmount: tickAmount,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: color
        },
        title: {
          text: name,
          style: {
            color: color
            }
          }
        },
      );
    }
  } */


  createChartOptions(combinedDates: any[], seriesData, yAxisData): any{
     return  {
      series : seriesData,
      chart: {
        height: 400,
        type: 'line',
        stacked: false,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        curve: 'straight'
      },
      tooltip: {
        enabled: true,
        shared: true,
        marker: {
          show: true
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) =>{
          const date = w.config.xaxis.categories[dataPointIndex];
          const name = w.config.series[seriesIndex].name;
          const value = series[seriesIndex][dataPointIndex];
          return (
            '<div style="background-color: grey">' +
            '<div class="margin5">'+
            '<span style="font-weight: bold; color: white">' + name + '</span><br/>' +
            '<span style="color:white">Date: ' + formatDate(date, 'dd/MM/yyyy - HH:mm:ss', 'en-US') + '</span><br/>' +
            '<span style="color:white">Value: ' + value + '</span>' +
            '</div>'
            +'</div>'
          );
        }
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      },
      xaxis: {
        type: 'datetime',
        categories: combinedDates,
      },
      yaxis : yAxisData,
    };
  }

  interpolateMissingData(data) {
    const result = [...data];
    for (let i = 0; i < result.length; i++) {
      if (result[i] === null) {
        // Find the previous and next non-null values
        let j = i;
        while (j < result.length && result[j] === null) j++;
        if (j < result.length) {
          let k = i - 1;
          while (k >= 0 && result[k] === null) k--;
          if (k >= 0 && j < result.length) {
            // Linear interpolation
            result[i] = Math.round(result[k] + (result[j] - result[k]) * (i - k) / (j - k));
          }
        }
      }
    }
    return result;
  }

  cardClicked(card: LogTabType) {
    if (DEMO_MODE) {
      this.demoModeService.getData().subscribe( value => {
        this.valueTableProperties = value.TableProperties
        // this.OLDgraphCreated = true;
      })
    }
    this.activeCard = card;
    if (DEMO_MODE) { return }
    this.valueTableProperties = [];
    // this.temperatureTypesSelect = [];
    this.temperatureProperties = [];
    // this.propSelect = undefined;
    if (this.activeCard === 'temperature') {
      // remove time From and To restrictions
      delete this.body.dateTimeFrom;
      delete this.body.dateTimeTo;

      this.selectedController.controllerProperties.$values.forEach ( prop => {
        if (Property.isRoomTemperatureCurrent(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          // this.temperatureTypesSelect.push(Property.isRoomTemperatureCurrent)
        } else if (Property.isBathroomTemperatureCurrent(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          // this.temperatureTypesSelect.push(Property.isBathroomTemperatureCurrent)
        }
      })
      this.createGraph();
    }
    if (this.activeCard === 'valueLog') {
      setTimeout (() => {
        this.showLast7days();
      },200)
    }
  }

  private getPropertyName(propType: string) {
    const prop = this.selectedController.controllerProperties.$values.find((prop)=> {
      return Number(prop.type) === Number(propType)
    })

    if (prop.name.includes('|')) {
      return prop.name.split('|')[0] + ' ' + prop.name.split('|')[1];
    }

    return prop.name?prop.name:'Unknown property';
    /* const target = this.propertiesList.find( (prop: PropertyType) => {
      if (+propType >= prop.CodeFrom && +propType <= prop.CodeTo) { return true}
    });
    if (propType) {
      if (target) {
        if (target.Name === 'RelayOutputStatus' ||
        target.Name === 'RelayOutputControl' ||
        target.Name === 'RelayOutputForceOn' ||
        target.Name === 'RelayOutputForceOff' ||
        target.Name === 'RelayOutputByRentedOrPresence' ||
        target.Name === 'RelayOutputInCooling' ) {
          return this.handleSpecialPropNames(propType)
        } else {
          return target.Name;
        }
      } else {
        return 'Unknown property';
      }
    } else {
      return null;
    } */
  }

  /* handleSpecialPropNames(propTypeCode: string) {
    const targetProp = this.selectedController.controllerProperties.$values.find(prop => +prop.type === +propTypeCode)
    return targetProp.name.split('|')[0] + ' ' + targetProp.name.split('|')[1];
  } */

  createGraph() {
    // we dont wanna change data if we are in demo mode
    if (DEMO_MODE) {
      return
    }
    this.eventLog.logsLoading = true;
    this.body.controllerDesignations = [];
    this.body.controllerDesignations.push(this.selectedController.designation);
    if (this.activeCard === 'temperature') {
      this.graphCreated = false;
      // this.body.properties = ["6250", "6200", ...this.valueTableProperties];
      this.body.properties = [...this.temperatureProperties,...this.valueTableProperties];
    }
    if (this.activeCard === 'valueLog') {
      this.tableCreated = false;
      this.body.properties = this.valueTableProperties;
    }

    if ((this.valueTableProperties.length > 0 && this.activeCard === 'valueLog') || this.activeCard === 'temperature') {
      this.apiProjectService.getGraph(this.body).subscribe( response => {
        if (response) {
          if (this.activeCard === 'temperature') {
            // this.OLDtempGraph = this.createDataFromResponse(response);
            this.initDataForNewGraph(response);
            this.graphCreated = true;
          }

          if (this.activeCard === 'valueLog') {
            this.tableData = this.reduceDataForTableView(response);
            this.getAlarmAndAcessLogs();
          }
        } else {
          this.graphCreated = false;
        }
      });
    } else {
      this.getAlarmAndAcessLogs();
    }
  }

  reduceDataForTableView(response: {controllerDesignation: string,propertyType:string,timestamp:string,value: number}[]) {
    const tempData = [];

    
    response = response.sort((a, b) => {
      // First, compare by propertyType
      if (a.propertyType !== b.propertyType) {
        return a.propertyType.localeCompare(b.propertyType); // String comparison
      }
    
      // If propertyType is the same, compare by timestamp
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(); // Numeric comparison
    });

    this.valueTableProperties.forEach( propTypeCode => {
      response.forEach( (element , index) => {
        if ((index === 0 && element.propertyType.toString() === propTypeCode.toString()) ||
            (index > 0 && (propTypeCode === element.propertyType.toString()) && (element.propertyType.toString() !== response[index-1].propertyType.toString()) ) || 
            (index > 0 && element.propertyType.toString() === propTypeCode.toString() &&
            response[index].value.toString() !== response[index-1].value.toString())) {
            tempData.push(element);
        }
      });
    })
    tempData.sort( (a, b) =>  (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any));
    return tempData;
  }

  /* createDataFromResponse(resp: any):
   {name: string, series: {name: any, value: any}[], type}[]
    {
    let results = [];
    this.body.properties.forEach( prop => {
      results.push({name: this.getPropertyName(prop), series: [], type: prop})
    })
    resp.forEach( data => {
      const target = results.find( element => {
       if (element.name === this.getPropertyName(data.propertyType)) { return true;}
      })
      if (target) {
        target.series.push({name: new Date(data.timestamp), value: data.value})
      }
    });
    if (this.activeCard === 'temperature') {
      this.OLDcircumstancesGraph = [];
      this.valueTableProperties.forEach( propType => {
        const prop = this.selectedController.controllerProperties.$values.find (p => p.type === +propType);
        if (prop) {
          this.OLDcircumstancesGraph.push(...results.filter( data => data.name === this.getPropertyName(prop.type.toString())));
          // We need to change every value 9 to value 1 in windows

          const target = this.OLDcircumstancesGraph.find( element => element.name === 'Window');
          if (target) {
            target.series.forEach( data => {
              if (Number(data.value) === 9) {
                data.value = 1;
              }
            })
          }
        }
        results = results.filter( data => data.name !== this.getPropertyName(propType));
      })
    }
    if(this.localeId === 'hr'){
      results.forEach(prop => {
        prop.name = this.pipe.transform(prop.name);
      });
    }
    // if(this.localeId === 'hr' && results[0].name === 'RoomTemperature'){
    //   results[0].name = "Sobna temperatura";
    return results;
  } */

  createDataFromResponseNEW(rawData: LogData[]): {name: string, series: {name: any, value: any}[], type}[]
    {
    let results = [];
    this.body.properties.forEach( prop => {
      results.push({name: this.getPropertyName(prop), series: [], type: prop})
    })
    rawData.forEach( data => {
      const target = results.find( element => {
       if (element.name === this.getPropertyName(data.propertyType)) { return true;}
      })
      if (target) {
        target.series.push({name: new Date(data.timestamp), value: data.value})
      }
    });
    const circumstancesGraph = [];
    if (this.activeCard === 'temperature') {

      this.valueTableProperties.forEach( propType => {
        const prop = this.selectedController.controllerProperties.$values.find (p => p.type === +propType);
        if (prop) {
          circumstancesGraph.push(...results.filter( data => data.name === this.getPropertyName(prop.type.toString())));
          // We need to change every value 9 to value 1 in windows and doors

          const windowData = circumstancesGraph.find( element => Number(element.type) === 1050); // window code
          if (windowData) {
            windowData.series.forEach( data => {
              if (Number(data.value) === 9) {
                data.value = 1;
              }
            })
          }
          const doorData = circumstancesGraph.find( element => Number(element.type) === 1000); // door code
          if (doorData) {
            doorData.series.forEach( data => {
              if (Number(data.value) === 9) {
                data.value = 1;
              }
            })
          }
        }
        results = results.filter( data => data.name !== this.getPropertyName(propType));
      })
    }
    if(this.localeId === 'hr'){
      results.forEach(prop => {
        prop.name = this.pipe.transform(prop.name);
      });
    }
    // if(this.localeId === 'hr' && results[0].name === 'RoomTemperature'){
    //   results[0].name = "Sobna temperatura";

    return [...results, ...circumstancesGraph];
  }

  yAxisTickFormatting = (value) => {
    if (Number(value) === 0) {
      return `Closed`
    }
    if (Number(value) === 1) {
      return `Open`
    }
  };

  setDateTimeFromTo(selectedDateTime: Date[]) {
    if (!(selectedDateTime[0] === undefined || selectedDateTime[0] === null)
    && !(selectedDateTime[1] === undefined || selectedDateTime[1] === null)) {
      this.body.dateTimeFrom = selectedDateTime[0].toISOString();
      this.body.dateTimeTo = selectedDateTime[1].toISOString();
      this.createGraph();
    }
  }

  getAlarmAndAcessLogs() {
    if (this.accessCheckbox) {
      this.removeAccessLog();
      this.getAccessLog(1)
    }
    if (this.alarmCheckbox) {
      this.removeAlarmLog()
      this.getAlarmLog(1)
    }

    if (!this.alarmCheckbox && !this.accessCheckbox) {
      this.tableCreated = true;
      this.eventLog.logsLoading = false;
    }
  }

  showLast24hours() {
    this.dateTimeFromTo.showLast24hours();
  }

  showLast7days() {
    this.dateTimeFromTo.showLast7days();
  }

  showLast30days() {
    this.dateTimeFromTo.showLast30days();
  }

  selectValuePropsChanged(propTypeValues: string[]) {
    // saving last selected props for more user friendly exp when swaping controllers
  /*   this.valueLogProps = propFunctions;
    this.valueTableProperties = this.getTableProperties(propFunctions, this.selectedController);
    this.createGraph();
    this.onDismiss(); */
     this.valueTableProperties = propTypeValues
     this.createGraph();
     this.onDismiss();
  }

 /*  getTableProperties(propFunctions: ((prop: Property)=> boolean)[], selectedController) {
    const tableProperties = [];
    selectedController.controllerProperties.$values.forEach ( prop => {
      let target// : Property;
      if(propFunctions.length === 0){
        this.showDoorSeries = false;
        this.showWindowSeries = false;
      }
      propFunctions.forEach(findPropFunction => {
        target = findPropFunction(prop);
        if(target && tableProperties.indexOf(prop.type.toString()) === -1) {
          tableProperties.push(prop.type.toString());
        }
      });
    });
    return tableProperties
  } */

  selectTemperaturePropsChanged(propTypeValues: string[]/* propFunctions: ((prop: Property)=> boolean)[] */) {
   /*  this.temperatureLogProps = propFunctions;
    this.temperatureProperties = this.getTemperatureProperties(propFunctions, this.selectedController);
    this.createGraph(); */
    this.temperatureProperties = propTypeValues
    this.createGraph();
    this.onDismiss();
  }

  /* getTemperatureProperties (propFunctions: ((prop: Property)=> boolean)[], selectedController) {
    const temperatureProperties = [];
    selectedController.controllerProperties.$values.forEach(prop => {
      let target// : Property;
      propFunctions.forEach(value => {
        target = value(prop);
        if(target && temperatureProperties.indexOf(prop.type.toString()) === -1) {
          temperatureProperties.push(prop.type.toString());
        }
      });
    });
    return temperatureProperties
  } */

  /* selectTemperatureChanged(event: CustomEvent) {
    this.tempEventTemperature = event;
    this.runEventCodeForTemperature(event)
    this.createGraph();
  } */



  getRelayName(name: string) {
    return name.split('|')[0];
  }

  controllerChanged(controller: Controller) {
    this.selectedController = controller;
    /* if (this.valueLogProps) {
      this.valueTableProperties = this.getTableProperties(this.valueLogProps, this.selectedController);
      // this.runEventCode(this.valueLogProps);
    } */

    /* if (this.temperatureLogProps) {
      // this.runEventCodeForTemperature(this.temperatureLogProps)
      this.temperatureProperties = this.getTemperatureProperties(this.temperatureLogProps, this.selectedController);
    } */
    this.createGraph()

    // force ion-select update when swaping controllers
    this.showSelect = false
    setTimeout( () => {
      this.showSelect = true;
    },100)
  }

  simulateClick() {
    // we use this to emulate button click to open the search component
      // let elements = document.querySelectorAll('.alert-head.sc-ion-alert-md');
      //   var rect = elements[0].getBoundingClientRect();
      //   console.log(rect.top, rect.right, rect.bottom, rect.left);
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
        clientX: 0,
        clientY:0,
        screenX: 0,
        screenY:0
      });
      this.openSearch(evt);
  }

  onDismiss() {
    if (this.searchActive) {
      this.searchActive = false;
      this.popoverController.dismiss(null)
      this.searchValue = '';
    }
  }

  getPropNamesHr() {
    this.propsHr = this.selectedController.controllerProperties.$values.map(prop => {
      prop.name = this.pipe.transform(prop.name);
      return prop;
    });
  }

  async openSearch(ev) {
    if (!this.searchActive) {
      this.searchActive = true;
      const myEmitter = new EventEmitter<string>();
      myEmitter.subscribe( value => {
        this.searchValue = value
      });
      const popover = await this.popoverController.create({
        component: FilterSelectComponent,
        cssClass: 'select-search',
        event: ev,
        backdropDismiss: false,
        showBackdrop: false,
        componentProps: {
          inputDelay: 500,
          someTextWriten: myEmitter
        },
      });
      await popover.present();

      popover.onDidDismiss().then( () => {
      })

    }
  }

  showCustom() {
    if (this.hideCustomTime === true) {
      this.hideCustomTime = false;
    } else {
      this.hideCustomTime = true;
      this.dateTimeFromTo.showLast7days();
    }
  }

  onAccessLogClick(event: any) {
    if (event.target.checked) {
      this.accessCheckbox = true;
      this.createGraph()
    } else {
      this.accessCheckbox = false;
      this.removeAccessLog()
      this.eventLog.giveAccess = false;
    }
  }

  onAlarmLogClick(event: any) {
    if (event.target.checked) {
      this.alarmCheckbox = true;
      this.createGraph()
    } else {
      this.alarmCheckbox = false;
      this.removeAlarmLog()
      this.eventLog.giveAlarm = false;
    }
  }

  getAccessLog(pageNumber: number) {
    this.tableCreated = false;
    this.eventLog.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    requestBody.sorting = 'DSC|TIME';
    requestBody.controllerDesignations.push(this.selectedController.designation)
    this.queryParams.pageNumber = pageNumber;

    this.apiAccessLogService.getAccessLogs(requestBody, this.queryParams).subscribe((resp: HttpResponse<AccessLog[]>) => {
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      let accessLogData = resp.body;

      // filtering for single room
        accessLogData = accessLogData.filter( accessData => {
          if (accessData.locationName.includes(this.selectedController.name)) {
            return true;
          }
        })

        this.combineData(accessLogData);
        if (paginationData.HasNext) {
          this.getAccessLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.eventLog.giveAccess = true;
        }

    });
  }


  getAlarmLog(pageNumber: number) {
    this.tableCreated = false;
    this.eventLog.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    requestBody.sorting = 'DSC|TIME';
    requestBody.controllerDesignations.push(this.selectedController.designation);

    this.queryParams.pageNumber = pageNumber;

    this.apiAlarmLogsService.getAlarmLogs(requestBody, this.queryParams).subscribe(resp => {
      let alarmLogData = resp.body;
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      alarmLogData = alarmLogData.filter( alarm => {
          if (alarm.data.includes(this.selectedController.designation)) {
            return true;
          }
        })

        this.combineData(null, alarmLogData);
        if (paginationData.HasNext) {
          this.getAlarmLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.eventLog.giveAlarm = true;
        }
    });
  }

  combineData(accessData?: AccessLog[], alarmData?: any) {
    if (accessData) {
      accessData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), user: data.userFullName, locationName: data.locationName, type: 'access'})
      })
    }

    if(alarmData) {
      alarmData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), level: data.level, description: data.description, type: 'alarm'})
      })
    }
  }

  removeAccessLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'access');
  }

  removeAlarmLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'alarm');
  }

  sortTable() {
    this.tableData.sort( (a, b) =>  (new Date(b.timestamp) as any) - (new Date(a.timestamp) as any));
    this.eventLog.logsLoading = false;
    this.tableCreated = true;
  }

  onPageChange(value: any) {
    this.eventLog.page = value
  }

  showDataAtPage(index: number) {
    if (index === 0 && this.eventLog.page === 1) {
      return true
    } else {
      const value = index / 50;
      return (this.eventLog.page === Math.ceil(value))
    }
  }

  onDestroy() {
    this.cardTypesSubscription.unsubscribe()
  }
}
