import { Subscription, Subject, lastValueFrom, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { timeout } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CardReader } from '../models/card/card-reader.model';
import { CardStatus } from '../models/card/card-status.model';
import { CARD_READER_URL, API_BASE_URL, GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE } from 'src/environments/environment';
import { ModalController, ToastController } from '@ionic/angular';
import { EnterRoomNumberModalComponent } from 'src/app/shared/components/enter-room-number-modal/enter-room-number-modal.component';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { CardOnHolderService } from './card-on-holder.service';
import { CardLoginComponent } from 'src/app/pages/card-login/card-login.component';
import { ApiService } from '../http/api.service';
import { CurrentUserStoreService } from './current-user-store.service';
import { SoftwarePermissionId } from '../models/permissions/software-permission-id.enum';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';
import { CardsTabSelectedService } from 'src/app/shared/services/cards-tab-selected.service';
import { AdriaCardReaderSettings } from '../models/card/adria-card-reader-settings.model';
import { ApiCardReaderService } from './api-card-reader.service';


export interface CardIdentityInfo {
  uid: string,
  techTypeId:number,
  secondaryUid?: string,
  secondaryTechTypeId: number,
  userId:number,
  locationId: number,
  locationDesignation: string
}

@Injectable({
    providedIn: 'root'
})

export class CardReaderService {
    private cardReaderHwId: string;
    private readerTopicSubscriptions = new Subscription();
    private cardReaderConnected = false;
    cardReaderConnectedChanged = new Subject<boolean>();
    private URLRegex: RegExp;
    private PMSisActive = false;

    dualCardSettings = new BehaviorSubject<AdriaCardReaderSettings>(undefined);
    public pauseBetweenCardReadAttemps = 3000;

    constructor(private http: HttpClient,
                private injector: Injector,
                private mqttService: MqttService,
                private cardsService: CardsService,
                private cardOnHolderService: CardOnHolderService,
                private apiService: ApiService,
                private currentUserStoreService: CurrentUserStoreService,
                private toastController: ToastController,
                private pipe: CustomTranslatePipe,
                private cardsTabSelectedService: CardsTabSelectedService,
                private apiCardReaderService: ApiCardReaderService
                ) {}

    initialize() {
        this.requestCardReaderHwId();
        this.subscribeToReaderTopics();
    }

    getCardReaderHwId() {
        return this.cardReaderHwId;
    }

    getCardReaderConnected() {
        return this.cardReaderConnected;
    }

    async getCardInfoFromCardReader():
      Promise<{primaryCardOnReaderInfo?:CardStatus,secondaryCardOnReaderInfo?:CardStatus,  error?: string}> {
      try {
        let primaryCardOnReaderInfo:CardStatus;
        let secondaryCardOnReaderInfo:CardStatus;
        primaryCardOnReaderInfo = await lastValueFrom(this.apiCardReaderService.getCard(this.getDualCardSettings().mainType))
        if (this.getDualCardSettings().useDualCardReader) {
          await new Promise(resolve => setTimeout(resolve,  this.getDualCardSettings().antennaSwitchDelay));
          secondaryCardOnReaderInfo = await lastValueFrom(
            this.apiCardReaderService.getCard(this.getDualCardSettings().getSecondaryCardReaderTechTypeId()))
        }
        const cardExists = await lastValueFrom(this.apiCardReaderService.cardByUidExists(primaryCardOnReaderInfo.uid))
        if (cardExists) {
          return { error: 'Card already used. Use another card' }
        }
        return {
          primaryCardOnReaderInfo,
          secondaryCardOnReaderInfo,
        }

      } catch (err) {
        return { error: 'Unable to read card, place card on reader again' }
      }
    }

    async getCardInfoFromCardReaderForSingleCardTechType():
    Promise<{primaryCardOnReaderInfo?:CardStatus,  error?: string}> {
      try {
        let primaryCardOnReaderInfo:CardStatus;
        primaryCardOnReaderInfo = await lastValueFrom(this.apiCardReaderService.getCard(GUEST_WITHOUT_ROOMS_SINGLE_CARD_TECH_TYPE))
        const cardExists = await lastValueFrom(this.apiCardReaderService.cardByUidExists(primaryCardOnReaderInfo.uid))
        if (cardExists) {
          return { error: 'Card already used. Use another card' }
        }
        return {
          primaryCardOnReaderInfo,
        }
      } catch (err) {
        return { error: 'Unable to read card, place card on reader again' }
      }
    }

    setCardReaderConnected(connected: boolean) {
        this.cardReaderConnected = connected;
        this.cardReaderConnectedChanged.next(this.cardReaderConnected);
    }



    requestCardReaderHwId() {
        this.http.get<CardReader>(CARD_READER_URL).pipe(timeout(500)).subscribe(response => {
            this.cardReaderHwId = response.hwid;
            this.setCardReaderConnected(true);
        });
    }


   /*  writeToCard(card: CardData) {
        const body = new HttpParams()
        .set('Type', card.Type.toString())
        .set('ValidFrom', card.ValidFrom.toString())
        .set('ValidTo', card.ValidTo.toString())
        .set('ProjectId', card.ProjectId.toString())
        .set('ProjectIdMask', card.ProjectIdMask.toString())
        .set('AccessControlBits', card.AccessControlBits);
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.http.post(CARD_READER_URL + '/v2/card/create', body.toString(), {headers}).pipe(
            map(cardCreated => {
                return cardCreated;
            }),
        );
    }
 */
    subscribeToReaderTopics() {
        this.readerTopicSubscriptions.add(
            this.mqttService
            .observe('dev/+/cardreader').subscribe((message: IMqttMessage) => {
                this.cardReaderMessageReceived(message);
        }));
        this.readerTopicSubscriptions.add(
            this.mqttService
            .observe('dev/+/card').subscribe((message: IMqttMessage) => {
                this.cardMessageReceived(message);
        }));
        this.apiService.getBaseMqttSubscription().subscribe((mqttBase) => {
          this.readerTopicSubscriptions.add(
            this.mqttService
            .observe(`${mqttBase}/pmscardrequest/${this.cardReaderHwId}`).subscribe((message: IMqttMessage) => {
              if (+message.payload.toString() == 1) {
                this.PMSisActive = true;
                this.closeModal()
              } else {
                this.PMSisActive = false;
              }
        }));
        })
    }

    cardReaderMessageReceived(message: IMqttMessage) {
        const subtopics = message.topic.split('/');
        const messageString = message.payload.toString();
        const receivedFromHwid = subtopics[1];
        if (messageString === 'connected') {
            if (receivedFromHwid === this.cardReaderHwId) {
                this.setCardReaderConnected(true);
            }
            if (this.cardReaderHwId === undefined) {
                this.requestCardReaderHwId();
            }
        } else if (messageString === 'disconnected' && receivedFromHwid === this.cardReaderHwId) {
            this.setCardReaderConnected(false);
        }
    }

    cardMessageReceived(message: IMqttMessage) {
        const router = this.injector.get(Router);
        const subtopics = message.topic.split('/');
        const receivedFromHwid = subtopics[1];
        const messageString = message.payload.toString();
        if (router.url.includes('/login')) {
            if (this.cardReaderHwId === receivedFromHwid && messageString !== '') {
                const messageJson: CardStatus = JSON.parse(messageString);
                this.openEnterPinForLoginWithCard(messageJson.uid);
                this.cardOnHolderService.setCardOnHolder(true);
            } else {
              this.cardOnHolderService.setCardOnHolder(false);
            }
        } else {
            if (this.cardReaderHwId === receivedFromHwid && messageString !== '') {
                // const messageJson: CardStatus = JSON.parse(messageString);
                // this.cardsService.setLastCardOnHolderUid(messageJson.uid);
                // this.cardsService.setLastCardOnHolderTechTypeId(messageJson.type);
                this.cardOnHolderService.setCardOnHolder(true);
                if (!this.PMSisActive) {
                  this.getIdentityByCardUid();
                }
            } else {
                this.cardOnHolderService.setCardOnHolder(false);
            }
        }
    }


    async getIdentityByCardUid() {
      const user = this.currentUserStoreService.getUser();
      const router = this.injector.get(Router);
      const tab = this.cardsTabSelectedService.getSelectedTab();
      // https://github.com/robisim74/angular-l10n/issues/176
      // we need to inject router, because... APP_INIT
      this.URLRegex = new RegExp('/users/.+/cards');
      if (this.URLRegex.test(router.url)) {
        return;
      }

      if (this.cardsService.getCardInMaking()) { // room modal open. create card in progress
        return;
      }

    let primaryCardOnReaderInfo:CardStatus;

      try {
        primaryCardOnReaderInfo = await lastValueFrom(this.apiCardReaderService.getCard(this.dualCardSettings.getValue().mainType))
      } catch (err) {
        console.log(err)
        return ;
      }

      const params = new HttpParams().set('uid', primaryCardOnReaderInfo.uid);
      this.http.get<CardIdentityInfo>(API_BASE_URL + '/cards/identity', { params }).subscribe({
          next: async (cardIdentityInfo: CardIdentityInfo) => {
            if ( cardIdentityInfo?.uid && cardIdentityInfo?.userId && cardIdentityInfo?.locationId ) { // GUEST CARD
              // mark guest card on cards list in room
              this.cardsService.setLastGuestCardOnHolderHighlighted(cardIdentityInfo);
            }

            /* if (this.cardsService.getCardInMaking()) { // room modal open. create card in progress
              return;
            } */
            if (cardIdentityInfo === null) { // Card is not used in system
              if(router.url === '/cards' && tab==='backup'){
                return;
              }else{
                if (!user.havePermission(SoftwarePermissionId.CardsGuestUpdate)) {
                  const toast = await this.toastController.create({
                    message: this.pipe.transform(
                      'Permission required. Card is not assigned. You do not have permission to assign new guest cards.'),
                    duration: 2000
                  });
                  await toast.present();
                  return;
               }
              }
              router.navigate(['/rooms']);
              this.openRoomsNumberModal();
            } else if (!cardIdentityInfo.locationId) { // staff card
              if (!user.havePermission(SoftwarePermissionId.UsersView)) {
                 const toast = await this.toastController.create({
                   message: this.pipe.transform('Permission required. You do not have permission to view staff information.'),
                   duration: 2000
                 });
                 await toast.present();
                 return;
              }
              router.navigate([`/users/${cardIdentityInfo.userId}`]);
            } else if ( cardIdentityInfo.uid && cardIdentityInfo.userId && cardIdentityInfo.locationId ) {
              // GUEST CARD
              if (router.url === '/rooms') {
                router.navigateByUrl('/users', { skipLocationChange: true }).then(() =>
                    // does not triger nav to /users. used only to trigger nav to rooms with state info
                    router.navigate([`/rooms/`], {state:
                    { targetLocationId: cardIdentityInfo.locationId,
                      modalView: 'cards',
                      locationDesignation: cardIdentityInfo.locationDesignation
                    }
                  })
                );
              }else if(router.url === '/cards'){
                const mController = this.injector.get(ModalController);
                mController.getTop().then(modal => {
                  if(modal){
                    return;
                  }
                });
                if(tab==='backup'){
                  return;
                }else{
                  router.navigate([`/rooms/`], {
                    state: {
                      targetLocationId: cardIdentityInfo.locationId,
                      modalView: 'cards',
                      locationDesignation: cardIdentityInfo.locationDesignation },
                  });
                }
              } else {
                router.navigate([`/rooms/`], {
                  state: {
                    targetLocationId: cardIdentityInfo.locationId,
                    modalView: 'cards',
                    locationDesignation: cardIdentityInfo.locationDesignation },
                });
              }
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
    }


    /* getCardByUid(cardUid: string) {
        const router = this.injector.get(Router);
        // https://github.com/robisim74/angular-l10n/issues/176
        // we need to inject router, because... APP_INIT
        this.URLRegex = new RegExp('/users/.+/cards')
        if (this.URLRegex.test(router.url)) {
            return;
        }
        const params = new HttpParams().set('uid', cardUid);
        this.http.get<Card>(API_BASE_URL + '/cards/byUId', {params}).subscribe({
            next: (card) => {
                if (this.cardsService.cardInMaking) { // do nothing. create card in progress
                  return;
                }

                if (card === null) { // Card is not used in system
                    router.navigate(['/rooms']);
                    this.openRoomsNumberModal();
                } else { // found card in system
                    const cardTypes = this.cardsService.getCardTypes();
                    const cardTypeCode = cardTypes.find((cType)=> {
                      return cType.typeId === card.type;
                    })
                    if (CardType.isGuestCard(cardTypeCode)) {
                      const locationId:string = JSON.parse(card.customData).Positions[0].LocationId;
                      if (router.url === '/rooms') {
                        router.navigateByUrl('/users', {skipLocationChange: true}).then(()=>
                        // does not triger nav to /users. used only to trigger nav to rooms with state info
                        router.navigate([`/rooms/`], { state: { targetLocationId: locationId, modalView: 'cards' } })
                        );
                      } else {
                        router.navigate([`/rooms/`], { state: { targetLocationId: locationId, modalView: 'cards' } });
                      }

                    } else {
                      router.navigate([`/users/${card.userId}`], );
                    }
                }
        },
        error: (error) => {
            console.log(error);
        }});
    } */


    async closeModal() {
      const modalController = this.injector.get(ModalController)
      const modalIsAlreadyOpen: HTMLIonModalElement = await modalController.getTop();
      if (modalIsAlreadyOpen) {
        modalController.dismiss()
      }
    }

    async openRoomsNumberModal() {
        const modalController = this.injector.get(ModalController)
        const modalIsAlreadyOpen: HTMLIonModalElement = await modalController.getTop();
        if (modalIsAlreadyOpen) {
          return
        }

        const modal = await modalController.create({
          component: EnterRoomNumberModalComponent,
          cssClass: 'enter-room-number-modal',
          backdropDismiss: true,
          showBackdrop: true
        });
        return await modal.present();
      }

    async openEnterPinForLoginWithCard(cUid: string) {
        const modalService = this.injector.get(ModalController)
        // we are checking if some modal is already open
        const popover = await modalService.getTop();
        if (popover) {
            modalService.dismiss();
        }
        const modal = await modalService.create({
            component: CardLoginComponent,
            cssClass: 'card-login',
            backdropDismiss: true,
            showBackdrop: true,
            componentProps: {
            cardUid: cUid
            }
        });
        return await modal.present();
    }



  getDualCardSettings () {
    return this.dualCardSettings.getValue();
  }

  setDualCardSettings (settings: AdriaCardReaderSettings) {
    const adriaCardReaderSettings =
    new AdriaCardReaderSettings(
      settings.mainType,
      settings.useDualCardReader,
      settings.antennaSwitchDelay
    )
    this.dualCardSettings.next(adriaCardReaderSettings);
  }

    unsubscribeFromReaderTopic() {
      if (this.readerTopicSubscriptions) {
        this.readerTopicSubscriptions.unsubscribe();
        this.readerTopicSubscriptions = new Subscription()
      }
    }

}
