<ion-content>
  <h1 class="flex-row-center-full width100 modal-header">
    {{ "Room" | translate }} {{ location.name }} {{ "Digital key" | translate }}
  </h1>
  <div class="flex-row-center-full width100">
    {{ "Guest" | translate }}: {{ card.user.fullName }}
  </div>
  <div class="flex-row-center-full width100">
    {{ "Valid from" | translate }}:
    {{ card.validFrom | date : "dd.MM.yyyy. HH:mm" }}
    {{ "Valid to" | translate }} {{ card.validTo | date : "dd.MM.yyyy. HH:mm" }}
  </div>
  <div class="flex-row-center-full width100">
    {{ "E-mail" | translate }}: {{ card.user.email }}
  </div>
  <div class="flex-row-center-full width100">
    <ion-button size="small" (click)="onDeepLinkModeClicked()" [fill]="activeMode==='key'?'outline':'solid'">Show URL</ion-button>
    <ion-button size="small" (click)="onKeyModeClicked()" [fill]="activeMode==='deepLink'?'outline':'solid'">Show key</ion-button>
  </div>

  <div class="flex-row-center-full width100">
    <img src="{{ img }}" />
  </div>
  <div class="flex-row-center-full width100">
    {{ textUnderQrCode }}
  </div>
  <div class="flex-row-center-full width100 margin-bottom30">
    <input type="text" class="hide-input" [value]="textUnderQrCode" #dLink />
  </div>
  <div class="flex-row-center-full width100">
    <ion-button (click)="openQrCodeInNewWindow()" size="small" fill="outline"
      ><ion-icon name="copy-outline"></ion-icon
      >{{ "Open in new window" | translate }}</ion-button
    >
    <ion-button (click)="copyToClipboard(dLink)" size="small" fill="outline"
      ><ion-icon name="clipboard"></ion-icon
      >{{ "Copy link to clipboard" | translate }}</ion-button
    >
    <ion-button (click)="sendLinkToGuestEmail()" size="small" fill="outline"
      ><ion-icon name="mail"></ion-icon
      >{{ "Send to guest e-mail" | translate }}</ion-button
    >
    <ion-button (click)="close()" size="small">{{
      "Close" | translate
    }}</ion-button>
  </div>
</ion-content>
