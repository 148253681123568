<div>
    <div class="flex-row"><ion-button
        class="to-right margin-top5 margin-right5"
        size="small"
        fill="outline"
        (click)="clearSatusFilters()"
        >{{ "Clear" | translate }}</ion-button>
    </div>

    <ion-list>
        <ion-list-header class="margin-left10 font-size18 bold-font"> Alarm Types </ion-list-header>

        <ng-container *ngFor="let type of alarmTypes">
                <ion-checkbox class="width40 margin-left10" color="primary" (ionChange)="typeSelected(type, $event)" [checked]="shouldCheck(type)">{{ type.name}}</ion-checkbox>
        </ng-container>
    </ion-list>
</div>