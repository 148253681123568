<div
  [title]="activeTooltip | translate"
  [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row',
    displaySize === 'minimal' ? 'status-container-minimal' : ''
  ]"
  class="full-height"
>
  <div
    class="icon-background-off"
    style="margin-left: inherit"
    [ngClass]="[activeIcon]"
  ></div>
  <div class="status-text-off">
    <ng-container
      *ngIf="
        location.controllers.$values
          | getControllerByIndex : controllerIndex as controllerAtIndex
      "
    >
      <ng-container
        *ngIf="
          controllerAtIndex
            | findPropertyByCode
              : codeFrom
              : codeTo
              : updated as setTemperatureProperties
        "
      >
        <ng-container
          *ngIf="
            controllerAtIndex
              | getRoomTemperatureData
                : modeRoomOrBathroom
                : updated as tempData
          "
        >
          <div class="status-text-on">
            <!-- IF ROOM IS OCCUPIED OR HVAC MODE IS NOT IN AUTO MODE DISPLAY BASIC SET TEMP VALUE -->
            @if (((controllerAtIndex | findProperty: Property.isContactor: updated) | propIsActive) ||
            !((controllerAtIndex | findProperty : Property.isHvacHeatCoolAuto: updated) | propIsActive )) {
            {{ setTemperatureProperties[0]?.value }}
            }
            <!-- ROOM RENTED -->
            @else if (location.isRented  && tempData.baseSetProp?.value != null && tempData.baseSetProp?.value !== ''
            && tempData.ecoHyst1Prop?.value != null && tempData.ecoHyst1Prop?.value !== '') {
            {{
              (++tempData.baseSetProp?.value) - (++tempData.ecoHyst1Prop?.value)
            }}
            -
            {{
              (++tempData.baseSetProp?.value) + (++tempData.ecoHyst1Prop?.value)
            }}
            }
            <!-- ROOM NOT RENTED -->
            @else if (!location.isRented  && tempData.baseSetProp?.value != null && tempData.baseSetProp?.value !== ''
            && tempData.ecoHyst1Prop?.value != null && tempData.ecoHyst1Prop?.value !== ''
             && tempData.ecoHyst2Prop?.value != null && tempData.ecoHyst2Prop?.value !== ''
            ) {
            {{
              (++tempData.baseSetProp?.value) - (++tempData.ecoHyst1Prop?.value) - (++tempData.ecoHyst2Prop?.value)
            }}
            -
            {{
              (++tempData.baseSetProp?.value) + (++tempData.ecoHyst1Prop?.value) + (++tempData.ecoHyst2Prop?.value)
            }}
            }
            <ng-container *ngIf="displaySize !== 'minimal'">
            {{ sufix }}
          </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
