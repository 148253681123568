import { Pipe, PipeTransform } from '@angular/core';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';

type StatusData = {
  sosIsActive: boolean;
  cardIsDeposited: boolean;
  contactorActive: boolean;
  roomIsRented: boolean;
  anyBurglaryActive: boolean;
  cardTypeProp: Property;
  guestCode: number;
  isStaffCard: boolean;
  ignoreCardTray: boolean
}

type RoomStatus = 'unauthorized-access' | 'sos-guest-in-room' | 'sos-card-not-deposited' | 'guest-card-deposited' | 'staff-card-deposited' | 'room-rented' | 'room-not-rented' ;
type SimplifedRoomStatus = 'unauthorized-access' |'sos-active' |'room-active' | 'room-not-active' | 'room-not-rented' ;
type RoomStatusWithCardTrayIgnored = 'unauthorized-access' |'sos-active' |'room-rented-card-tray-ignored' | 'room-not-rented-card-tray-ignored' ;


@Pipe({
  name: 'getRoomStatusColor'
})
export class GetRoomStatusColorPipe implements PipeTransform {

  transform(location: Location, cardTypes: CardType[], simplifiedStatus = false, updated: Date, showCardTrayIgnoredIndicator: boolean) {
    const controllers = location.controllers.$values;
    if (!cardTypes || cardTypes.length === 0) {
      return 'room-not-rented'
    }
    const statusData: StatusData = {
      sosIsActive : false,
      cardIsDeposited : false,
      contactorActive: false,
      roomIsRented : false,
      anyBurglaryActive : false,
      cardTypeProp: undefined,
      isStaffCard: false,
      ignoreCardTray: false,
      guestCode : cardTypes.find((type: CardType)=> {
        return type.name.toLowerCase() === 'guest';
          }).code
    }
     /*  let sosIsActive = false;
      let cardIsDeposited = false;
      let roomIsRented = false;
      let anyBurglaryActive = false;
      let cardTypeProp: Property;
      const guestCode = cardTypes.find((type: CardType)=> {
        return type.name.toLowerCase() === 'guest';
          }).code; */

      for (const controller of controllers) {
        controller.controllerProperties.$values.forEach((prop: Property) => {
          if (Property.isSOS(prop) && statusData.sosIsActive === false && Property.isActive(prop)) {
            statusData.sosIsActive = true
          }
          else if (Property.isCardDeposited(prop) && statusData.cardIsDeposited === false && Property.isActive(prop)) {
            statusData.cardIsDeposited = true
          }
          else if (Property.isContactor(prop) && statusData.contactorActive === false && Property.isActive(prop)) {
            statusData.contactorActive = true
          }
          else if (Property.isAnyBurglaryProperty(prop) && statusData.anyBurglaryActive === false && Property.isActive(prop)) {
            statusData.anyBurglaryActive = true;
          }
          else if(Property.isCardType(prop)) {
            statusData.cardTypeProp = prop;
          }else if (Property.isIgnoreCardTray(prop) && Property.isActive(prop)) {
            statusData.ignoreCardTray = true;
          }
        });
      }

      if (location.isRented && statusData.roomIsRented === false) {
        statusData.roomIsRented = true;
      }

      // let isStaff = false;

      if (statusData.cardTypeProp?.value !== undefined && statusData.cardTypeProp?.value!== null) {
          const cardType = cardTypes.find((ct: CardType)=> {
            return statusData.cardTypeProp.value.toString() === ct.code.toString()
          });
          statusData.isStaffCard = CardType.isStaffCard(cardType)
      }


      if (simplifiedStatus) {
        return this.getSimplifiedStatus(statusData)
      } else if (showCardTrayIgnoredIndicator && statusData.ignoreCardTray){
        return this.getStatusWithIgnoredCardTray(statusData)
      } else {
        return this.getStatus(statusData)
      }
  }

  getStatus(statusData: StatusData): RoomStatus {
    const { anyBurglaryActive, cardIsDeposited, sosIsActive, cardTypeProp,guestCode, isStaffCard ,roomIsRented} = statusData;

    if (anyBurglaryActive) {
      return 'unauthorized-access'
    } else if (cardIsDeposited && sosIsActive && (Number(cardTypeProp?.value) === Number(guestCode)) ) {
      return 'sos-guest-in-room'
    } else if (!cardIsDeposited && sosIsActive) {
      return 'sos-card-not-deposited'
    } else if (cardIsDeposited && (Number(cardTypeProp?.value) === Number(guestCode))) {
      return 'guest-card-deposited'
    } else if (cardIsDeposited && isStaffCard ) {
      return 'staff-card-deposited'
    } else if (roomIsRented) {
      return 'room-rented';
    } else {
      return 'room-not-rented';
    }
  }

  getSimplifiedStatus(statusData: StatusData): SimplifedRoomStatus {
    const {anyBurglaryActive, sosIsActive,contactorActive, roomIsRented} = statusData;
    if (anyBurglaryActive) {
      return 'unauthorized-access'
    }
    else if (sosIsActive) {
      return 'sos-active'
    }
    else if (contactorActive) {
       return 'room-active'
    } else  if (!contactorActive && roomIsRented) {
      return 'room-not-active'
    }
    return 'room-not-rented'
  }


  getStatusWithIgnoredCardTray(statusData: StatusData): RoomStatusWithCardTrayIgnored {
    const { anyBurglaryActive, sosIsActive, roomIsRented} = statusData;

    if (anyBurglaryActive) {
      return 'unauthorized-access'
    } else if ( sosIsActive ) {
      return 'sos-active'
    }  else if (roomIsRented) {
      return 'room-rented-card-tray-ignored';
    } else {
      return 'room-not-rented-card-tray-ignored';
    }
  }

}
