<div *ngIf="!singleRoom" class="content-header flex-row">
  <h1>{{'Alarm Logs' | translate}}</h1>
  <div *ngIf="loading" class="loading-indicator"><div></div><div></div><div></div><div></div></div>
</div>

<div class="content-container">
  <div class="flex-row" style="align-items: center;">
    <app-filter
    class="margin-right15 margin-bottom8"
      [inputDelay]="1500"
      (filteredValue)="searchFilterReady($event)">
    </app-filter>

    <!-- <div *ngIf="singleRoom" class="flex-row">
      <select [(ngModel)]="mainController" (ngModelChange)="controllerChanged($event)" >
      <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
      </select>
    </div> -->

    <app-date-time-from-to (selectedDateTimeFromTo)="setDateTimeFromTo($event)"></app-date-time-from-to>

    <div class="margin-left10" (click)="openAlarmTypesPopover($event)">
      <p
        class="clickable bold-font uppercase"
        [ngClass]="{ 'filter-text-active': requestBody.types.length > 0 }"
      >
        {{ "Alarm types" | translate }}
        <i
          class="header-arrow"
          [ngClass]="[
            filtersDropdownOpened ? 'up' : 'down',
            requestBody.types.length > 0 ? 'filter-arrow-active' : ''
          ]"
        ></i>
      </p>
    </div>

    <div *ngIf="!downloadInProgress" class="flex-row-center-full margin-left10">
      <div> {{'Export log for selected timeline to' | translate}}: </div>
      <ion-button class="margin-left10" (click)="confirmDownload('CSV')">CSV</ion-button>
      <ion-button class="margin-left10" (click)="confirmDownload('PDF')">PDF</ion-button>
    </div>

    <div *ngIf="downloadInProgress" class="flex-row-center-full margin-left10">
      <div> {{'Download in progress' | translate}}: </div>
      <div class="loading-indicator"><div></div><div></div><div></div><div></div></div>
    </div>
    <app-paginator
      *ngIf="alarmLogData.length > 0"
      class="to-right"
      [paginationData]="paginationData"
      (targetPage)="onSubmit($event)">
   </app-paginator>
  </div>

  <div *ngIf="alarmLogData.length === 0 && !loading">{{'No results found' | translate}}.</div>
  <div *ngIf="singleRoom && loading" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>


  <div class="margin-top30" *ngIf="alarmLogData.length > 0">
    <table class="logs-table" >
      <thead>
        <tr>
          <th class="clickable width20" (click)="onSort('TIME')">{{'Timestamp' | translate}}
            {{this.requestBody.sorting==='ASC|TIME'?"▲":""}}
            {{this.requestBody.sorting==='DSC|TIME'?"▼":""}}
          </th>
          <th class="clickable width10" (click)="onSort('LEVEL')">{{'Level' | translate}}
            {{this.requestBody.sorting==='ASC|LEVEL'?"▲":""}}
            {{this.requestBody.sorting==='DSC|LEVEL'?"▼":""}}
          </th>
          <th class="clickable" (click)="onSort('DESCRIPT')">{{'Description' | translate}}
            {{this.requestBody.sorting==='ASC|DESCRIPT'?"▲":""}}
            {{this.requestBody.sorting==='DSC|DESCRIPT'?"▼":""}}
          </th>
          <th>{{'User confirmed' | translate}}</th>
          <th>{{'Confirmation text' | translate}}</th>
          <th>{{'Confirmation time' | translate}}</th>
          </tr>
      </thead>

      <tr *ngFor="let log of alarmLogData">
        <td class=" ">{{log.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}</td>
        <td class=" ">{{log.level}}</td>
        <td class=" ">{{log.description}}</td>
        <td class=" ">{{log.confirmUsername}}</td>
        <td class=" ">{{log.confirmText}}</td>
        <td class=" ">{{log.confirmTimestamp | date:'dd.MM.yyyy. HH:mm:ss'}}</td>
      </tr>
    </table>

    <app-paginator class="flex-row from-back margin-top20 margin-bottom20 margin-top5"
      [paginationData]="paginationData"
      (targetPage)="onSubmit($event)">
    </app-paginator>
  </div>
</div>


