<ng-container *ngIf="roomViewSize$ | async as roomViewSize">
   <ng-container *ngIf="roomViewSize === 'full' || roomViewSize === 'scaledDown'">
    <div class="access-control-card">
      <div
        [ngClass]="{
          'access-control-inactive-overlay': !location.isOnline || (location | allControllersInLocNotPolling: updated)
        }"
      ></div>
      <div
      [title]="(location | allControllersInLocNotPolling: updated)?
          ('Not polling' |translate):(!location.isOnline?('Offline' | translate):'')"
        [ngClass]="{
          'access-control-not-polling-icon': (location | allControllersInLocNotPolling: updated),
          'access-control-inactive-icon': !location.isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: (!location.isOnline || (location | allControllersInLocNotPolling: updated))
        }"
      >
        <div class="access-control-top-bar"></div>
        <div class="tag-icon-container">
          <div *ngIf="location.locationTags.$values !== null && location.locationTags.$values !== undefined && location.locationTags.$values.length !== 0"
              class="relative"  (mouseenter)="showTags = true" (mouseleave)="showTags = false" [title]="''">
              <ion-icon name="bookmark-outline"></ion-icon>
              <div *ngIf="showTags" class="tags-container">
                <div class="tags-text" *ngFor="let tag of location.locationTags.$values">
                  {{tag.tag}}
                </div>
              </div>
            </div>
          </div>
        <div [ngClass]="{'access-control-card-content-with-tags' : location.locationTags.$values !== null && location.locationTags.$values !== undefined && location.locationTags.$values.length !== 0,
        'access-control-card-content' : location.locationTags.$values === null || location.locationTags.$values === undefined || location.locationTags.$values.length === 0}">
          <div class="status-container-full flex-row">
            <div class="icon-background-black icon-fan font-size18 white"></div>
            <div class="access-control-name">{{ location.name }}</div>
          </div>
          <div class="flex-row width100 margin-top12">
            <div class="status-container-small margin-right6 flex-row">
              <div class="icon-temp font-size18 white icon-background-on"></div>
              <div class="status-text-off">
                {{'Set' | translate}} {{ (controllers | findProperty: Property.isRoomTemperatureSet: updated  : true)[0].value }}
              </div>
            </div>
            <div class="status-container-small margin-left5 flex-row">
              <div class="icon-temp font-size18 white icon-background-on"></div>
              <div class="status-text-off">
                {{'Current' | translate}} {{ (controllers | findProperty: Property.isRoomTemperatureCurrent: updated  : true)[0].value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="roomViewSize === 'minimal'">
    <div class="access-control-card-minimal">
      <div
        [ngClass]="{
          'access-control-inactive-overlay-minimal': !location.isOnline || (location | allControllersInLocNotPolling: updated)
        }"
      ></div>
      <div
        [title]="(location | allControllersInLocNotPolling: updated)?
          ('Not polling' |translate):(!location.isOnline?('Offline' | translate):'')"
        [ngClass]="{
          'access-control-not-polling-icon-minimal': (location | allControllersInLocNotPolling: updated),
          'access-control-inactive-icon-minimal': !location.isOnline
        }"
      ></div>
      <div
        [ngClass]="{
          grayscale: (!location.isOnline || (location | allControllersInLocNotPolling: updated))
        }"
      >
        <div class="access-control-top-bar-minimal"></div>
        <div class="flex-row">
          <div class="access-control-name-box-minimal">
            {{ location.name }}
          </div>
          <ng-container
            *ngIf="
              controllers
                | findProperty: Property.isAnyAlarm: updated : true
                | getActiveProps as activeAlarmProps
            "
          >
            <ng-container
              *ngIf="activeAlarmProps.length > 0; else noAlarmsActive"
              ><div class="margin-left5 icon-alert red"></div></ng-container
          ></ng-container>
          <ng-template #noAlarmsActive
            ><div class="margin-left5 icon-alert light-gray"></div></ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
