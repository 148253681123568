import { LocationTags } from "./project/location.model";
import { ControllerFilters } from "./view-filter.model";

export class LocationTagsFilter {
    locationTags: string[] = []
}
export class LocationsFilter {
    objects: number[];  //null ili empty ako ne koristis; uzimas sve; inace id-evi objecta
    subobjects: number[]; //isto kao gore; id-evi subobjecta
    groups: any[];
    floors: any[];
    locationTypes: number[]; //id-evi type-ova; za sobe uzimas id 1;access control 2, za common area 3, hvac 4
    controllerFilterType: 'slim' | 'fat'; // slim sa osnovnim za home
    locationTags: LocationTagsFilter;
    customControllerFilter: ControllerFilters; //vec postojevi filter za kontrolere; samo u ovom objektu; za filtere po temperaturi; itd

    constructor (locationType: number[]) {
        this.objects = null
        this.subobjects = null
        this.groups = []
        this.floors = null
        this.locationTypes = locationType
        this.controllerFilterType = 'slim'
        this.locationTags = new LocationTagsFilter()
        this.customControllerFilter = new ControllerFilters()
    }

    public static mergeGuestRoomFiltersWithBlankFilter(locationFil: LocationsFilter) {
      const locationFilter = structuredClone(locationFil)
      const newLocationsFilters = new LocationsFilter([1])
      newLocationsFilters.objects = locationFilter.objects;
      newLocationsFilters.subobjects = locationFilter.subobjects;
      newLocationsFilters.groups = locationFilter.groups;
      newLocationsFilters.floors = locationFilter.floors;
      newLocationsFilters.locationTypes = locationFilter.locationTypes;
      newLocationsFilters.controllerFilterType = locationFilter.controllerFilterType;
      newLocationsFilters.locationTags.locationTags =  locationFilter.locationTags.locationTags
      newLocationsFilters.customControllerFilter.Cleaning = {...newLocationsFilters.customControllerFilter.Cleaning,
         ...locationFilter.customControllerFilter.Cleaning}
      newLocationsFilters.customControllerFilter.Hvac = {...newLocationsFilters.customControllerFilter.Hvac,
        ...locationFilter.customControllerFilter.Hvac}
      newLocationsFilters.customControllerFilter.Statuses ={...newLocationsFilters.customControllerFilter.Statuses,
        ...locationFilter.customControllerFilter.Statuses}
      return newLocationsFilters;
    }




}
