import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { CustomTranslatePipe } from '../../pipes/custom-translate.pipe';
import { PopoverController } from '@ionic/angular';
import { RoomTagPopoverComponent } from '../room-tag-popover/room-tag-popover.component';
import { Location, Tag } from 'src/app/core/models/project/location.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { IPropDictionary, PropDictionaryService } from '../../services/prop-dictionary.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
/*
interface ClearAlarmData {
  designation: string;
  propertyId: string;
  value: string
} */

@Component({
  selector: 'app-room-modal-view',
  templateUrl: './room-modal-view.component.html',
  styleUrls: ['./room-modal-view.component.scss']
})
export class RoomModalViewComponent implements OnInit, OnDestroy {
  @Input() location: Location;

  controllers: Controller[];
  cardTypes: CardType[];
  cardTypesSubscription: Subscription;
  Property = Property;
  selectedControllers: Controller[] = []; // select one for filter, select all for filter off
  allTags: Tag[] = []
  SoftwarePermissionId = SoftwarePermissionId
  signedInUser: User;
  activeProp: Property;
  activePropTextSufix: string;
  mutexPropsFunc = [Property.isFancoilV1, Property.isFancoilV2, Property.isFancoilV3];
  mutexProps : Property[] = [];
  showHvacSection: boolean;

  daikinUnitStatusDictionary: IPropDictionary = this.propDictionaryService.daikinUnitStatusDictionary
  daikinUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.daikinUnitFanSpeedDictionary
  daikinUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.daikinUnitOperationModeDictionary

  lgUnitStatusDictionary: IPropDictionary = this.propDictionaryService.lgUnitStatusDictionary
  lgUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.lgUnitFanSpeedDictionary
  lgUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.lgUnitOperationModeDictionary

  boschUnitStatusDictionary: IPropDictionary = this.propDictionaryService.boschUnitStatusDictionary
  boschUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.boschUnitFanSpeedDictionary
  boschUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.boschUnitOperationModeDictionary

  pipeSubscription: Subscription;
  updated: Date = new Date();

  constructor(
    private apiProjectService: ApiProjectService,
    private cardsService: CardsService,
    private pipe: CustomTranslatePipe,
    private popoverController: PopoverController,
    private currentUserStoreService: CurrentUserStoreService,
    private propDictionaryService: PropDictionaryService,
    private cd: ChangeDetectorRef,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
      this.controllers = this.location.controllers.$values;
      this.selectAllControllers();
      this.cardTypes = this.cardsService.getCardTypes();
      this.cardTypesSubscription = this.cardsService.cardTypesChanged.subscribe(() => this.cardTypes = this.cardsService.getCardTypes())
      this.apiProjectService.getAllTags().subscribe( value => {
        this.allTags = value;
      })
      this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
      this.checkForHvacFanProp();

      this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
        this.location = this.projectService.getLocationById(this.location.locationId.toString())
        this.outOfFocusFix();
        this.updated = new Date()
        this.cd.detectChanges();
      })
  }


  //if tab is out of focus angular wont update correctly
  outOfFocusFix() {
    if (this.selectedControllers.length > 1) {
      this.selectedControllers = this.location.controllers.$values;
    } else {
      this.selectedControllers = [this.location.controllers.$values.find( cont => cont.id == this.selectedControllers[0].id)]
    }
  }

  checkForHvacFanProp(){
    this.mutexPropsFunc.forEach(func => {
      this.mutexProps.push(...this.findPropertyC(this.selectedControllers, func));
    })

  }

  findPropertyC(controllers: Controller[], filterFunction: (property: Property) => boolean){
    return controllers?.reduce((allFilteredProps, controller)=> {
      const filteredPropsOnController = controller?.controllerProperties?.$values?.filter((prop: Property) => {
        return filterFunction(prop);
      });
      if (filteredPropsOnController) {
        allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
      }
      if(allFilteredProps.length > 0){
        this.showHvacSection = true;
      }else{
        this.showHvacSection = false;
      }
      return allFilteredProps;
    },[]);
  }

  selectAllControllers() {
    this.selectControllers(this.controllers);
  }


  selectControllers(controllers: Controller | Controller[]) {
    if (Array.isArray(controllers)) {
      this.selectedControllers = controllers;
    } else {
      this.selectedControllers = [controllers];
    }
    this.checkForHvacFanProp();
  }

  getAlarmName(prop: Property) {
    if (Property.isBurglary(prop)) {
      return this.pipe.transform('Burglary detected')
    } else if (Property.isSafeCommError(prop)) {
      return this.pipe.transform('Safe comm error')
    }
    else if (Property.isHVACLargeTempDiffRoom(prop)) {
      return this.pipe.transform('Large temp. diff. room')
    }
    else if (Property.isHVACLargeTempDiffBathroom(prop)) {
      return this.pipe.transform('Large temp. diff. bathroom' )
    }
    else if (Property.isFlood(prop)) {
      return this.pipe.transform('Flood')
    }
    else if (Property.isRoomBurglaryPIR(prop)) {
      return this.pipe.transform('Burglary detected by PIR')
    }
    else if (Property.isBurglaryDetectedWindow(prop)) {
      return this.pipe.transform('Burglary detected on win.')
    }
    else if (Property.isBurglaryDetectedDoor(prop)) {
      return this.pipe.transform('Burglary detected on door')
    }
     else if (Property.isSOS(prop)) {
      return this.pipe.transform('SOS')
    }
    else if (Property.isDoorOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Door open too long')
    }
    else if (Property.isWindowOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Win. open too long')
    }
    else if (Property.isDoorLockBatteryLow(prop)) {
      return this.pipe.transform('Door lock bat. low')
    }
    else if (Property.isLowWindowOpenTooLong(prop)) {
      return this.pipe.transform('Low win. open too long')
    }
    else if (Property.isSafeBurglary(prop)) {
      return this.pipe.transform('Safe burglary detected')
    }
  }

  clearStatus() {
    // find any burglary alarm property on controller and send any true value to that property

    let alarmControllerDesignation;
    let alarmPropertyId;

    this.selectedControllers.some((controller: Controller) => {
      const anyAlarmProperty =  controller.controllerProperties.$values.find((prop)=> {
        return Property.isAnyBurglaryProperty(prop)
      })
      if (anyAlarmProperty) {
        alarmControllerDesignation = controller.designation
        alarmPropertyId = anyAlarmProperty.id.toString()
      }
      return anyAlarmProperty
    })

    // if no burglary alarms found search for any alarm
    if (!alarmControllerDesignation || !alarmPropertyId ) {
      this.selectedControllers.some((controller: Controller) => {
        const anyAlarmProperty =  controller.controllerProperties.$values.find((prop)=> {
          return Property.isAnyAlarm(prop)
        })
        if (anyAlarmProperty) {
          alarmControllerDesignation = controller.designation
          alarmPropertyId = anyAlarmProperty.id.toString()
        }
        return anyAlarmProperty
      })
    }

    this.apiProjectService.changeProperty(alarmControllerDesignation, Number(alarmPropertyId), 1).subscribe();
    // to clear status of /safety/sos,   /security/burglary, and  /security/safe/burglary,
    //  send any true value to any of those endpoints

   /*  this.selectedControllers.forEach((controller: Controller) => {
      let sosData : ClearAlarmData;
      let burglaryData : ClearAlarmData;
      let safeBurglaryData: ClearAlarmData;
      let burglaryDoorData: ClearAlarmData;
      let burglaryWindowData: ClearAlarmData;
      let burglaryPirData: ClearAlarmData;

      controller.controllerProperties.$values.forEach((prop)=> {
        if(Property.isSOS(prop)) {
          sosData = {
            designation: controller.designation,
            propertyId: prop.id.toString(),
            value: prop.value
          }
        }
        else if(Property.isBurglary(prop) ) {
          burglaryData = {
            designation: controller.designation,
            propertyId: prop.id.toString(),
            value: prop.value
          }
        }
        else if(Property.isBurglaryDetectedDoor(prop)) {
          burglaryDoorData = {
            designation: controller.designation,
            propertyId: prop.id.toString(),
            value: prop.value
          }
        }
        else if( Property.isBurglaryDetectedWindow(prop) ) {
          burglaryWindowData = {
            designation: controller.designation,
            propertyId: prop.id.toString(),
            value: prop.value
          }
        }
        else if(Property.isRoomBurglaryPIR(prop) ) {
          burglaryPirData = {
            designation: controller.designation,
            propertyId: prop.id.toString(),
            value: prop.value
          }
        }
        else if(Property.isSafeBurglary(prop)) {
          safeBurglaryData = {
            designation: controller.designation,
            propertyId: prop.id.toString(),
            value: prop.value
          }
        }
      })

      if (Number(sosData?.value)) {
        this.apiProjectService.changeProperty(sosData.designation, Number(sosData.propertyId), 1).subscribe();
      } else if (Number(burglaryData?.value)) {
        this.apiProjectService.changeProperty(burglaryData.designation, Number(burglaryData.propertyId), 1).subscribe();
      } else if (Number(safeBurglaryData?.value)) {
        this.apiProjectService.changeProperty(safeBurglaryData.designation, Number(safeBurglaryData.propertyId), 1).subscribe();
      } else if (Number(burglaryDoorData?.value)) {
        this.apiProjectService.changeProperty(burglaryDoorData.designation, Number(burglaryDoorData.propertyId), 1).subscribe();
      } else if (Number(burglaryWindowData?.value)) {
        this.apiProjectService.changeProperty(burglaryWindowData.designation, Number(burglaryWindowData.propertyId), 1).subscribe();
      } else if (Number(burglaryPirData?.value)) {
        this.apiProjectService.changeProperty(burglaryPirData.designation, Number(burglaryPirData.propertyId), 1).subscribe();
      }
      }
    ); */
  }


  async editControllerTags(ev) {
    const popover = await this.popoverController.create({
      component: RoomTagPopoverComponent,
      cssClass: 'tags-popover',
      event: ev,
      showBackdrop: false,
      componentProps: {
        location: this.location,
        existingTags: this.allTags
      }
    });
    await popover.present();

    popover.onDidDismiss().then( () => {
    })
  }

  ngOnDestroy() {
    if (this.cardTypesSubscription) {
      this.cardTypesSubscription.unsubscribe();
    }
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }
  }

}
