import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { RoomViewSize, RoomsOverviewService } from 'src/app/shared/services/rooms-overview.service';

@Component({
  selector: 'app-io-common-area-card',
  templateUrl: './io-common-area-card.component.html',
  styleUrls: ['./io-common-area-card.component.scss']
})
export class IOCommonAreaCardComponent implements OnInit {
  @Input() location: Location;
  controllers: Controller[];
  roomViewSize$: Observable<RoomViewSize> = this.roomsOverviewService.getViewSize();
  isAnyAlarm = Property.isAnyAlarm;
  showTags = false;
  updated: Date;
  pipeSubscription: Subscription;


  constructor(
      private roomsOverviewService: RoomsOverviewService,
      private cd: ChangeDetectorRef,
      private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
      this.cd.detectChanges();
    })
  }

  getNumberOfOnLights() {
  let count = 0
   this.controllers[0].controllerProperties.$values.forEach (prop => {
      if (Property.isLight(prop)) {
        if (+prop.value === 1) {
          count++;
        }
      }
    })
    return count;
  }

  getNumberOfOffLights() {
    let count = 0
    this.controllers[0].controllerProperties.$values.forEach (prop => {
       if (Property.isLight(prop)) {
         if (+prop.value !== 1) {
           count++;
         }
       }
     })
     return count;
  }

  onDestroy(){
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }
  }

}
