import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { LocationDragSelectModalComponent } from '../location-drag-select-modal/location-drag-select-modal.component';
import { RoomModalComponent } from '../room-modal/room-modal.component';
import { Location } from 'src/app/core/models/project/location.model';

@Component({
  selector: 'app-enter-room-number-modal',
  templateUrl: './enter-room-number-modal.component.html',
  styleUrls: ['./enter-room-number-modal.component.scss']
})
export class EnterRoomNumberModalComponent implements OnInit, AfterViewInit {


  @ViewChild('search') searchElement: ElementRef;
  showError = false;
  roomNumber: string;
  constructor(private modalController: ModalController,
              private projectService: ProjectService) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    setTimeout( () => {
      this.searchElement.nativeElement.focus();
    },800)
  }

  async guestWithoutRoom() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: LocationDragSelectModalComponent,
      cssClass: 'locations-drag-modal',
      backdropDismiss: true,
      showBackdrop: true
    });
    return await modal.present();
  }


  async onEnterLocationName(locName: string) {
    const location = this.projectService.getThinLocationByLocationName(locName);
    if (location) {
      this.modalController.dismiss();
      const modal = await this.modalController.create({
        component: RoomModalComponent,
        cssClass: 'room-modal',
        backdropDismiss: true,
        showBackdrop: true,
        componentProps:  {
          object: Location.getLocationObject(location),
          subObject: Location.getLocationSubObject(location),
          zone: Location.getLocationZone(location),
          startingView: 'cards',
          openNewCard: true,
          locationId: location.locationId
        }
      });
      return await modal.present();
    } else {
      this.showError = true;
    }
  }

}

