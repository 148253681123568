<ion-content *ngIf="location; else loading"  [ngClass]="{offline: !location.isOnline}">
<ng-container>
  <div class="flex-row-center-v margin-left20 margin-top10" style="flex-flow: nowrap;">
    <div class="room-name"  [ngClass]="{
      'inactive-icon-room-modal' : !location.isOnline,
      'font-size50': location.name.length <= 5,
      'font-size20': location.name.length > 6 && location.name.length < 15,
      'font-size16': location.name.length  > 15}">{{location.name}}</div>
    <ion-item class="margin-left80 width50">
      <ion-segment [(ngModel)]="activeView" (ngModelChange)="tabClicked($event)" >
        <ion-segment-button *ngIf="Location.isGuestRoom(location)" class="width100" value="view">
          <ion-label class="ion-text-wrap">{{'View' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="(signedInUser | userHasPermission : swPermissions.LocationSettings)" class="width100" value="settings">
          <ion-label *ngIf="Location.isIOCommonArea(location) || Location.isAccessControl(location) || Location.isHvacCommonArea(location)" class="ion-text-wrap">{{'View and Settings' | translate}}</ion-label>
          <ion-label *ngIf="!(Location.isIOCommonArea(location) || Location.isAccessControl(location) || Location.isHvacCommonArea(location))" class="ion-text-wrap">{{'Settings' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="!Location.isAccessControl(location) && !Location.isIOCommonArea(location)
        && (signedInUser | userHasPermission : swPermissions.LocationSettings)" class="width100" value="hvac">
        <ion-label class="ion-text-wrap">{{'Hvac' | translate}}</ion-label>
      </ion-segment-button>

        <ion-segment-button *ngIf="!Location.isIOCommonArea(location) && !Location.isHvacCommonArea(location)" class="width100" value="cards">
          <ion-label class="ion-text-wrap">{{'Cards' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="
        (signedInUser | userHasPermission : swPermissions.AccessLogView) ||
        (signedInUser | userHasPermission : swPermissions.AlarmsLogView) ||
        (signedInUser | userHasPermission : swPermissions.ValueLogView)" class="width100" value="log">
          <ion-label class="ion-text-wrap">{{'Log' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button *ngIf="User.isAdria(signedInUser)" class="width100" value="service">
          <ion-label class="ion-text-wrap">{{'Service' | translate}}</ion-label>
        </ion-segment-button>
      </ion-segment>
  </ion-item>
  <div class="flex-row margin-left115 remove-margin">
    <ion-button
    *ngIf="activeView === 'service'"
    size="small"
    class="margin-left20 margin-top20"
    color="light"
    (click)="showAllProps($event)">
    {{'Show all properties' | translate}}
  </ion-button>
    <ion-button *ngIf="(signedInUser | userHasPermission : swPermissions.CardsGuestUpdate) &&
      !Location.isHvacCommonArea(location) && !Location.isIOCommonArea(location) &&
      !Location.isAccessControl(location) && !cardMaxNumberReached && activeView !== 'service'"
      color="light"
      style="height: 48px;"
      [ngClass]="[from === null && to === null || savedDateTo.getTime() < getCurrentTime().getTime() ? 'width100' : 'width45']"
      (click)="newCard()">{{'New card (F9)' | translate}}
    </ion-button>
    <ion-button *ngIf="(signedInUser | userHasPermission : swPermissions.CardsGuestUpdate) &&
      !Location.isHvacCommonArea(location) && !Location.isIOCommonArea(location) &&
      !Location.isAccessControl(location) && !cardMaxNumberReached && from &&
      to && savedDateTo.getTime() > getCurrentTime().getTime() && activeView !== 'service'"
      class="margin-left20"
      color="light"
      style="height: 48px; width: 45%;"
      (click)="onNewCardWithSavedDates()">
      <div class="flex-column-center">
        <div>{{'New card (F6)' | translate}}</div>
        <div *ngIf="!isFirefox" style="font-size: 11px; font-weight: bold;">{{savedDateFromString + ' - ' + savedDateToString}}</div>
        <div *ngIf="isFirefox" style="font-size: 11px; font-weight: bold;">{{savedDateFromString + ' - ' + savedDateToString}}</div>
      </div>
    </ion-button>
    <div *ngIf="cardMaxNumberReached">{{ 'Maximum number of guest cards reached' | translate}}</div>
  </div>
  </div>
  <ng-container *ngIf="activeView === 'view'">
    <app-room-modal-view *ngIf="Location.isGuestRoom(location)" [location]="location"></app-room-modal-view>
  </ng-container>
  <ng-container *ngIf="activeView === 'hvac'">
    <app-room-modal-hvac [location]="location"></app-room-modal-hvac>
  </ng-container>
  <div *ngIf="activeView === 'settings'">
    <app-room-modal-settings *ngIf="Location.isGuestRoom(location)" [location]="location"></app-room-modal-settings>
    <app-access-control-modal-settings *ngIf="Location.isAccessControl(location)" [location]="location"></app-access-control-modal-settings>
    <app-io-common-area-modal-settings *ngIf="Location.isIOCommonArea(location)" [location]="location"></app-io-common-area-modal-settings>
    <app-hvac-common-area-modal-view *ngIf="Location.isHvacCommonArea(location)" [location]="location"></app-hvac-common-area-modal-view>
  </div>
  <div *ngIf="activeView === 'cards'">
    <app-room-modal-cards *ngIf="Location.isGuestRoom(location)" (guestCardsUpdated)="cardsUpdated($event)" [location]="location" ></app-room-modal-cards>
    <app-access-control-modal-cards *ngIf="Location.isAccessControl(location)" [location]="location"></app-access-control-modal-cards>
  </div>
  <div *ngIf="activeView === 'log'">
    <app-room-modal-log *ngIf="Location.isGuestRoom(location)" [controllers]="location.controllers.$values"></app-room-modal-log>
    <app-access-control-modal-log *ngIf="Location.isAccessControl(location)" [controllers]="location.controllers.$values"></app-access-control-modal-log>
    <app-io-common-area-modal-log *ngIf="Location.isIOCommonArea(location)" [controllers]="location.controllers.$values"></app-io-common-area-modal-log>
    <app-hvac-common-area-modal-log *ngIf="Location.isHvacCommonArea(location)" [controllers]="location.controllers.$values"></app-hvac-common-area-modal-log>
  </div>
  <div *ngIf="activeView === 'service'">
    <app-room-modal-service [location]="location"></app-room-modal-service>
  <!--   <app-access-control-modal-service *ngIf="Location.isAccessControl(location)" [controllers]="location.controllers.$values"></app-access-control-modal-service> -->
  </div>
</ng-container>
</ion-content>
<ng-template #loading>
  <ion-content>
    <div class="flex-row flex-center height100">
      <ion-spinner name="lines-sharp"></ion-spinner>
    </div>
  </ion-content>
</ng-template>

<app-modal-confirmation
    [type]="'cardholder'"
    [content]=""
    [cardType]="cardType"
    [errorReadingCard]="errorReadingCard"
    [guestCard]="true"
    (createVirtualCard)="createVirtualCard()"
    [loading]="modalLoading"
    (decision)="closeAndResetConfirmationModal($event)"
    *ngIf="openModal">
</app-modal-confirmation>
