import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getAveragePropValue'
})
export class GetAveragePropValuePipe implements PipeTransform {

  transform(controllers: Controller[], codeFrom: number, codeTo: number, updated: Date ): number {
    let total = 0;
    let count = 0;

    controllers.forEach((controller)=>  {
      if (Number(controller.subZone) !== 0) {// do not count non 0 subzones
        return;
      }
      if (!Controller.isGuestRoom(controller)) {// do not count non guest rooms
        return;
      }

      if (controller.isOnline === false) { // if controller is offline, do not count it to total
        return;
      }

      const propTypeFrom = new Property();
      const propTypeTo = new Property();
      let targetDaikins: Property[] = [];
      let targetLgUnits: Property[] = [];
      let targetBoschUnits: Property[] = [];

      propTypeFrom.type = codeFrom
      propTypeTo.type = codeTo
      // we are checking if controllers have daikins or LGS , if they do we use those values for analytics
      if (Property.isRoomTemperatureSet(propTypeFrom) && Property.isRoomTemperatureSet(propTypeTo)) {
        targetDaikins = controller.controllerProperties.$values.filter( prop => Property.isDaikinUnitSetTemperature(prop))
        targetLgUnits = controller.controllerProperties.$values.filter( prop => Property.isLgUnitSetTemperature(prop))
        targetBoschUnits = controller.controllerProperties.$values.filter( prop => Property.isBoschUnitSetTemperature(prop))
    } else  if (Property.isRoomTemperatureCurrent(propTypeFrom) && Property.isRoomTemperatureCurrent(propTypeTo)) {
        targetDaikins = controller.controllerProperties.$values.filter( prop => Property.isDaikinUnitMeasuredTemperature(prop))
        targetLgUnits = controller.controllerProperties.$values.filter( prop => Property.isLgUnitMeasuredTemperature(prop))
        targetBoschUnits = controller.controllerProperties.$values.filter( prop => Property.isBoschUnitMeasuredTemperature(prop))
  }

    if (targetDaikins.length > 0) {
      targetDaikins.forEach ( prop => {
        if (Controller.isGuestRoom(controller) && prop && prop.value && Number(controller.subZone) === 0) {
          if (this.valueIsValid(Number(prop.value))) {
            total = total + Number(prop.value);
            count = count + 1;
          }
        }
      })
    } else if (targetLgUnits.length > 0) {
      targetLgUnits.forEach ( prop => {
        if (Controller.isGuestRoom(controller) && prop && prop.value && Number(controller.subZone) === 0) {
          if (this.valueIsValid(Number(prop.value))) {
          total = total + Number(prop.value);
          count = count + 1;
          }
        }
      })
    } else if (targetBoschUnits.length > 0) {
      targetBoschUnits.forEach ( prop => {
        if (Controller.isGuestRoom(controller) && prop && prop.value && Number(controller.subZone) === 0) {
          if (this.valueIsValid(Number(prop.value))) {
          total = total + Number(prop.value);
          count = count + 1;
          }
        }
      })
    }

    else {
      const property = controller.controllerProperties.$values.find((prop: Property)=> {
        return prop.type >= codeFrom && prop.type <= codeTo;
      })
      if (Controller.isGuestRoom(controller) && property && property.value && Number(controller.subZone) === 0) {
        if (this.valueIsValid(Number(property.value))) {
        total = total + Number(property.value);
        count = count + 1;
        }
      }
    }

    });
    if (count === 0) {
      return 0;
    }
    return +(total/count).toFixed(1)
  }

  valueIsValid(value: number) {
    return value >= 5 && value <= 40
  }

}
