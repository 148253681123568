<ng-container
  *ngIf="
    controllers
      | findProperty : Property.isHvacHeatCool : null as hvacHeatCoolProps
  "
>
  <ng-container
    *ngIf="
      controllers
        | findProperty : Property.isHvacHeatCoolAuto : null as hvacAutoProps
    "
  >
    <div
      class="status-container-small flex-row-center-v margin-bottom12 width230"
    >
      @if(hvacAutoProps[0]?.value.toString() === '1') {
      <div class="icon-background-on font-size19 icon-hvac"></div>
      <div class="status-text-on">{{ "Auto" | translate }}</div>
      } @else if (hvacHeatCoolProps[0]?.value.toString() === '1') {
      <div class="icon-background-on font-size19 icon-snowflake blue-bg"></div>
      <div class="status-text-on">{{ "Cooling" | translate }}</div>

      } @else if (hvacHeatCoolProps[0]?.value.toString() === '0') {
      <div class="icon-background-on font-size19 icon-sun orange-bg"></div>
      <div class="status-text-on">{{ "Heating" | translate }}</div>
      } @else {
      <div class="icon-background-off font-size19 icon-hvac"></div>
      <div class="status-text-off">
        {{ "Heat/Cool/Auto undef." | translate }}
      </div>
      }
    </div>
  </ng-container>
</ng-container>
