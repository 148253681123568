import { Injectable } from '@angular/core';
import {  BehaviorSubject, Subject } from 'rxjs';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Card } from 'src/app/core/models/card/card.model';
import { CardIdentityInfo } from 'src/app/core/services/card-reader.service';

@Injectable({
  providedIn: 'root',
})
export class CardsService {
  private cardTypes: CardType[] = [];
  cardTypesChanged = new Subject<CardType[]>();
  // lastCardsOnHolderUid: string;
  // lastCardOnHolderTechTypeId: number;
  private cardInMaking = false;

  private cards$ = new BehaviorSubject<Card[]>([]);

  private lastGuestCardOnHolderHighlighted$ = new BehaviorSubject<CardIdentityInfo>(undefined);
  cardsMaxEndDate = new Date(2035, 11, 31); // 31.12.2035

  constructor() {}

  setCardTypes(cardTypes: CardType[]) {
    this.cardTypes = cardTypes.slice();
    this.cardTypesChanged.next(this.cardTypes);
  }

  getCardTypes() {
    return this.cardTypes.slice();
  }


/*   setLastCardOnHolderUid(uid: string) {
    this.lastCardsOnHolderUid = uid;
  } */

  /* setLastCardOnHolderTechTypeId(id: number) {
    this.lastCardOnHolderTechTypeId = id;
  } */

  setCardInMaking(value: boolean) {
    this.cardInMaking = value;
  }

  getCardInMaking() {
    return this.cardInMaking;
  }

  setCards(cards: Card[]) {
    this.cards$.next(cards);
  }

  getCards() {
    return this.cards$.asObservable();
  }

  setLastGuestCardOnHolderHighlighted(cardInfo: CardIdentityInfo) {
    this.lastGuestCardOnHolderHighlighted$.next(cardInfo)
  }

  getLastGuestCardOnHolderHighlighted() {
    return this.lastGuestCardOnHolderHighlighted$.asObservable()
  }
}
