<div class="content-sub-header flex-row-center-full">
    <h4>{{ "Group settings" | translate | uppercase }}</h4>
</div>
<!-- <app-groups-hvac-modal
  [settingsType]="'hvac'"
  [selectedGroup]="selectedGroup"
></app-groups-hvac-modal> -->
<div

  class="flex-row-center-v width100 margin-top30 padding-left15"
>
<ng-content>
  <div *ngIf="defaultPresets?.length > 0; else noPresets">
    {{ "Apply preset" | translate }}:
    <!-- <ion-chip class="margin-right10" (click)="clearValues()">
      <ion-label class="pad20">{{'No preset' | translate}}</ion-label>
    </ion-chip> -->
    <ion-chip
      class="margin-right10"
      *ngFor="let preset of defaultPresets"
      (click)="onClickPreset(preset)"
    >
      <ion-label class="pad20">{{ preset.name | titlecase }}</ion-label>
    </ion-chip>
  </div>
  <ng-template #noPresets>
    {{'No presets available' |translate}}
  </ng-template>
</ng-content>
  <ion-chip
    *ngIf="(signedInUser | userHasPermission : swPermissions.SettingsPresets)"
    class="margin-left50" [outline]="true" routerLink="/settings/presets">
    <ion-label class="pad20">{{'Edit presets' | translate}}</ion-label>
  </ion-chip>
</div>
<div *ngIf="targetProps" class="props-container padding-left15">
  <div class="props-container-top-right">
    <ion-button class="margin-right5" size="small" fill="outline"
    (click)="clearValues()"
    >{{
      "Clear" | translate
    }}</ion-button>
    <ion-button size="small" (click)="onApply()" [disabled]="loading">{{
      "Send values" | translate
    }}</ion-button>
  </div>
  <ng-container *ngFor="let prop of targetProps; let i = index">
      <ng-container *ngIf="(targetProps[i-1] | getSubtitleForPresetProps : targetProps[i]) as subTitle">
        <ion-list-header style="width:200px">
          {{subTitle | translate}}
        </ion-list-header>
      </ng-container>
    <div>
    <p class="flex-row-center-v margin-top10 margin-bottom10">
      <ion-checkbox
        class="margin-right5"
        [(ngModel)]="prop.include"
      ></ion-checkbox>
      <label style="width:200px">
        <ng-container *ngIf="prop | isBurglaryDetectedProp ; else nonBurglaryDetectedProp"> {{prop | getBurglaryIncludeName: targetProps | translate}}</ng-container>
        <ng-template #nonBurglaryDetectedProp>
          <ng-container>{{ prop.name.split('|')[0] | translate }}</ng-container>
        </ng-template>
     </label>
     <ng-container *ngIf="(prop | getTypeForPresetProp) === 'int'">
      <input
        class="margin-right25 text-align-right"
        (change)="defaultPropChange($event, prop)"
        [(ngModel)]="prop.value"
        [disabled]="!prop.include"
        type="number"
      />
    </ng-container>
    <ng-container *ngIf="(prop | getTypeForPresetProp) === 'bool'">
      <ion-radio-group class="flex-row" [(ngModel)]="prop.value" >
          <ion-radio class="margin-right20" labelPlacement="end" [value]="0" [disabled]="!prop.include">{{'Off' | translate}}</ion-radio>
          <ion-radio labelPlacement="end" [value]="1" [disabled]="!prop.include">{{'On' | translate}}</ion-radio>
    </ion-radio-group>
    </ng-container>
    <ng-container *ngIf="(prop | getTypeForPresetProp) === 'burglaryDetected'">
      <!-- 0 and 1 places are switched compared to bool -->
      <ion-radio-group class="flex-row" [(ngModel)]="prop.value" >
          <ion-radio class="margin-right20" labelPlacement="end" [value]="1" [disabled]="!prop.include">{{'Off' | translate}}</ion-radio>
          <ion-radio labelPlacement="end" [value]="0" [disabled]="!prop.include">{{'On' | translate}}</ion-radio>
    </ion-radio-group>
    </ng-container>
    <ng-container *ngIf="(prop | getTypeForPresetProp) === 'hvacHeatingCoolingAuto'">
      <ion-radio-group class="flex-row" [(ngModel)]="prop.value" >
          <ion-radio class="margin-right20" labelPlacement="end" [value]="0" [disabled]="!prop.include">{{'Heating' | translate}}</ion-radio>
          <ion-radio class="margin-right20" labelPlacement="end" [value]="1" [disabled]="!prop.include">{{'Cooling' | translate}}</ion-radio>
          <ion-radio labelPlacement="end" [value]="2" [disabled]="!prop.include">{{'Auto' | translate}}</ion-radio>
    </ion-radio-group>
    </ng-container>
    </p>
  </div>
  </ng-container>
  <ng-container *ngFor="let relayProp of targetEquipmentRelayPropsOptions; let i=index">
    <p class="flex-row-center-v margin-top10 margin-bottom10">
      <ion-checkbox
        class="margin-right5"
        [(ngModel)]="relayProp.include"
      ></ion-checkbox>
      <label style="width:200px">
           {{ relayProp.name.split('|')[0] | translate }}
     </label>
      <ng-container *ngIf="relayProp | getRelayOutputPropsOfSameType :( $allLocations |async) as props">
      <ion-radio-group class="flex-row">
<!--           <ion-radio *ngFor="let p of props" class="margin-right50" labelPlacement="end" [disabled]="!relayProp.include">{{p.name.split('|')[1]}}</ion-radio>
-->           <!--  <ion-radio class="margin-right20" labelPlacement="end" [value]="0" [disabled]="!defaultProp.include">{{'Off' | translate}}</ion-radio>
          <ion-radio labelPlacement="end" [value]="1" [disabled]="!defaultProp.include">{{'On' | translate}}</ion-radio> -->
      </ion-radio-group>
      <div class="flex-row">
        <ng-container *ngFor="let p of props">
          <div class="margin-right80 clickable" (click)="onClickRelayOutputPropValue(p)" [ngClass]="{ 'selected': equipmentRelayPropValuesIncludes(p) }">
            {{p.name.split('|')[1]}}
          </div>
        </ng-container>
      </div>
    </ng-container>
    </p>
  </ng-container>
</div>

