import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'allControllersInLocNotPolling',
})
export class AllControllersInLocNotPollingPipe implements PipeTransform {

  transform(location: Location, updated: Date): boolean {
    return location.controllers.$values.every((cont: Controller)=> {
      return !cont.isPolling
    });
  }

}
