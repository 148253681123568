import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardReaderTechType } from 'src/app/modules/settings/services/http/api-settings.service';
import { CardStatus } from '../models/card/card-status.model';
import { API_BASE_URL, CARD_READER_URL } from 'src/environments/environment';
import { CardIdentityInfo } from './card-reader.service';
import { map, Observable } from 'rxjs';
import { Card } from '../models/card/card.model';

@Injectable({
  providedIn: 'root'
})
export class ApiCardReaderService {

  constructor(private http: HttpClient) { }


  getCard(forceType: CardReaderTechType) {
    let params;
    if (forceType!==undefined && forceType!==null) {
      params = new HttpParams().set('force', forceType);
    }
    return this.http.get<CardStatus>(CARD_READER_URL + '/card/status', params?{params}: null);
  }

    getCardCodeQR(cardUid: string) {
        const params = new HttpParams().set('uid', cardUid);
        return this.http.get<any>(API_BASE_URL + '/cards/qr', {params})
    }

    getCardDeepLinkQR(cardUid: string) {
      const params = new HttpParams().set('uid', cardUid);
      return this.http.get<any>(API_BASE_URL + '/cards/qrdeeplink', {params})
  }

    getCardDeepLinkText(cardUid: string) {
      const params = new HttpParams().set('uid', cardUid);
      return this.http.get<any>(API_BASE_URL + '/cards/deeplink',{params, responseType: 'text' as 'json', // ts won accept 'text'
      })
    }

    sendDeepLinkToCardEmail(cardUid: string) {
      const params = new HttpParams().set('uid', cardUid);
      return this.http.get<any>(API_BASE_URL + '/cards/senddeeplink',
      {params})
    }

    getCardIdentityInfo(cardUid: string) {
      const params = new HttpParams().set('uid', cardUid);
      return this.http.get<CardIdentityInfo>(API_BASE_URL + '/cards/identity', { params })
    }

    cardByUidExists (cardUid): Observable<boolean> {
      const params = new HttpParams().set('uid', cardUid);
      return this.http.get<Card>(API_BASE_URL + '/cards/byUId', { params }).pipe(
        map((card) => {
          return card !== null
        })
      );
    }
}
