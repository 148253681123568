<ng-container
  *ngIf="
    selectedControllers
      | findProperty : Property.isHvacHeatCool: null : true as hvacHeatCoolProps
  "
>
  <ng-container
    *ngIf="
      selectedControllers
        | findProperty : Property.isHvacHeatCoolAuto: null : true as hvacHCAutoProps
    "
  >
    <ng-container
      *ngIf="
        selectedControllers
          | getRoomTemperatureData
            : location
            : (updated$ | async) as roomTempData
      "
    >
      <!-- AUTO MODE-->
      <ng-container
        *ngIf="
          hvacHCAutoProps.length > 0 &&
            hvacHCAutoProps[0]?.value.toString() === '1';
          else heatCool
        "
      >
        <div class="margin-top50" style="width: max-content;">
          <div>
            <ng-container *ngIf="location === 'bathroom'">{{'Bathroom HVAC inactive when temperature is in range' | translate}}</ng-container>
            <ng-container *ngIf="location !== 'bathroom'">{{'Room HVAC inactive when temperature is in range' | translate}}</ng-container>
          </div>
          <ul class="margin-left30">
            <li>{{'Someone in room' | translate}}: {{ roomTempData?.baseSetProp?.value }} ℃</li>
            <li *ngIf="roomTempData.baseSetProp && roomTempData.ecoHyst1Prop">
              {{'Room rented but empty' | translate}}:
              <ng-container *ngIf="roomTempData.baseSetProp.value != null && roomTempData.ecoHyst1Prop.value != null">
                  {{ +roomTempData.baseSetProp.value - +roomTempData.ecoHyst1Prop.value }} -
                  {{ +roomTempData.baseSetProp.value + +roomTempData.ecoHyst1Prop.value }}
              </ng-container>
              ℃
            </li>
            <li *ngIf="roomTempData.baseSetProp && roomTempData.ecoHyst1Prop && roomTempData.ecoHyst2Prop">
              {{'Room not rented' | translate}}:
              <ng-container *ngIf="roomTempData.baseSetProp.value !=null && roomTempData.ecoHyst1Prop.value!= null && roomTempData.ecoHyst2Prop.value!= null">
                {{
                  +roomTempData.baseSetProp.value -
                    +roomTempData.ecoHyst1Prop.value -
                    +roomTempData.ecoHyst2Prop.value
                }}
                -
                {{
                  +roomTempData.baseSetProp.value +
                    +roomTempData.ecoHyst1Prop.value +
                    +roomTempData.ecoHyst2Prop.value
                }}

            </ng-container>
              ℃
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-template #heatCool>
        <ng-container *ngIf="hvacHeatCoolProps | propIsActive; else heat">
          <!-- COOLING MODE-->
          <div class="margin-top50" style="width: max-content;">
            <div>
              <ng-container *ngIf="location === 'bathroom'">{{'Bathroom temperature set will be' | translate}}</ng-container>
              <ng-container *ngIf="location !== 'bathroom'">{{'Room temperature set will be' | translate}}</ng-container>
            </div>
            <ul class="margin-left30">
              <li>{{'Someone in room' | translate}}: {{ roomTempData?.baseSetProp?.value }} ℃</li>
              <li *ngIf="roomTempData.baseSetProp && roomTempData.ecoHyst1Prop">
                {{'Room rented but empty' | translate}}:
                <ng-container *ngIf="roomTempData.baseSetProp.value != null && roomTempData.ecoHyst1Prop.value != null">
                {{ +roomTempData.baseSetProp.value + +roomTempData.ecoHyst1Prop.value }}
              </ng-container>
                 ℃
              </li>
              <li *ngIf="roomTempData.baseSetProp && roomTempData.ecoHyst1Prop && roomTempData.ecoHyst2Prop">
                {{'Room not rented' | translate}}:
                <ng-container *ngIf="roomTempData.baseSetProp.value !=null && roomTempData.ecoHyst1Prop.value!= null && roomTempData.ecoHyst2Prop.value!= null">
                {{
                  +roomTempData.baseSetProp.value +
                    +roomTempData.ecoHyst1Prop.value +
                    +roomTempData.ecoHyst2Prop.value
                }}
                </ng-container>
                ℃
              </li>
            </ul>
            <div *ngIf="location === 'room' && roomTempData.baseSetProp && roomTempData.regulationProp" class="margin-top10">
              {{'In room regulation is between' | translate}}
              <ng-container *ngIf="roomTempData.baseSetProp.value!= null && roomTempData.regulationProp.value!=null">
                {{ +roomTempData.baseSetProp.value - +roomTempData.regulationProp.value }} ℃ {{'and' | translate}}
                {{ +roomTempData.baseSetProp.value + +roomTempData.regulationProp.value }} ℃
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- HEATING MODE -->
        <ng-template #heat>
          <div class="margin-top50" style="width: max-content;">
            <div>
              <ng-container *ngIf="location === 'bathroom'">{{'Bathroom temperature set will be' | translate}}</ng-container>
              <ng-container *ngIf="location !== 'bathroom'">{{'Room temperature set will be' | translate}}</ng-container>
            </div>
            <ul class="margin-left30">
              <li>{{'Someone in room' | translate}}: {{ roomTempData?.baseSetProp?.value }} ℃</li>
              <li *ngIf="roomTempData.baseSetProp && roomTempData.ecoHyst1Prop">
                {{'Room rented but empty' | translate}}:
                <ng-container *ngIf="roomTempData.baseSetProp.value != null && roomTempData.ecoHyst1Prop != null">
                {{ roomTempData.baseSetProp.value - roomTempData.ecoHyst1Prop.value }}
                </ng-container> ℃
              </li>
              <li *ngIf="roomTempData.baseSetProp && roomTempData.ecoHyst1Prop && roomTempData.ecoHyst2Prop">
                {{'Room not rented' | translate}}:
                <ng-container *ngIf="roomTempData.baseSetProp.value !=null && roomTempData.ecoHyst1Prop.value!= null && roomTempData.ecoHyst2Prop.value!= null">
                {{
                  roomTempData.baseSetProp.value -
                    roomTempData.ecoHyst1Prop.value -
                    roomTempData.ecoHyst2Prop.value
                }}
                </ng-container>
                ℃
              </li>
            </ul>
            <div *ngIf="location === 'room' && roomTempData.baseSetProp && roomTempData.regulationProp" class="margin-top10">
              {{'In room regulation is between' | translate}}
              <ng-container *ngIf="roomTempData.baseSetProp.value!= null && roomTempData.regulationProp.value!=null">
              {{ roomTempData.baseSetProp.value - roomTempData.regulationProp.value }} ℃ {{'and' | translate | lowercase}}
              {{ +roomTempData.baseSetProp.value + +roomTempData.regulationProp.value }} ℃
            </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
