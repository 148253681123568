import { Component, Input } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Component({
  selector: 'app-modal-view-heat-cool-auto',
  templateUrl: './modal-view-heat-cool-auto.component.html',
  styleUrl: './modal-view-heat-cool-auto.component.scss'
})
export class ModalViewHeatCoolAutoComponent {
  @Input({required: true}) controllers: Controller[];
  Property = Property
}
