import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { RoomViewSize, RoomsOverviewService } from 'src/app/shared/services/rooms-overview.service';

@Component({
  selector: 'app-access-control-card',
  templateUrl: './access-control-card.component.html',
  styleUrls: ['./access-control-card.component.scss']
})
export class AccessControlCardComponent implements OnInit {
  @Input() location: Location;
  controllers: Controller[];
  roomViewSize$: Observable<RoomViewSize> = this.roomsOverviewService.getViewSize();
  Property = Property;
  showTags = false
  updated: Date;
  pipeSubscription: Subscription

  constructor(
    private roomsOverviewService: RoomsOverviewService,
    private cd: ChangeDetectorRef,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
      this.cd.detectChanges();
    })
  }

  onDestroy(){
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }

  }

}
