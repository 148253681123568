import { Pipe, PipeTransform } from '@angular/core';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getRoomsOnFloorForSelection'
})
export class GetRoomsOnFloorForSelectionPipe implements PipeTransform {

  transform(
    guestRoomLocations: Location[], fl: LocationGroup, workingLocation?: Location  ): Location[] {

    if (guestRoomLocations.length === 0) {
      return  [];
    }

    if (workingLocation) {
      return guestRoomLocations.filter(location => fl.locations.some(
         loc => (loc.locationId === location.locationId) && (loc.locationId != workingLocation.locationId)) /* && location.isOnline */)
    } else {
      return guestRoomLocations.filter(location => fl.locations.some(
         loc => (loc.locationId === location.locationId)) /* && location.isOnline */)
    }

  }
}
