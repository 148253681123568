import { Component, Input } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Component({
  selector: 'app-modal-view-set-temperature',
  templateUrl: './modal-view-set-temperature.component.html',
  styleUrl: './modal-view-set-temperature.component.scss'
})
export class ModalViewSetTemperatureComponent {
    @Input({required:true}) controllers: Controller[];
    @Input({required:true}) propFilterFunction;
    @Input({required:true}) modeRoomOrBathroom: 'room' | 'bathroom';
    @Input({required:true}) isRented: boolean;
    @Input({required:true}) updated
    @Input() sufix= '°C';
    Property = Property


}
