<ng-container
  *ngIf="
    controllers
      | findProperty : propFilterFunction : null : true as setTemperatureProps
  "
>
  <div
    *ngFor="let setTemperatureProp of setTemperatureProps"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <div class="icon-temp icon-background-off font-size19"></div>

    <ng-container
      *ngIf="
        controllers
          | getRoomTemperatureData : modeRoomOrBathroom : updated as tempData
      "
    >
      <div class="status-text-off">
        <!-- IF ROOM IS OCCUPIED OR HVAC MODE IS NOT IN AUTO MODE DISPLAY BASIC SET TEMP VALUE -->
        @if (((controllers | findProperty: Property.isContactor: updated) |
        propIsActive) || !((controllers | findProperty :
        Property.isHvacHeatCoolAuto: updated) | propIsActive )) {
        {{ setTemperatureProp?.value }}
        }
        <!-- ROOM RENTED -->
        @else if (isRented && tempData.baseSetProp?.value != null &&
        tempData.baseSetProp?.value !== '' && tempData.ecoHyst1Prop?.value !=
        null && tempData.ecoHyst1Prop?.value !== '') {
        {{ +(+tempData.baseSetProp?.value) - +(+tempData.ecoHyst1Prop?.value) }}
        -
        {{ +(+tempData.baseSetProp?.value) + +(+tempData.ecoHyst1Prop?.value) }}
        }
        <!-- ROOM NOT RENTED -->
        @else if (!isRented && tempData.baseSetProp?.value != null &&
        tempData.baseSetProp?.value !== '' && tempData.ecoHyst1Prop?.value !=
        null && tempData.ecoHyst1Prop?.value !== '' &&
        tempData.ecoHyst2Prop?.value != null && tempData.ecoHyst2Prop?.value !==
        '' ) {
        {{
          +(+tempData.baseSetProp?.value) -
            +(+tempData.ecoHyst1Prop?.value) -
            +(+tempData.ecoHyst2Prop?.value)
        }}
        -
        {{
          +(+tempData.baseSetProp?.value) +
            +(+tempData.ecoHyst1Prop?.value) +
            +(+tempData.ecoHyst2Prop?.value)
        }}
        }
        {{ sufix }}
        <span class="status-text-off-small">{{
          setTemperatureProp.name | translate
        }}</span>
      </div>
    </ng-container>
  </div>
</ng-container>
