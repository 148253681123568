<ng-container *ngIf="location.controllers.$values.length > 0 && propToDisplay">
  <!-- SINGLE PROP TYPE -->
  <ng-container *ngIf="propToDisplay.mode === 'singlePropType'">
    <ng-container
      *ngIf="
        location.controllers.$values
        | getControllerByIndex: propToDisplay.propInfo.controllerIndex
        | findPropertyByCode
            : propToDisplay.propInfo.codeFrom
            : propToDisplay.propInfo.codeTo : updated: displaySize

            as properties
      "
    >
      <ng-container
        *ngIf="
          properties
            | propertiesAreActive : propToDisplay.propInfo.activeCondition;
          else propertiesNotActive
        "
      >
        <div
          [title]="propToDisplay.propInfo.activeTooltip | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-on"
            style="margin-left: inherit;"
            [ngClass]="[
              propToDisplay.propInfo.activeIcon,
            ]"
          ></div>

          <div *ngIf="displaySize === 'large'" class="status-text-on">
            {{ propToDisplay.propInfo.activeName | translate }}
          </div>
        </div>
      </ng-container>
      <ng-template #propertiesNotActive>

        <div
          [title]="propToDisplay.propInfo.inactiveTooltip | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-off"
            style="margin-left: inherit;"
            [ngClass]="[
              propToDisplay.propInfo.inactiveIcon,
            ]"
          ></div>
          <div *ngIf="displaySize === 'large'" class="status-text-off">
            {{ propToDisplay.propInfo.inactiveName | translate }}
          </div>
        </div></ng-template
      >
    </ng-container>
  </ng-container>
  <!-- NUMERICAL PROP TYPE -->

  <ng-container *ngIf="propToDisplay.mode === 'numericalPropType'">
    <ng-container
      *ngIf="
        location.controllers.$values
         | getControllerByIndex: propToDisplay.propInfo.controllerIndex
          | findPropertyByCode
            : propToDisplay.propInfo.codeFrom
            : propToDisplay.propInfo.codeTo : updated : displaySize as properties
      "
    >

      <div
        [title]="propToDisplay.propInfo.activeTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'status-container-minimal' : ''
        ]"
        class="flex-row full-height"
      >
        <div
          class="icon-background-off"
          style="margin-left: inherit;"
          [ngClass]="[
            propToDisplay.propInfo.activeIcon,
          ]"
        ></div>
        <div class="status-text-on">
          <ng-container
            *ngIf="
              properties[0]?.value !== null && properties[0]?.value !== undefined;
              else noTemp
            "
            >{{ properties[0].value }}
            <ng-container
              *ngIf="displaySize === 'large'"
            >
              {{ propToDisplay.propInfo.sufix |translate }}
            </ng-container></ng-container
          >
          <ng-template #noTemp> - </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- STATUS -->

  <ng-container *ngIf="propToDisplay.mode === 'status'">
    <ng-container
      *ngIf="
        location
          | statusIsActive
            : propToDisplay.propInfo.statusName
            : propToDisplay.propInfo.activeCondition
            : updated;
        else statusNotActive
      "
    >
      <div
        [title]="propToDisplay.propInfo.activeTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-on"
          style="margin-left: inherit;"
          [ngClass]="[
            propToDisplay.propInfo.activeIcon,
          ]"
        ></div>
        <div *ngIf="displaySize === 'large'" class="status-text-on">
          {{ propToDisplay.propInfo.activeName | translate }}
        </div>
      </div>
    </ng-container>
    <ng-template #statusNotActive>

      <div
        [title]="propToDisplay.propInfo.inactiveTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-off"
          style="margin-left: inherit;"
          [ngClass]="[
            propToDisplay.propInfo.inactiveIcon,
          ]"
        ></div>
        <div *ngIf="displaySize === 'large'" class="status-text-off">
          {{ propToDisplay.propInfo.inactiveName | translate  }}
        </div>
      </div></ng-template
    >
  </ng-container>


  <!-- CUSTOM SET ROOM TEMPERATURE -->

  <ng-container *ngIf="propToDisplay.mode === 'custom(setRoomTemperature)'">
    <app-display-set-temperature
    [location]="location"
    [controllerIndex]="propToDisplay.propInfo.controllerIndex"
    [activeIcon]="propToDisplay.propInfo.activeIcon"
    [codeFrom]="propToDisplay.propInfo.codeFrom"
    [codeTo]="propToDisplay.propInfo.codeTo"
    [modeRoomOrBathroom]="'room'"
    [sufix]="propToDisplay.propInfo.sufix"
    [displaySize]="displaySize"
    [activeTooltip]="propToDisplay.propInfo.activeTooltip"
    >
    </app-display-set-temperature>
  </ng-container>

    <!-- CUSTOM SET BATHROOM TEMPERATURE -->

    <ng-container *ngIf="propToDisplay.mode === 'custom(setBathroomTemperature)'">
      <app-display-set-temperature
      [location]="location"
      [controllerIndex]="propToDisplay.propInfo.controllerIndex"
      [activeIcon]="propToDisplay.propInfo.activeIcon"
      [codeFrom]="propToDisplay.propInfo.codeFrom"
      [codeTo]="propToDisplay.propInfo.codeTo"
      [modeRoomOrBathroom]="'bathroom'"
      [sufix]="propToDisplay.propInfo.sufix"
      [displaySize]="displaySize"
      [activeTooltip]="propToDisplay.propInfo.activeTooltip"
      >
      </app-display-set-temperature>
    </ng-container>


  <!-- CUSTOM (FAN SPEED) -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(fanSpeed)'">
    <ng-container
      *ngIf="
      location.controllers.$values
      | getControllerByIndex: propToDisplay.propInfo.controllerIndex
          | getFancoilSpeedProps :
          propToDisplay.propInfo.fanSpeedV1Code:
          propToDisplay.propInfo.fanSpeedV2Code:
          propToDisplay.propInfo.fanSpeedV3Code:
          updated
         as fanCoilSpeedProps
      "
    >
      <ng-container
        *ngIf="fanCoilSpeedProps | getActiveProps | sortFanCoilSpeedProps as activeFanCoilSpeedProps"
      >
        <div
          [ngClass]="[
            displaySize === 'minimal' ? 'status-container-minimal' : ''
          ]"
          class="flex-row full-height"
        >
          <div
            style="margin-left: inherit;"
            [ngClass]="[
              propToDisplay.propInfo.activeIcon,
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length > 0
                ? 'icon-background-on'
                : '',
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length === 0
                ? 'icon-background-off'
                : '',
            ]"
          ></div>
          <ng-container *ngIf="location.controllers.$values |
          getControllerByIndex: propToDisplay.propInfo.controllerIndex
          | findProperty : Property.isFancoilVAuto: updated as fancoilVAutoProps">
            <ng-container *ngIf="displaySize === 'large' || displaySize === 'minimal'">
            @if (fancoilVAutoProps | propIsActive) {
              <div class="status-text-on"[title]="'Auto'"> Auto </div>
            }
            @else if (activeFanCoilSpeedProps.length > 1  && fanCoilSpeedProps.length > 1
            && activeFanCoilSpeedProps.length === fanCoilSpeedProps.length) {
              <div  class="status-text-on" [title]="'Auto'"> Auto </div>
            }
            @else if (activeFanCoilSpeedProps.length >0 && Property.isFancoilV1(activeFanCoilSpeedProps[0])) {
              <div  class="status-text-on" [title]="'V1'"> V1 </div>
            }
            @else if (activeFanCoilSpeedProps.length >0 && Property.isFancoilV2(activeFanCoilSpeedProps[0])) {
              <div  class="status-text-on" [title]="'V2'"> V2 </div>
            }
            @else if (activeFanCoilSpeedProps.length >0 && Property.isFancoilV3(activeFanCoilSpeedProps[0])) {
              <div  class="status-text-on" [title]="'V3'"> V3 </div>
            }
            @else {
              <div title="{{ 'Off' | translate }}" class="status-text-off">
              {{ "Off" | translate }}
            </div>
            }
          </ng-container>
      </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>


    <!-- CUSTOM (VRV) -->
    <ng-container *ngIf="propToDisplay.mode === 'custom(vrv)'">
        <ng-container *ngIf="location.controllers.$values | getControllerByIndex: propToDisplay.propInfo.controllerIndex
       | findProperty : Property.isVrvOnOff: updated as vrvOnOffProps
      ">
        <ng-container *ngIf="location.controllers.$values | getControllerByIndex: propToDisplay.propInfo.controllerIndex
        | findProperty : Property.isVrvSpeed: null : true as vrvSpeedProps
        ">
          <div
            [ngClass]="[
              displaySize === 'minimal' ? 'status-container-minimal' : ''
            ]"
            class="flex-row full-height"
          >
            <div
              style="margin-left: inherit;"
              [ngClass]="[
                propToDisplay.propInfo.activeIcon,
                (displaySize === 'large' || displaySize === 'minimal') &&
                (vrvOnOffProps | propIsActive)
                  ? 'icon-background-on'
                  : '',
                (displaySize === 'large' || displaySize === 'minimal') &&
                !(vrvOnOffProps | propIsActive)
                  ? 'icon-background-off'
                  : '',
              ]"
            ></div>
            <!-- VRV ON -->
            <ng-container *ngIf="vrvOnOffProps[0] | propIsActive; else vrvOff">
              <!-- VRV HIGH -->
              <ng-container *ngIf="vrvSpeedProps | propIsActive; else vrvLow">
                <div class="status-text-on"  >{{ "VRV high" | translate }}</div>
              </ng-container>
              <!-- VRV LOW -->
              <ng-template #vrvLow>
                <div class="status-text-on" >{{ "VRV low" | translate }}</div>
              </ng-template>
            </ng-container>
            <!-- VRV OFF -->
            <ng-template #vrvOff>
              <div class="status-text-off" >{{ "VRV off" | translate }}</div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      </ng-container>

  <!-- DAIKIN UNIT STATUS! -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(daikinUnitStatus)'">
  <ng-container
    *ngIf="
       location.controllers.$values
       | getControllerByIndex: propToDisplay.propInfo.controllerIndex
       | findPropertyByCode
      : propToDisplay.propInfo.codeFrom
      : propToDisplay.propInfo.codeTo : updated : displaySize as  daikinUnitStatusProps
    "
  >


  <app-display-dictionary-prop-in-room-overview
    [dictionary]="daikinUnitStatusDictionary"
    [prop]="daikinUnitStatusProps[0]"
    [displaySize]="displaySize">
  </app-display-dictionary-prop-in-room-overview>


  </ng-container>
  </ng-container>

  <!-- DAIKIN UNIT FAN SPEED! -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(daikinUnitFanSpeed)'">
  <ng-container
    *ngIf="
       location.controllers.$values
       | getControllerByIndex: propToDisplay.propInfo.controllerIndex
       | findPropertyByCode
      : propToDisplay.propInfo.codeFrom
      : propToDisplay.propInfo.codeTo : updated : displaySize as  daikinUnitFanSpeedProps
    "
  >
  <app-display-dictionary-prop-in-room-overview
    [dictionary]="daikinUnitFanSpeedDictionary"
    [prop]="daikinUnitFanSpeedProps[0]"
    [displaySize]="displaySize"
    [icon]="'icon-fan'"
    >
  </app-display-dictionary-prop-in-room-overview>


  </ng-container>
  </ng-container>

<!-- LG HVAC UNIT OPERATION MODE -->

  <ng-container *ngIf="propToDisplay.mode === 'custom(lgUnitOperationMode)'">
    <ng-container
      *ngIf="
         location.controllers.$values
         | getControllerByIndex: propToDisplay.propInfo.controllerIndex
         | findPropertyByCode
        : propToDisplay.propInfo.codeFrom
        : propToDisplay.propInfo.codeTo : updated : displaySize as  lgUnitOperationModeProps
      "
    >
    <app-display-dictionary-prop-in-room-overview
      [dictionary]="lgUnitOperationModeDictionary"
      [prop]="lgUnitOperationModeProps[0]"
      [displaySize]="displaySize"
      [icon]="'icon-temp'"
      >
    </app-display-dictionary-prop-in-room-overview>
    </ng-container>
    </ng-container>


<!-- LG HVAC UNIT FAN SPEED -->

<ng-container *ngIf="propToDisplay.mode === 'custom(lgUnitFanSpeed)'">
  <ng-container
    *ngIf="
        location.controllers.$values
        | getControllerByIndex: propToDisplay.propInfo.controllerIndex
        | findPropertyByCode
      : propToDisplay.propInfo.codeFrom
      : propToDisplay.propInfo.codeTo : updated : displaySize as  lgUnitOperationModeProps
    "
  >
  <app-display-dictionary-prop-in-room-overview
    [dictionary]="lgUnitFanSpeedDictionary"
    [prop]="lgUnitOperationModeProps[0]"
    [displaySize]="displaySize"
    [icon]="'icon-fan'"
    >
  </app-display-dictionary-prop-in-room-overview>
  </ng-container>
  </ng-container>

  <!-- LG HVAC UNIT STATUS -->

<ng-container *ngIf="propToDisplay.mode === 'custom(lgUnitStatus)'">
  <ng-container
    *ngIf="
        location.controllers.$values
        | getControllerByIndex: propToDisplay.propInfo.controllerIndex
        | findPropertyByCode
      : propToDisplay.propInfo.codeFrom
      : propToDisplay.propInfo.codeTo : updated : displaySize as  lgUnitOperationStatusProps
    "
  >
  <app-display-dictionary-prop-in-room-overview
    [dictionary]="lgUnitStatusDictionary"
    [prop]="lgUnitOperationStatusProps[0]"
    [displaySize]="displaySize"
    [icon]="'icon-temp'"
    >
  </app-display-dictionary-prop-in-room-overview>
  </ng-container>
  </ng-container>


  <!-- BOSCH HVAC UNIT OPERATION MODE -->

  <ng-container *ngIf="propToDisplay.mode === 'custom(boschUnitOperationMode)'">
    <ng-container
      *ngIf="
         location.controllers.$values
         | getControllerByIndex: propToDisplay.propInfo.controllerIndex
         | findPropertyByCode
        : propToDisplay.propInfo.codeFrom
        : propToDisplay.propInfo.codeTo : updated : displaySize as  boschUnitOperationModeProps
      "
    >
    <app-display-dictionary-prop-in-room-overview
      [dictionary]="boschUnitOperationModeDictionary"
      [prop]="boschUnitOperationModeProps[0]"
      [displaySize]="displaySize"
      [icon]="'icon-temp'"
      >
    </app-display-dictionary-prop-in-room-overview>
    </ng-container>
    </ng-container>


<!-- BOSCH HVAC UNIT FAN SPEED -->

<ng-container *ngIf="propToDisplay.mode === 'custom(boschUnitFanSpeed)'">
  <ng-container
    *ngIf="
        location.controllers.$values
        | getControllerByIndex: propToDisplay.propInfo.controllerIndex
        | findPropertyByCode
      : propToDisplay.propInfo.codeFrom
      : propToDisplay.propInfo.codeTo : updated : displaySize as  boschUnitOperationModeProps
    "
  >
  <app-display-dictionary-prop-in-room-overview
    [dictionary]="boschUnitFanSpeedDictionary"
    [prop]="boschUnitOperationModeProps[0]"
    [displaySize]="displaySize"
    [icon]="'icon-fan'"
    >
  </app-display-dictionary-prop-in-room-overview>
  </ng-container>
  </ng-container>

  <!-- BOSCH HVAC UNIT STATUS -->

<ng-container *ngIf="propToDisplay.mode === 'custom(boschUnitStatus)'">
  <ng-container
    *ngIf="
        location.controllers.$values
        | getControllerByIndex: propToDisplay.propInfo.controllerIndex
        | findPropertyByCode
      : propToDisplay.propInfo.codeFrom
      : propToDisplay.propInfo.codeTo : updated : displaySize as  boschUnitOperationStatusProps
    "
  >
  <app-display-dictionary-prop-in-room-overview
    [dictionary]="boschUnitStatusDictionary"
    [prop]="boschUnitOperationStatusProps[0]"
    [displaySize]="displaySize"
    [icon]="'icon-temp'"
    >
  </app-display-dictionary-prop-in-room-overview>
  </ng-container>
  </ng-container>


  <!-- CLEANED STATUS -->

  <ng-container *ngIf="propToDisplay.mode === 'custom(cleanStatus)'">
    <ng-container
      *ngIf="
         location.controllers.$values
         | getControllerByIndex: propToDisplay.propInfo.controllerIndex
         | findPropertyByCode
        : propToDisplay.propInfo.codeFrom
        : propToDisplay.propInfo.codeTo : updated : displaySize as cleanedStatus
      "
    >

    <!-- 0 Not cleaned -->
      <ng-container
        *ngIf="cleanedStatus[0]?.value === 0 || cleanedStatus[0]?.value === '0'"
      >
        <div
          [title]="'Cleaning status' | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-off"
            style="margin-left: inherit;"
            [ngClass]="[
            displaySize === 'minimal' ? 'icon-cleaned bg-color-red' : 'icon-cleaned'
            ]"
          ></div>

          <div *ngIf="displaySize === 'large'" class="status-text-off">
            {{ 'Not cleaned' | translate }}
          </div>
        </div>
      </ng-container>
        <!-- 1 Cleaned -->
      <ng-container
        *ngIf="cleanedStatus[0]?.value === 1 || cleanedStatus[0]?.value === '1'"
      >
      <div
      [title]="'Cleaning status' | translate"
      [ngClass]="[
        displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
      ]"
      class="full-height"
    >
      <div
        class="icon-background-on"
        style="margin-left: inherit;"
        [ngClass]="[
        displaySize === 'minimal' ? 'icon-cleaned bg-color-green' : 'icon-cleaned'
        ]"
      ></div>

      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Cleaned' | translate }}
      </div>
    </div>
      </ng-container>
       <!-- 2 Touched -->
      <ng-container
        *ngIf="cleanedStatus[0]?.value === 2 || cleanedStatus[0]?.value === '2'"
      >
      <div
      [title]="'Cleaning status' | translate"
      [ngClass]="[
        displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
      ]"
      class="full-height"
    >
      <div
        class="icon-background-on"
        style="margin-left: inherit;"
        [ngClass]="[
        displaySize === 'minimal' ? 'icon-cleaned bg-color-orange' : 'icon-cleaned'
        ]"
      ></div>

      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Touched' | translate }}
      </div>
    </div>
      </ng-container>
    <!-- ELSE  -->
        <ng-container
        *ngIf="
        (cleanedStatus[0]?.value) !== 0 &&
        (cleanedStatus[0]?.value) !== '0' &&
        (cleanedStatus[0]?.value) !== 1 &&
        (cleanedStatus[0]?.value) !== '1' &&
        (cleanedStatus[0]?.value) !== 2 &&
        (cleanedStatus[0]?.value) !== '2' &&
        (cleanedStatus[0]?.value) !== 3"
      >
      <div
      [title]="'Cleaning status' | translate"
      [ngClass]="[
        displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
      ]"
      class="full-height"
    >
      <div
        class="icon-background-off"
        style="margin-left: inherit;"
        [ngClass]="[
          'icon-cleaned ',
        ]"
      ></div>

      <div *ngIf="displaySize === 'large'" class="status-text-off font-size12">
        {{ 'Cleaning status undefined' | translate }}
      </div>
    </div>
      </ng-container>

    </ng-container>
    </ng-container>

  <!-- HIGHEST CLEANING STATUS -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(highestCleaningStatus)'">
    <ng-container
      *ngIf="
         location.controllers.$values
         | getControllerByIndex: propToDisplay.propInfo.controllerIndex
         | findPropertyByCode
        : propToDisplay.propInfo.codeFrom
        : propToDisplay.propInfo.codeTo : updated : displaySize as  highestCleaningStatus
      "
    >

    <!-- 0 Dirty -->
      <ng-container
        *ngIf="highestCleaningStatus[0]?.value === 0 || highestCleaningStatus[0]?.value === '0'"
      >
        <div
          [title]="'Highest cleaning status' | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-off"
            style="margin-left: inherit;"
            [ngClass]="[
            displaySize === 'minimal' ? 'icon-cleaned bg-color-red' : 'icon-cleaned'
            ]"
          ></div>

          <div *ngIf="displaySize === 'large'" class="status-text-off">
            {{ 'Not cleaned' | translate }}
          </div>
        </div>
      </ng-container>
        <!-- 1 Cleaned -->
      <ng-container
        *ngIf="highestCleaningStatus[0]?.value === 1 || highestCleaningStatus[0]?.value === '1'"
      >
      <div
      [title]="'Highest cleaning status' | translate"
      [ngClass]="[
        displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
      ]"
      class="full-height"
    >
      <div
        class="icon-background-on"
        style="margin-left: inherit;"
        [ngClass]="[
        displaySize === 'minimal' ? 'icon-cleaned bg-color-green' : 'icon-cleaned'
        ]"
      ></div>

      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Cleaned' | translate }}
      </div>
    </div>
      </ng-container>
       <!-- 2 Inspected -->
      <ng-container
        *ngIf="highestCleaningStatus[0]?.value === 2 || highestCleaningStatus[0]?.value === '2'"
      >
      <div
      [title]="'Highest cleaning status' | translate"
      [ngClass]="[
        displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
      ]"
      class="full-height"
    >
      <div
        class="icon-background-on"
        style="margin-left: inherit;"
        [ngClass]="[
        displaySize === 'minimal' ? 'icon-cleaned bg-color-orange' : 'icon-cleaned'
        ]"
      ></div>

      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Inspected' | translate }}
      </div>
    </div>
      </ng-container>

      <!-- 3 DidntPassInspection  -->
      <ng-container
      *ngIf="highestCleaningStatus[0]?.value === 3 || highestCleaningStatus[0]?.value === '3'"
    >
    <div
    [title]="'Highest cleaning status' | translate"
    [ngClass]="[
      displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
    ]"
    class="full-height"
  >
    <div
      class="icon-background-off"
      style="margin-left: inherit;"
      [ngClass]="[
      displaySize === 'minimal' ? 'icon-cleaned bg-color-blue' : 'icon-cleaned'
      ]"
    ></div>

    <div *ngIf="displaySize === 'large'" class="status-text-off">
      {{ 'Did not pass inspection' | translate }}
    </div>
  </div>
    </ng-container>
    <!-- ELSE  -->
        <ng-container
        *ngIf="
        (highestCleaningStatus[0]?.value) !== 0 &&
        (highestCleaningStatus[0]?.value) !== '0' &&
        (highestCleaningStatus[0]?.value) !== 1 &&
        (highestCleaningStatus[0]?.value) !== '1' &&
        (highestCleaningStatus[0]?.value) !== 2 &&
        (highestCleaningStatus[0]?.value) !== '2' &&
        (highestCleaningStatus[0]?.value) !== 3 &&
        (highestCleaningStatus[0]?.value) !== '3'"
      >
      <div
      [title]="'Highest cleaning status' | translate"
      [ngClass]="[
        displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
      ]"
      class="full-height"
    >
      <div
        class="icon-background-off"
        style="margin-left: inherit;"
        [ngClass]="[
          'icon-cleaned ',
        ]"
      ></div>

      <div *ngIf="displaySize === 'large'" class="status-text-off font-size12">
        {{ 'Highest cleaned undefined' | translate }}
      </div>
    </div>
      </ng-container>

    </ng-container>
    </ng-container>

  <!-- DAIKIN UNIT MODE! -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(daikinUnitMode)'">
  <ng-container
    *ngIf="
       location.controllers.$values
       | getControllerByIndex: propToDisplay.propInfo.controllerIndex
       | findPropertyByCode
      : propToDisplay.propInfo.codeFrom
      : propToDisplay.propInfo.codeTo : updated : displaySize as  daikinUnitModeProps
    "
  >
  <app-display-dictionary-prop-in-room-overview
    [dictionary]="daikinUnitOperationModeDictionary"
    [prop]="daikinUnitModeProps[0]"
    [displaySize]="displaySize">
  </app-display-dictionary-prop-in-room-overview>

  </ng-container>
  </ng-container>


  <!-- CUSTOM (HVAC HEAT COOL AUTO) -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(hvacHeatCoolAuto)'">
    <ng-container
      *ngIf="
      location.controllers.$values
          | findProperty : Property.isHvacHeatCool: null as hvacHeatCoolProps
      "
    >
      <ng-container
        *ngIf="
        location.controllers.$values
            | findProperty : Property.isHvacHeatCoolAuto: null as hvacHCAutoProps
        "
      >
        <div
          [ngClass]="[
            displaySize === 'minimal' ? 'status-container-minimal' : ''
          ]"
          class="flex-row-center-v"
        >
          <!-- HVAC HEAT COOL AUTO -->
          <ng-container *ngIf="hvacHCAutoProps.length > 0">
            <ng-container
              *ngIf="displaySize === 'large' || displaySize === 'minimal'"
            >
                <div *ngIf="(hvacHCAutoProps[0]?.value.toString() === '1') ||
                (hvacHCAutoProps[0]?.value.toString() !== '1' &&
                hvacHeatCoolProps[0]?.value.toString() !== '0' &&
                hvacHeatCoolProps[0]?.value.toString() !== '1')"
                class="icon-background-on  icon-hvac"
                style="margin-left: inherit;"></div>

                <div *ngIf="
                hvacHCAutoProps[0]?.value.toString() !== '1' &&
                hvacHeatCoolProps[0]?.value.toString() === '0'"
                class="icon-background-on icon-sun orange-bg"
                style="margin-left: inherit;"></div>

                <div *ngIf="
                hvacHCAutoProps[0]?.value.toString() !== '1' &&
                hvacHeatCoolProps[0]?.value.toString() === '1'"
                class="icon-background-on  icon-snowflake blue-bg"
                style="margin-left: inherit;"></div>

              <div
                *ngIf="hvacHCAutoProps[0]?.value.toString() === '1'"
                class="status-text-on"
                title="{{ 'Auto' | translate }}"
              >
                {{ displaySize === "large" ? "Auto" : ("A" | translate) }}
              </div>
              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() === '0'
                "
                title="{{ 'Heating' | translate }}"
                class="status-text-on"
              >
                {{ displaySize === "large" ? "Heating" : ("H" | translate) }}
              </div>
              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() === '1'
                "
                title="{{ 'Cooling' | translate }}"
                class="status-text-on"
              >
                {{ displaySize === "large" ? "Cooling" : ("C" | translate) }}
              </div>

              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() !== '0' &&
                  hvacHeatCoolProps[0]?.value.toString() !== '1'
                "
                class="status-text-off"
                title="{{ 'Heat/Cool/Auto undef.' | translate }}"
              >
                {{
                  displaySize === "large"
                    ? ("Heat/Cool/Auto undef." | translate)
                    : ("?")  | translate
                }}
              </div>
            </ng-container>
          </ng-container>
          <!-- HVAC HEAT COOL  -->
          <ng-container
            *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0">
            <ng-container
              *ngIf="hvacHeatCoolProps[0] | propIsActive; else propNotActive">
              <div
              class="icon-background-on  icon-snowflake blue-bg"
              style="margin-left: inherit;"
              ></div>
              <div class="status-text-on">
                {{ "Cooling" | translate }}
              </div>
            </ng-container>
            <ng-template #propNotActive>
              <div
                class="icon-background-on icon-sun orange-bg"
                style="margin-left: inherit;"
              ></div>
              <div class="status-text-off">
                {{ "Heating" | translate }}
              </div>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- RELAY OUTPUT STATUS -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(relayOutputStatus)'">
    <ng-container
      *ngIf="
        location.controllers.$values
        | getControllerByIndex: propToDisplay.propInfo.controllerIndex
        | findPropertyByCode
            : propToDisplay.propInfo.codeFrom
            : propToDisplay.propInfo.codeTo : updated: displaySize
            as relayOutputProperties
      "
    >
    <ng-container
        *ngIf="relayOutputProperties | propIsActive ; else propertiesNotActive
        "
      >
        <div
          title="{{relayOutputProperties | getRelayOutputStatusName | translate}} {{'On' | translate}}"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-on icon-settings"
            style="margin-left: inherit;"
          ></div>

          <div *ngIf="displaySize === 'large'" class="status-text-on status-text-ellipsis" >
            {{ relayOutputProperties | getRelayOutputStatusName | translate}} {{'On' | translate}}
          </div>
        </div>
      </ng-container>
      <ng-template #propertiesNotActive>
        <div
        title="{{relayOutputProperties | getRelayOutputStatusName | translate}} {{'Off' | translate}}"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-off icon-settings"
            style="margin-left: inherit;"
          ></div>
          <div *ngIf="displaySize === 'large'" class="status-text-off status-text-ellipsis">
            {{relayOutputProperties | getRelayOutputStatusName | translate}} {{'Off' | translate}}
          </div>
        </div></ng-template
      >
  </ng-container>
  </ng-container>
</ng-container>
