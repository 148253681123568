import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { AnalyticsToolbarItem } from 'src/app/modules/rooms/components/analytics-toolbar/analytics-toolbar.component';
import { IPropDictionary, PropDictionaryService } from '../../services/prop-dictionary.service';

export interface DisplayConfig {
  name: string,
  // permissionRequired: number;
  data: DisplayPropertyParams[],
  analyticsToolbar: AnalyticsToolbarItem[]
}

export interface DisplayPropertyParams {
  mode: 'singlePropType' | 'status' | 'numericalPropType' | 'custom(fanSpeed)' | 'custom(hvacHeatCoolAuto)' | 'custom(empty)'
  | 'custom(daikinUnitStatus)'  | 'custom(daikinUnitMode)'  | 'custom(daikinUnitFanSpeed)'| 'custom(relayOutputStatus)'
  | 'custom(highestCleaningStatus)' |  'custom(cleanStatus)' | 'custom(lgUnitOperationMode)' | 'custom(lgUnitFanSpeed)'
  | 'custom(lgUnitStatus)' | 'custom(boschUnitOperationMode)' | 'custom(boschUnitFanSpeed)' | 'custom(boschUnitStatus)'
  | 'custom(vrv)' | 'custom(setRoomTemperature)' | 'custom(setBathroomTemperature)'
  propInfo: {
    activeCondition: 'any' | 'every',
    activeName: string,
    activeIcon: string,
    activeTooltip: string,
    inactiveName: string,
    inactiveIcon: string,
    inactiveTooltip: string,
    codeFrom?: number,
    codeTo?: number,
    statusName?: string,
    sufix?: string,
    controllerIndex?: number,
    fanSpeedV1Code?: number,
    fanSpeedV2Code?: number,
    fanSpeedV3Code?: number,
    nameValuePairs?: string[]
    vrvOnOffCode?: number,
    vrvSpeedCode?: number,
  },
}

@Component({
  selector: 'app-display-property',
  templateUrl: './display-property.component.html',
  styleUrls: ['./display-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayPropertyComponent implements OnInit, OnDestroy, DoCheck {
  @Input() location: Location;
  @Input() propToDisplay: DisplayPropertyParams;
  @Input() displaySize: 'large' | 'minimal';

  Property = Property
  Number = Number;

  propDisplayArray: string[] = []
  pipeSubscription: Subscription;
  updated: Date = new Date();

  daikinUnitStatusDictionary: IPropDictionary = this.propDictionaryService.daikinUnitStatusDictionary;
  daikinUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.daikinUnitFanSpeedDictionary;
  daikinUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.daikinUnitOperationModeDictionary;

  lgUnitStatusDictionary: IPropDictionary = this.propDictionaryService.lgUnitStatusDictionary;
  lgUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.lgUnitFanSpeedDictionary;
  lgUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.lgUnitOperationModeDictionary;

  boschUnitStatusDictionary: IPropDictionary = this.propDictionaryService.boschUnitStatusDictionary;
  boschUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.boschUnitFanSpeedDictionary;
  boschUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.boschUnitOperationModeDictionary;


  constructor(private projectService: ProjectService,
              private cd: ChangeDetectorRef,
              private propDictionaryService: PropDictionaryService
            ) { }
  ngDoCheck(): void {

  }

  ngOnInit(): void {
    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
      this.cd.detectChanges();
    })
  }

  ngOnDestroy(): void {
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }
  }

}
